<template>
  <loading-container :loading="isLoading">
    <async-form
      style="margin-top: 0"
      @submit="submit"
      @keydown.enter.prevent
      persist
    >
      <activity
        ref="activity"
        v-slot="{
          variableContext,
          viewMode,
          toggleSplitView,
          splitViewAvailable
        }"
        :activity="activity"
        :response="response"
        :is-overdue="isOverdue"
        :is-submitted="isSubmitted"
      >
        <sticky-header>
          <template #primary-navigation>
            <breadcrumb>
              <breadcrumb-item
                :to="{
                  name: 'classes'
                }"
                >Classes
              </breadcrumb-item>
            </breadcrumb>
          </template>

          <template #page-action>
            <toggle
              v-if="splitViewAvailable"
              link
              :toggle="viewMode === 'split'"
              @toggle="toggleSplitView"
            >
              <template #on>Show Inline Question View </template>

              <template #off> Show Split Question View </template>
            </toggle>
          </template>

          <template #title>
            <div class="header-title">
              <sticky-header-title>
                {{ activity.name }}
              </sticky-header-title>
            </div>
          </template>

          <template #sub-title>
            <sticky-header-sub-title>
              <p style="margin-right: 8px">Individual Assignment</p>
              <activity-grading-progress />
              <activity-score student />
            </sticky-header-sub-title>
          </template>

          <template #actions>
            <div class="actions-submission">
              <div class="action-buttons">
                <submit-button
                  action="saveProgress"
                  :disabled="isSubmitted || isOverdue"
                  secondary
                >
                  <template #default>Save & Close </template>

                  <template #submitting> Saving </template>

                  <template #submitted> Saved </template>
                </submit-button>
                <submit-button
                  action="submitForGrading"
                  :disabled="isSubmitted || isOverdue"
                >
                  <template #default> Submit for Grading </template>

                  <template #submitting> Submitting </template>

                  <template #submitted> Submitted </template>
                </submit-button>
              </div>
            </div>
          </template>
        </sticky-header>
        <p v-if="isSubmitted">
          <strong>
            Because you have submitted this assignment for grading, you may not
            make any further changes to the assignment unless your instructor
            requests it.
          </strong>
        </p>
        <p v-else-if="isOverdue">
          <strong>
            Your instructor stopped accepting responses for this assignment on
            {{
              $format.date(
                response.assignment.endDate,
                'MM/dd/yyyy [at] HH:mm a'
              )
            }}. Your response will not be saved.
          </strong>
        </p>
        <activity-objectives
          :variable-context="variableContext"
          :view-as-student="true"
        />
        <activity-sections
          v-slot="{ section, index }"
          :class="{
            'student-activity-sections': viewMode === 'split',
            'split-activity-view': splitViewComponentEnabled
          }"
        >
          <activity-section
            class="student-activity-section"
            :section="section"
            :variable-context="variableContext"
            :view-as-student="true"
          >
            <activity-section-components
              v-slot="{ component }"
              :view-mode="viewMode"
              :split-view-component-enabled="splitViewComponentEnabled"
            >
              <activity-split-view-component
                v-if="component.componentType === 'SplitView'"
                :ref="el => (componentRefs[component._id] = el)"
                :component="component"
                :componentRefs="componentRefs"
                :variable-context="variableContext"
                @change="updateResponse"
                @submit="onAutogradeSubmit(component)"
                @needs-submission="
                  e => onNeedsSubmissionChanged(e, component._id)
                "
                @updateComponentRef="val => (componentRefs = val)"
                view-as-student
                :answersShown="false"
                :viewMode="viewMode"
              />
              <activity-section-component
                v-if="component.componentType !== 'SplitView'"
                v-slot="{ onSubmit, onComplete, onCanSubmitChange, canSubmit }"
                :ref="el => (componentRefs[component._id] = el)"
                :component="component"
                view-as-student
                @change="updateResponse"
                @submit="onAutogradeSubmit(component)"
                @needs-submission="
                  e => onNeedsSubmissionChanged(e, component._id)
                "
              >
                <activity-autosave-indicator
                  :autosaved-components="autosavedComponents"
                />
                <activity-section-component-renderer
                  :component="component"
                  :variable-context="variableContext"
                  :answersShown="false"
                  view-as-student
                  :canSubmit="canSubmit"
                  @submit="onSubmit"
                  @complete="onComplete"
                  @canSubmitChange="onCanSubmitChange"
                />
              </activity-section-component>
            </activity-section-components>
            <activity-section-lock
              :disabled="isSubmitted || isOverdue"
              :activityIndex="index"
              @lock="lockSection"
            />
          </activity-section>
        </activity-sections>
      </activity>
    </async-form>
  </loading-container>
</template>

<script>
import { mapState } from 'vuex'
import throttle from 'lodash/throttle'
import { AssignmentStudentFeedbackTimingValue } from '@pi/types'
import Activity from 'src/modules/activities/components/Activity'
import ActivityScore from 'src/modules/activities/components/ActivityScore'
import ActivityObjectives from 'src/modules/activities/components/ActivityObjectives'
import ActivitySections from 'src/modules/activities/components/ActivitySections'
import ActivitySectionComponents from 'src/modules/activities/components/ActivitySectionComponents'
import ActivitySectionComponent from 'src/modules/activities/components/ActivitySectionComponent'
import ActivitySectionLock from 'src/modules/activities/components/ActivitySectionLock'
import ActivityAutosaveIndicator from 'src/modules/activities/components/ActivityAutosaveIndicator'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import QuestionsNeedingSubmissionModal from 'src/shared/components/modals/QuestionsNeedingSubmissionModal.vue'
import client from 'src/shared/api-client'
import { notConcurrent } from 'src/setup/async'
import ActivitySection from 'src/modules/activities/components/ActivitySection'
import ActivitySectionComponentRenderer from '../components/ActivitySectionComponentRenderer.vue'
import ActivitySplitViewComponent from 'src/modules/activities/components/ActivitySplitViewComponent.vue'
import ActivityGradingProgress from 'src/modules/activities/components/ActivityGradingProgress.vue'
export default {
  name: 'StudentResponseView',
  components: {
    Activity,
    ActivityGradingProgress,
    ActivityScore,
    ActivityObjectives,
    ActivitySections,
    ActivitySectionComponents,
    ActivitySectionComponent,
    ActivitySectionLock,
    ActivityAutosaveIndicator,
    ActivitySectionComponentRenderer,
    ActivitySplitViewComponent,
    ActivitySection
  },
  inject: ['$modal'],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup() {
    const componentRefs = {}
    return { componentRefs }
  },
  data() {
    return {
      activity: null,
      response: null,
      componentIdsToAutosave: [],
      autosavedComponents: [],
      autogradedComponents: [],
      isLoading: true,
      isAutosaving: false,
      isMounted: false,
      componentIdsNeedingSubmission: new Set()
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    splitViewComponentEnabled() {
      return this.activity.splitViewComponentEnabled
    },
    isOverdue() {
      const assignmentEndDate = this.response?.assignment?.endDate
        ? new Date(this.response.assignment.endDate)
        : undefined
      const extensionEndDate = this.response?.extensionEndDate
        ? new Date(this.response.extensionEndDate)
        : undefined

      const endDate =
        extensionEndDate &&
        (!assignmentEndDate || extensionEndDate > assignmentEndDate)
          ? extensionEndDate
          : assignmentEndDate

      return (
        endDate &&
        endDate < new Date() &&
        this.response?.gradingProgress !== 'feedback'
      )
    },
    isSubmitted() {
      return !!(this.response?.activityProgress === 'submitted')
    },
    allowAutoGrade() {
      return (
        this.response.assignment.studentFeedbackTiming ===
        AssignmentStudentFeedbackTimingValue.AfterQuestionSubmit
      )
    }
  },
  methods: {
    async loadResponses() {
      try {
        return await client.assignments.getStudentResponse({
          assignmentId: this.id
        })
      } catch (error) {
        if (error.status === 404) {
          await client.assignments.createStudentResponses({
            assignmentId: this.id
          })
          return await client.assignments.getStudentResponse({
            assignmentId: this.id
          })
        } else {
          throw error
        }
      }
    },
    async load() {
      // if an instructor gets here, redirect to preview.
      if (this.user.role === 'teacher') {
        return this.$router.push({
          name: 'preview_assignment',
          params: { id: this.id }
        })
      }
      this.isLoading = true
      const [response, activity] = await Promise.all([
        this.loadResponses(),
        client.assignments.getActivity({ assignmentId: this.id })
      ])

      this.activity = activity
      this.response = {
        ...response,
        owner: this.user
      }
      if (this.allowAutoGrade) {
        this.autogradedComponents = this.activity.sections.reduce(
          (acc, section) => {
            acc = acc.concat(
              section.components
                .filter(
                  c =>
                    (c.componentType === 'MultipleChoiceQuestion' ||
                      c.componentType === 'NumericalQuestion') &&
                    c.autograde
                )
                .map(c => c._id)
            )
            return acc
          },
          []
        )
      }
      this.isLoading = false
    },
    save: notConcurrent(async function ({
      skipAutograded = true,
      componentIds
    } = {}) {
      let responsesToSave = componentIds
        ? componentIds.map(componentId =>
            this.response.responses.find(
              response => response.component === componentId
            )
          )
        : this.response.responses

      if (skipAutograded) {
        responsesToSave = responsesToSave.filter(
          response => !this.autogradedComponents.includes(response.component)
        )
      }

      // Clear autosaved components, while we are saving, new changes can be added to this array.
      const autosavingComponents = this.componentIdsToAutosave
      this.componentIdsToAutosave = []
      try {
        await client.assignments.saveResponse({
          assignmentId: this.id,
          components: responsesToSave.map(response => ({
            id: response.component,
            value: response.value ?? undefined
          })),
          lockSections: this.response.lockedSections
        })
      } catch (e) {
        // re-enqueue the autosaved components that failed
        this.queueAutosave(autosavingComponents)
        throw e
      }
      this.autosavedComponents = autosavingComponents

      await this.updateComponentResponses(
        responsesToSave.map(({ component }) => component)
      )
    }),
    // Save the whole response, and update autograded responses
    // with those from the assignment response from the server.
    async submitResponse() {
      // Clear autosaved components, while we are saving, new changes can be added to this array.
      const autosavingComponents = this.componentIdsToAutosave
      let assignmentRes
      try {
        await client.assignments.submit({
          assignmentId: this.id,
          responses: this.response.responses.map(response => ({
            component: response.component,
            value: response.value ?? undefined
          }))
        })
        await this.loadResponses()
      } catch (e) {
        // re-enqueue the autosaved components that failed
        this.queueAutosave(autosavingComponents)
        throw e
      }
      this.autosavedComponents = autosavingComponents
      this.componentIdsToAutosave = []
    },
    async submit(e) {
      try {
        if (e.action === 'submitForGrading') {
          if (this.componentIdsNeedingSubmission.size) {
            const { status } = await this.$modal.show(
              QuestionsNeedingSubmissionModal,
              {
                componentIdsNeedingSubmission:
                  this.componentIdsNeedingSubmission,
                activity: this.activity
              }
            )

            if (status !== 'ok') {
              e.done(false)
              return
            }
          } else {
            const { status } = await this.$modal.show(ConfirmModal, {
              text: 'Once you submit this assignment for grading, you will not be able to make any further changes unless your instructor requests them.',
              prompt:
                'Are you sure you want to submit this assignment for grading?'
            })

            if (status !== 'ok') {
              e.done(false)
              return
            }
          }
          await this.submitResponse()
        } else {
          await this.save({
            skipAutograded: false
          })
        }
        e.done()
        if (e.action === 'submitForGrading') {
          this.$success('Response successfully submitted for grading.')
          // Update the activity and grading progress to disable form if it was submitted for grading
          await this.load()
        } else {
          this.$success('Response progress saved successfully.')
          this.$router.push({ name: 'classes' })
        }
      } catch (error) {
        e.done(false)
        throw error
      }
    },
    queueAutosave(componentIds) {
      this.componentIdsToAutosave =
        this.componentIdsToAutosave.concat(componentIds)
      this._triggerAutosave()
    },
    _triggerAutosave: throttle(
      async function () {
        if (!this.isMounted) return

        if (this.componentIdsToAutosave.length === 0) {
          return
        }

        const componentIds = this.componentIdsToAutosave
        await this.save({ componentIds })
      },
      3000,
      { leading: false, trailing: true }
    ),
    updateResponse({ response, isDirty }) {
      if (this.isSubmitted || this.isOverdue) return

      // Update response object.
      const index = this.response.responses.findIndex(
        r => r.component === response.component
      )

      if (index >= 0) {
        this.response.responses.splice(index, 1, response)
      } else {
        this.response.responses.push(response)
      }

      this.autosavedComponents = this.autosavedComponents.filter(
        component => component !== response.component
      )

      if (!isDirty || !this.allowAutoGrade) {
        // if the response has been graded, update the dependency info
        this.$refs.activity.checkComponentDependencies()
      }

      // Skip autosaving of autograded components for now,
      // to force students to use the "submit answer" or "save" buttons
      if (this.autogradedComponents.includes(response.component)) return

      if (isDirty) {
        this.updateResponseVariableValue(response.component, response.value)
        this.queueAutosave([response.component])
      }
    },
    updateResponseVariableValue(id, value) {
      const activityVariable = this.activity.variables.find(
        v => v.content === id
      )
      if (!activityVariable) return
      this.response.variables = this.response.variables.map(v => {
        if (v.id === activityVariable.id) {
          return {
            ...v,
            value
          }
        }
        return v
      })
    },
    async updateComponentResponses(componentIds = []) {
      const responseResult = await client.assignments.getStudentResponse({
        assignmentId: this.id,
        componentIds
      })
      const responsesByComponent = responseResult.responses.reduce(
        (accm, response) => {
          accm[response.component] = response
          return accm
        },
        {}
      )

      const updatedResponse = {
        ...this.response,
        activityProgress: responseResult.activityProgress,
        gradingProgress: responseResult.gradingProgress,
        variables: responseResult.variables
      }

      // Update autograded mc questions with those from the response
      for (let i = 0; i < this.response.responses.length; i++) {
        const response = this.response.responses[i]
        const updated = responsesByComponent[response.component]
        if (updated && this.autogradedComponents.includes(response.component)) {
          updatedResponse.responses[i] = {
            ...responsesByComponent[response.component]
          }
        }
      }

      this.response = updatedResponse
    },
    onAutogradeSubmit({ _id, componentType }) {
      if (componentType === 'NumericalQuestion') {
        this.updateComponentResponses([_id])
      }
    },
    async lockSection(index) {
      this.response.lockedSections.push(index)
      await this.save()
    },
    onNeedsSubmissionChanged(event, id) {
      if (event) {
        this.componentIdsNeedingSubmission.add(id)
      } else {
        this.componentIdsNeedingSubmission.delete(id)
      }
    }
  },
  async created() {
    await this.load()
    this.$refs.activity?.checkComponentDependencies()
    let hashParams = window.location.hash
    hashParams = hashParams.replace('#component-', '')
    if (hashParams) {
      const el = this.componentRefs[hashParams]
      if (el) {
        el.$el.scrollIntoView()
      }
    }
  },
  async mounted() {
    this.isMounted = true
  },
  unmounted() {
    this.isMounted = false
  }
}
</script>

<style lang="scss" scoped>
.form-button:not(.modal-button-submit):not(.form-button--link) + .form-button {
  margin-right: 0;
}

.actions-submission {
  display: flex;
  flex-direction: column;
}

.action-buttons {
  & .form-button--secondary {
    margin-right: 6px;
  }
  @media (min-width: $screen-sm) {
    margin-left: 0px !important;
  }
  @media (min-width: $screen-md) {
    margin-left: auto !important;
  }
  @media (min-width: $screen-lg) {
    margin-left: auto !important;
  }
}

.submission-note {
  margin-bottom: 0;
  font-size: 12px;
}

.student-activity-sections {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-right: -50vw;
  margin-left: -50vw;
  padding-left: 30px;
  padding-right: 30px;
}
</style>
