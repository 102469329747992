import { unref } from 'vue'
import { Node, mergeAttributes, nodeInputRule } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import InlineVariable from './InlineVariable'
const VARIABLE_MATCH = /\[\$([A-Za-z0-9_ℹ]+(?:\.[A-Za-z0-9_ℹ]+)?)\]/

function processVariableMatch(match, variables) {
  const nameParts = match[1].split('.')
  const variable = variables.find(v => v.name === nameParts[0])
  let id
  if (variable && nameParts[1]) {
    const nested = variable.variables.find(v => v.name === nameParts[1])
    if (nested) {
      id = `${variable.id}.${nested.id}`
    }
  } else {
    id = variable?.id
  }

  if (id) {
    return {
      index: match.index,
      text: match[0],
      replaceWith: match[0],
      data: { id }
    }
  } else {
    return {
      index: match.index,
      text: match[0],
      replaceWith: match[0],
      data: { id: 'UNKNOWN' }
    }
  }
}

export function getVariable(id, variables = []) {
  const idParts = (id ?? '').split('.')
  const variable = variables.find(v => v.id === idParts[0])
  if (idParts[1] && variable) {
    const child = variable.variables.find(v => v.id === idParts[1])
    return {
      ...child,
      id,
      name: `${variable.name}.${child.name}`,
      length: variable?.dataSets?.length
    }
  } else {
    return variable
  }
}

const Variable = Node.create({
  name: 'variable',
  inline: true,
  group: 'inline',
  atom: true,
  selectable: true,

  addAttributes() {
    return {
      id: {
        default: '',
        parseHTML: element =>
          element.getAttribute('id') ?? element.textContent.trim()
      }
    }
  },

  addStorage() {
    return {
      variableContext: null
    }
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: text => {
          const { variables } = this.storage.variableContext.value
          const match = VARIABLE_MATCH.exec(text)
          if (match) {
            return processVariableMatch(match, variables)
          }
        },
        type: this.type,
        getAttributes: match => ({ id: match.data?.id })
      })
    ]
  },

  addKeyboardShortcuts() {
    return {
      Enter({ editor }) {
        if (editor.isActive('variable')) {
          editor.commands.openVariableModal()
          return true
        }
      }
    }
  },

  addCommands() {
    return {
      setVariable:
        attributes =>
        ({ commands }) => {
          const { variable } = attributes
          return commands.insertContent({
            type: this.name,
            attrs: {
              id: variable.id
            }
          })
        },
      openVariableModal: attrs => () => {
        this.storage.onEditVariable?.(attrs?.id)
      }
    }
  },

  addNodeView() {
    return VueNodeViewRenderer(InlineVariable)
  },

  renderHTML({ node, HTMLAttributes }) {
    return ['variable', mergeAttributes(HTMLAttributes)]
  },

  renderText({ node }) {
    if (!node.attrs.id) {
      return '{$UNKNOWN}'
    }
    const variable = getVariable(
      node.attrs.id,
      unref(this.storage.variableContext).variables
    )
    return `{$${variable?.name ?? 'UNKNOWN'}}`
  },

  parseHTML() {
    return [{ tag: 'inline-variable' }, { tag: 'span.ql-var' }]
  }
})

export default Variable
