<template>
  <ul class="nav navbar-nav navbar-right pi-menu">
    <nav-link
      :to="{ name: 'activities', query: { library: 'pivot' } }"
      @click="closeMenu"
      >Activities</nav-link
    >
    <nav-link
      v-if="!isSalesAdmin"
      :to="{ name: 'lti_platforms' }"
      @click="closeMenu"
    >
      LTI
    </nav-link>
    <nav-dropdown title="Admin">
      <nav-dropdown-action
        v-if="!isSalesAdmin"
        :to="{ name: 'admin_purchases' }"
        @click="closeMenu"
      >
        Generate Codes
      </nav-dropdown-action>
      <nav-dropdown-action :to="{ name: 'admin_licenses' }" @click="closeMenu">
        Licenses
      </nav-dropdown-action>
      <nav-dropdown-action
        :to="{ name: 'admin_manage_users' }"
        @click="closeMenu"
      >
        Users
      </nav-dropdown-action>
      <nav-dropdown-action
        v-if="!isSalesAdmin"
        :to="{ name: 'admin_vet_instructors' }"
        @click="closeMenu"
      >
        Vet Instructors
      </nav-dropdown-action>
      <nav-dropdown-action
        v-if="!isSalesAdmin"
        :to="{ name: 'admin_manage_invitations' }"
        @click="closeMenu"
      >
        Invitations
      </nav-dropdown-action>
      <nav-dropdown-action
        v-if="!isSalesAdmin"
        :to="{ name: 'admin_bookstore_purchases' }"
        @click="closeMenu"
      >
        Bookstore Purchases
      </nav-dropdown-action>
      <nav-dropdown-action
        v-if="!isSalesAdmin"
        :to="{ name: 'admin_search_quotes' }"
        @click="closeMenu"
      >
        Quotes
      </nav-dropdown-action>
    </nav-dropdown>

    <nav-dropdown :title="fullName || 'Welcome back!'">
      <nav-dropdown-action :to="{ name: 'edit_profile' }" @click="closeMenu">
        Profile
      </nav-dropdown-action>
      <nav-dropdown-action
        id="logout"
        :to="{ name: 'logout' }"
        @click="closeMenu"
      >
        Log out
      </nav-dropdown-action>
    </nav-dropdown>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import NavLink from 'src/shared/components/navigation/NavLink'
import NavDropdown from 'src/shared/components/navigation/NavDropdown'
import NavDropdownAction from 'src/shared/components/navigation/NavDropdownAction'
export default {
  name: 'AdminMenu',
  components: {
    NavLink,
    NavDropdown,
    NavDropdownAction
  },
  inject: ['closeMenu'],
  computed: {
    ...mapGetters(['fullName', 'isSalesAdmin'])
  }
}
</script>
