<template>
  <view-container narrow>
    <view-header>
      <view-title>Connect Pivot to {{ platform }}?</view-title>
    </view-header>
    <async-form @submit="connect">
      <form-group>
        <p>
          <strong>{{ platform }}</strong> would like to connect your Pivot
          Interactives account with the email <strong>{{ user.email }}</strong
          >. If this is not you, please log out.
        </p>
      </form-group>
      <form-group variant="right-aligned">
        <submit-button>
          <template #default>Connect</template>
          <template #submitting>Connecting</template>
          <template #submitted>Success</template>
        </submit-button>
        <form-button secondary @click="logOut"> Log Out </form-button>
      </form-group>
    </async-form>
  </view-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import client from 'src/shared/api-client'

export default {
  name: 'LtiConnectAccountView',
  data() {
    return {
      platform: null
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user || {}
    })
  },
  async mounted() {
    let ltiSession
    ltiSession = await client.lti.getSession()
    

    if (ltiSession.state !== 'CONNECT_ACCOUNT') {
      this.$router.push({ name: 'home' })
    } else {
      this.platform = ltiSession.context.platform.name
    }
  },
  methods: {
    ...mapActions(['refreshAuth']),
    async connect(e) {
      try {
        await client.lti.connectAccount({
          request: this.ltiSession
        })
        this.$success(`Your account has been connected to ${this.platform}.`)
        this.$router.push({ name: 'lti' })
        e.done()
       
        await this.refreshAuth()
      } catch (error) {
        e.done(false)
        if (error.status === 401) {
          this.$error('Incorrect email or password.')
        }
        throw error
      }
    },
    async logOut() {
      await client.auth.logout()
      window.location.assign(this.$router.resolve({ name: 'lti_login' }).href)
    }
  }
}
</script>

<style lang="scss" scoped>
.platform-name {
  font-style: italic;
}

.account-email {
  font-size: 16px;
  margin: 0 0 32px 0;
}
</style>
