<template>
  <view-container>
    <flash />
    <loading-container :loading="step === 'redirect'">
      <signup-start v-if="step === 'account-select'" />
      <login-selection v-if="step === 'login-select'" />
      <create-account-selection v-if="step === 'create-account'" student />
      <standard-login v-if="step === 'email'" />
      <profile-details-form v-if="step === 'sign-up'" student />
      <connect-sso-account v-if="step === 'connect'" />
      <multifactor-auth v-if="step === 'mfa'" />
    </loading-container>
  </view-container>
</template>

<script setup>
import client from 'src/shared/api-client'
import Flash from 'src/shared/components//Flash.vue'
import SignupStart from 'src/modules/auth/components/SignupStart.vue'
import LoginSelection from 'src/modules/auth/components/LoginSelection.vue'
import CreateAccountSelection from 'src/modules/auth/components/CreateAccountSelection.vue'
import StandardLogin from 'src/modules/auth/components/StandardLogin.vue'
import ProfileDetailsForm from 'src/modules/auth/components/ProfileDetailsForm.vue'
import ConnectSsoAccount from 'src/modules/auth/components/ConnectSsoAccount.vue'
import MultifactorAuth from 'src/modules/auth/components/MultifactorAuth.vue'

import { onBeforeMount, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useFlash } from 'src/shared/hooks/flash'

const router = useRouter()
const store = useStore()
const flash = useFlash()
const props = defineProps({
  step: {
    type: String,
    required: true
  },
  classKey: {
    type: String,
    default: undefined
  },
  redirect: {
    type: Object,
    default: () => ({ name: 'classes' })
  }
})

const klass = ref()

onBeforeMount(async () => {
  try {
    const body = await client.classes.validate({ classKey: props.classKey })
    klass.value = body
  } catch (error) {
    router.push({
      name: 'join_class',
      query: { classKey: props.classKey, redirect: props.redirect }
    })
    if (error.status === 404) {
      flash.error(`${props.classKey} is not a valid class key.`)
    } else {
      throw error
    }
  }
})

watchEffect(async () => {
  if (props.step === 'redirect' && klass.value) {
    if (klass.value.paymentSource === 'institution') {
      try {
        await client.classes.join({ classKey: props.classKey })
        window.location.assign(
          router.resolve({
            ...props.redirect,
            query: {
              ...props.redirect.query,
              flash: {
                success: `You joined the class: ${klass.value.name}.`
              }
            }
          }).href
        )
      } catch (error) {
        router.push({
          name: 'join_class',
          query: { classKey: props.classKey, redirect: props.redirect }
        })
        throw error
      }
    } else {
      window.location.assign(
        router.resolve({
          name: 'join_class_payment',
          query: { classKey: props.classKey, redirect: props.redirect }
        }).href
      )
    }
  } else if (store.getters.isAuthenticated && props.step !== 'redirect') {
    router.replace({
      name: 'join_class',
      query: { classKey: props.classKey, redirect: props.redirect }
    })
  }
})
</script>
