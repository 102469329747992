<template>
  <div v-if="hasPoints && showScore" class="activity-score">
    <span v-if="!previewingAsTeacher" :class="!grading ? 'score-prefix' : ''"
      >Score:
    </span>
    <span
      v-if="grading"
      :class="isStuck ? 'scoring-selection-stuck' : 'scoring-selection'"
      ><select-field
        :id="isStuck ? 'scoring-dropdown-stuck' : 'scoring-dropdown'"
        v-model="scoringType"
        @update:modelValue="
          value => handleScoringChange({ scoringType: value })
        "
      >
        <option value="point-value">Point Value</option>
        <option value="custom">Custom</option>
      </select-field>
      <number-input
        v-if="scoringType === 'custom'"
        :id="isStuck ? 'scoring-input-stuck' : 'scoring-input'"
        data-testid="custom-scoring-input"
        :modelValue="customScore"
        label="Custom Score"
        @input="value => handleScoringChange({ customScore: value })"
      ></number-input>
    </span>
    <span v-if="previewingAsTeacher" class="hidden-print"
      >Total Points: {{ max }} ({{ grade }})</span
    >
    <span v-else class="hidden-print"
      >{{ scoringType === 'custom' && grading ? '' : score }} / {{ max }} ({{
        grade
      }})</span
    >
    <span class="visible-print-inline">_______ / {{ max }}</span>
  </div>
</template>

<script>
export default {
  name: 'ActivityScore',
  inject: ['inherited'],
  emits: ['change'],
  props: {
    grading: {
      type: Boolean,
      default: false
    },
    isStuck: {
      type: Boolean,
      default: true
    },
    previewingAsTeacher: {
      type: Boolean,
      default: false
    },
    student: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      scoringType: null,
      customScore: null
    }
  },
  computed: {
    activity() {
      return this.inherited.activity
    },
    response() {
      return this.inherited.response
    },
    hasPoints() {
      return this.max > 0
    },
    showScore() {
      if (!this.student) return true
      return this.response?.assignment?.showScore
    },
    score() {
      if (this.response.scoringType !== 'custom') {
        let numScore = 0
        numScore = (this.response.responses || []).reduce(
          (total, response) => total + (response.score || 0),
          0
        )
        return Math.floor(numScore) === numScore
          ? numScore
          : numScore.toFixed(2)
      }
      return Math.floor(this.response.totalScore) === this.response.totalScore
        ? this.response.totalScore
        : this.response.totalScore.toFixed(2)
    },
    max() {
      if (this.activity.totalPointValue) {
        return this.activity.totalPointValue
      }
      return (this.activity.sections || []).reduce(
        (total, section) =>
          section.components.reduce(
            (sectionTotal, component) =>
              sectionTotal + (component.pointValue || 0),
            total
          ),
        0
      )
    },
    grade() {
      const { score, max, customScore } = this
      if (typeof customScore === 'number' && this.scoringType === 'custom') {
        return `${((customScore / max) * 100).toFixed(2)}%`
      } else if (customScore === null && this.scoringType === 'custom') {
        return '0%'
      }
      return `${((score / max) * 100).toFixed(2)}%`
    }
  },
  methods: {
    handleScoringChange({ scoringType, customScore }) {
      if (typeof scoringType === 'string') {
        if (this.score && !this.customScore) {
          this.customScore = this.score
        }
        this.$emit('change', { scoringType })
      }

      // Accept a null value and handle that in the parent for better input UX
      if (typeof customScore === 'number' || customScore === null) {
        this.customScore = customScore
        this.$emit('change', { customScore })
      }
    }
  },
  created() {
    this.scoringType = this.inherited.response.scoringType || 'point-value'
    this.customScore = this.inherited.response.totalScore || 0
  }
}
</script>

<style lang="scss">
.activity-score {
  margin: 0 10px 0px 10px;
  font-size: 14px;
  font-weight: 400;

  @media print {
    margin: 10px 0;
  }

  display: flex;
}

.scoring-selection {
  display: flex;
  margin: 0 10px;
  align-items: center;
  height: 30px;
}

#scoring-dropdown {
  display: flex;
  margin-right: 10px;
  width: 115px;
  height: 30px;
  padding: 0px;
  font-size: 18px;
}

#scoring-input {
  width: 60px;
  height: 30px;
  padding: 0px 12px;
  align-self: flex-start;
  font-size: 18px;
}

.scoring-selection-stuck {
  display: flex;
  margin: 0 10px;
  align-items: center;
  height: 20px;
}

#scoring-dropdown-stuck {
  display: flex;
  margin-right: 10px;
  width: 85px;
  height: 20px;
  padding: 0px;
  font-size: 12px;
}

#scoring-input-stuck {
  width: 35px;
  height: 20px;
  padding: 0px 6px;
  align-self: flex-start;
  font-size: 12px;
}

.score-prefix {
  margin-right: 5px;
}
</style>
