<template>
  <UICard class="tw-reset mt-6 max-w-[680px] mx-auto relative">
    <UICardHeading class="mb-4">Connect To Pivot Class</UICardHeading>
    <div class="rounded-sm bg-pivot-yellow-50 p-3 mb-2">
      <p>
        We noticed you have
        <span class="font-bold">{{ sectionCount }}</span> sections for
        {{ contextName }}. Would you like to customize how these sections are
        connected to Pivot Classes?
      </p>
      <div class="flex gap-1 mt-3">
        <UIButton size="sm" type="outline" @click="emit('select', 'sections')">
          Yes, customize how sections are connected
        </UIButton>
        <UIButton size="sm" type="invisible" @click="emit('select', 'context')">
          No, connect, all sections to one class
        </UIButton>
      </div>
    </div>
  </UICard>
</template>

<script setup lang="ts">
import { UICard, UIButton, UICardHeading } from '@pi/design'

const emit = defineEmits<{
  select: [mode: 'sections' | 'context']
}>()
withDefaults(
  defineProps<{
    contextName?: string
    sectionCount: number
  }>(),
  {
    contextName: 'your course'
  }
)
</script>
