<template>
  <ViewContainer>
    <LoadingContainer :loading="isLoading">
      <StickyHeader>
        <template #primary-navigation>
          <Breadcrumb>
            <BreadcrumbItem>
              {{ ltiSession?.context.platform.name }}
            </BreadcrumbItem>
            <BreadcrumbItem v-if="ltiSession?.context.context?.name">
              {{ ltiSession.context.context.name }}
            </BreadcrumbItem>
          </Breadcrumb>
        </template>
        <template #title>
          <StickyHeaderTitle> Connect Assignment </StickyHeaderTitle>
        </template>
        <template #actions="{ isStuck }">
          <UIButton
            type="outline"
            variant="destructive"
            :size="isStuck ? 'sm' : 'md'"
            @click="disconnectClass"
          >
            Disconnect class
          </UIButton>
        </template>
      </StickyHeader>
      <UICard
        class="tw-reset mt-6 max-w-[680px] mx-auto relative"
        header="Connect Pivot Assignment"
      >
        <div class="mb-6">
          <div class="flex justify-between items-start">
            <UICardHeading>Connect Pivot Assignment</UICardHeading>
            <UITextInput
              v-model="assignmentFilter"
              class="w-48 flex-shrink-0"
              name="assignment filter"
              placeholder="Filter Assignments"
              icon="search"
            />
          </div>
          <select
            v-if="sections.length > 0"
            class="font-bold text-pivot-teal-700 rounded focus:underline focus:outline-none"
            v-model="selectedSection"
            aria-label="section"
          >
            <option
              v-for="section in ltiSession?.context.sections"
              :key="section.id"
              :value="section.id"
            >
              {{ section.class?.name }}
            </option>
          </select>
        </div>
        <router-link
          className="flex items-center my-2 gap-2 block rounded-md border border-dashed border-pivot-teal-700 px-3 py-4 cursor-pointer focus-within:shadow-glow w-full text-pivot-teal-700 font-bold"
          :to="{
            name: 'lti_assign_activity'
          }"
        >
          Add Pivot Assignment <icon :icon="['fa', 'plus']" />
        </router-link>
        <UIForm @submit="onSubmit">
          <p v-if="assignments.length === 0">
            You have no existing assignments in this class.
            <UIButton
              type="link"
              :to="{
                name: 'lti_assign_activity'
              }"
              >Assign an activity</UIButton
            >
            from your library to get started!
          </p>
          <p v-else-if="filteredAssignments.length === 0">
            No assignments match your filter.
          </p>
          <RadioGroup
            v-else
            v-model="selectedAssignment"
            class="flex flex-col gap-2"
          >
            <RadioGroupLabel class="sr-only"
              >selected assignment</RadioGroupLabel
            >
            <RadioGroupOption
              v-for="assignment in filteredAssignments"
              :key="assignment.id"
              :value="assignment.id"
              class="block rounded-md border border-pivot-teal-700 px-3 py-2 cursor-pointer focus-within:shadow-glow outline-pivot-teal-700 outline-1 ui-checked:outline ui-checked:bg-pivot-teal-50"
            >
              <div class="flex justify-between">
                <RadioGroupLabel
                  class="font-bold whitespace-nowrap min-w-0 overflow-ellipsis overflow-hidden mr-2"
                >
                  {{ assignment.activity.name }}
                </RadioGroupLabel>
                <div class="font-bold flex-shrink-0" v-if="assignment.endDate">
                  Due
                  {{ formatDate(new Date(assignment.endDate), 'MMM d, YYY') }}
                </div>
              </div>
              <div class="flex justify-between">
                <RadioGroupDescription
                  class="whitespace-nowrap min-w-0 overflow-ellipsis overflow-hidden mr-2"
                >
                  {{ assignment.activity.description }}
                </RadioGroupDescription>
                <UIButton
                  type="link"
                  openInNewTab
                  :to="{
                    name: 'preview_assignment',
                    params: { id: assignment.id }
                  }"
                  class="flex-shrink-0"
                >
                  Preview
                  <UIIcon icon="external-link" class="ml-1" />
                </UIButton>
              </div>
            </RadioGroupOption>
          </RadioGroup>
          <div class="flex justify-end mt-5">
            <UIButton submit :disabled="!selectedAssignment">
              Connect Assignment
            </UIButton>
          </div>
        </UIForm>
      </UICard>
    </LoadingContainer>
  </ViewContainer>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'
import { format as formatDate } from 'date-fns'
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption
} from '@headlessui/vue'

import {
  UIButton,
  UICard,
  UIForm,
  UITextInput,
  UIIcon,
  UICardHeading,
  UILabel
} from '@pi/design'
import { GetLtiSessionResponse } from '@pi/api-types'
import client from 'src/shared/api-client'
import { useFlash } from 'src/shared/hooks/flash'
import { useRouter } from 'vue-router'
import { postRedirect } from 'src/setup/mixins/form-post'

const flash = useFlash()
const router = useRouter()

const ltiSession = ref<GetLtiSessionResponse>()
async function loadLtiSession() {
  ltiSession.value = await client.lti.getSession()

  if (ltiSession.value.state !== 'CONNECT_ASSIGNMENT') {
    await router.push({ name: 'lti' })
  }
}

interface Assignment {
  id: string
  activity: {
    name: string
    description?: string
  }
  endDate?: string
  isArchived?: boolean
}

const assignments = ref<Assignment[]>([])
const assignmentFilter = ref('')
const filteredAssignments = computed(() => {
  if (!assignmentFilter.value) return assignments.value

  // Escape regex reserved characters.
  const matcher = new RegExp(
    assignmentFilter.value
      .trim()
      .replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
      .replace(/-/g, '\\x2d'),
    'i'
  )
  return assignments.value.filter(a => matcher.test(a.activity.name))
})
async function loadAssignments(classId: string) {
  const request = await client.classes.getAssignments({ classId })
  assignments.value = (request as Assignment[]).filter(a => !a.isArchived)
}

const isLoading = ref(true)
async function load() {
  isLoading.value = true
  await loadLtiSession()
  let classId = ltiSession.value?.context.class?._id
  if (classId) {
    await loadAssignments(classId)
  }
  isLoading.value = false
}
onBeforeMount(load)

const sections = computed(
  () =>
    ltiSession.value?.context.sections?.filter(section => !!section.class) ?? []
)
async function switchSection(sectionId: string) {
  await client.lti.switchSection(sectionId)
  await load()
}
const selectedSection = computed({
  get() {
    return ltiSession.value?.context.class?.sectionId
  },
  async set(sectionId) {
    if (sectionId) {
      await switchSection(sectionId)
    }
  }
})

const selectedAssignment = ref<string>()
async function onSubmit(e: SubmitEvent) {
  e.preventDefault()
  if (!selectedAssignment.value) return

  let response
  response = await client.lti.connectAssignment({
    assignmentId: selectedAssignment.value
  })

  flash.success('Assignment connected successfully.')
  const { redirectUrl, token, openedInNewTab } = response
  if (openedInNewTab) {
    window.opener.postMessage(
      JSON.stringify({
        type: 'pivot:deep-link-response',
        redirectUrl,
        token
      }),
      `${window.location.protocol}//${window.PI_API_HOST}`
    )
    window.close()
  } else {
    postRedirect(redirectUrl, { JWT: token })
  }
}

async function disconnectClass() {
  await client.lti.disconnectClass()
  flash.success('Class disconnected successfully.')
  await router.replace({ name: 'lti_connect_class' })
}
</script>
