<template>
  <modal class="total-points-modal" @submit="submit">
    <modal-header id="modal-header">
      <span id="modal-title">Change Total Possible Points:</span>
      <template #secondary>
        <div class="points-input-group">
          <number-input
            id="points"
            v-model="customMaxScore"
            aria-label="total possible points"
            class="points-input"
            :class="{ 'error-border': higherTotalPossiblePoints }"
            :rules="`min_value:1|integer`"
            label="points"
          />

          <form-button
            v-if="customMaxScore !== defaultTotalPoints"
            id="reset-button"
            link
            @click="customMaxScore = defaultTotalPoints"
            >reset</form-button
          >
        </div>
      </template>
    </modal-header>
    <modal-body>
      <div class="points-breakdown">
        <div class="points-column">
          <span class="column-title"> Auto Graded </span>
          <p>{{ autoGradedPoints }} points</p>
        </div>
        <div class="points-column">
          <span class="column-title"> Instructor Graded </span>
          <p>{{ instructorGradedPoints }} points</p>
        </div>
        <div class="points-column">
          <span class="column-title"> Default Total </span>
          <p>{{ defaultTotalPoints }} points</p>
        </div>
      </div>
      <p v-if="lowerTotalPossiblePoints" class="lower-points">
        By lowering the total possible points, it is possible that the student's
        maximum score could be over 100%. Are you sure you wish to continue?
      </p>
      <p v-if="higherTotalPossiblePoints" class="higher-points">
        Cannot enter total possible points greater than the maximum value of the
        assignment.
      </p>
    </modal-body>
    <modal-actions class="modal-actions">
      <modal-button-submit :disabled="higherTotalPossiblePoints"
        >Save Changes</modal-button-submit
      >
      <modal-button-cancel />
      <hyper-link
        class="learn-more"
        :to="{
          name: 'total_possible_points_help'
        }"
        >Learn More</hyper-link
      >
    </modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalButtonSubmit,
  ModalButtonCancel,
  ModalHeader
} from 'src/shared/components/modals/components'

export default {
  name: 'TotalPossiblePointsModal',
  components: {
    Modal,
    ModalBody,
    ModalActions,
    ModalButtonSubmit,
    ModalButtonCancel,
    ModalHeader
  },
  props: {
    autoGradedPoints: {
      type: Number,
      default: 0
    },
    instructorGradedPoints: {
      type: Number,
      default: 0
    },
    totalPossiblePoints: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      customMaxScore: this.totalPossiblePoints
    }
  },
  methods: {
    submit(e) {
      e.done(this.customMaxScore)
    }
  },
  computed: {
    lowerTotalPossiblePoints() {
      return (
        this.totalPossiblePoints &&
        this.autoGradedPoints + this.instructorGradedPoints >
          this.customMaxScore
      )
    },
    higherTotalPossiblePoints() {
      return (
        this.totalPossiblePoints &&
        this.autoGradedPoints + this.instructorGradedPoints <
          this.customMaxScore
      )
    },
    defaultTotalPoints() {
      return this.autoGradedPoints + this.instructorGradedPoints
    }
  }
}
</script>

<style scoped lang="scss">
.total-points-modal {
  width: 540px;
}
#modal-title {
  font-size: 26px;
}
.points-input-group {
  margin-top: -4px;
}
.points-input {
  display: inline-block;
  width: 55px;
  margin: 0px 10px 0px 20px;
  font-size: 18px;
}
.points-breakdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
  font-size: 18px;
}
.points-column {
  display: flex;
  flex-direction: column;
}
.column-title {
  font-weight: bold;
}
.lower-points {
  font-weight: 600;
  color: $teal;
  font-size: 16px;
  margin-bottom: 0px;
}
.higher-points {
  color: $color-error;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0px;
}
.learn-more {
  margin-right: auto;
  text-decoration: underline;
  align-self: center;
}
#reset-button {
  color: $color-error;
  text-decoration: underline;
  font-size: 18px;
}
.error-border {
  border: 2px solid $color-error;
  border-radius: 4px;
}
.modal-actions {
  font-size: 16px;
}
</style>
