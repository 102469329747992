<template>
  <UICard class="mt-6 max-w-[680px] mx-auto relative">
    <div class="mb-6 flex justify-between items-start">
      <UICardHeading>
        {{ heading }}
      </UICardHeading>
      <UITextInput
        v-model="classFilter"
        class="w-48 flex-shrink-0"
        name="class filter"
        placeholder="Filter Classes"
        icon="search"
        :controlled="false"
      />
    </div>
    <p>Select Pivot class to connect:</p>
    <button
      @click="createClass"
      class="flex items-center my-2 gap-2 rounded-md border border-dashed border-pivot-teal-700 px-3 py-4 cursor-pointer focus-within:shadow-glow w-full text-pivot-teal-700 font-bold"
    >
      Add Pivot Class
      <UIIcon icon="plus" />
    </button>
    <p v-if="classes.length === 0">
      You have no existing classes in Pivot Interactives.
      <UIButton type="link" @click="createClass"> Connect Class </UIButton>
      to get started!
    </p>
    <p v-else-if="filteredClasses.length === 0">
      No classes match your filter.
    </p>
    <RadioGroup v-else v-model="value" class="flex flex-col gap-2">
      <RadioGroupLabel class="sr-only">selected class</RadioGroupLabel>
      <RadioGroupOption
        value="none"
        class="block rounded-md border border-pivot-teal-700 px-3 py-2 cursor-pointer focus-within:shadow-glow outline-pivot-teal-700 outline-1 ui-checked:outline ui-checked:bg-pivot-teal-50"
      >
        <RadioGroupLabel
          class="font-bold whitespace-nowrap min-w-0 overflow-ellipsis overflow-hidden mr-2"
          >Do Not Connect To A Class</RadioGroupLabel
        >
      </RadioGroupOption>
      <RadioGroupOption
        v-for="klass in filteredClasses"
        :key="klass.id"
        :value="klass.id"
        class="block rounded-md border border-pivot-teal-700 px-3 py-2 cursor-pointer focus-within:shadow-glow outline-pivot-teal-700 outline-1 ui-checked:outline ui-checked:bg-pivot-teal-50"
      >
        <RadioGroupLabel
          class="font-bold whitespace-nowrap min-w-0 overflow-ellipsis overflow-hidden mr-2"
        >
          {{ klass.name }}
        </RadioGroupLabel>
        <RadioGroupDescription>
          Created
          {{ formatDate(new Date(klass.createdAt), 'MMM d, YYY') }}
          <template v-if="klass.endDate">
            - Ends
            {{ formatDate(new Date(klass.endDate), 'MMM d, YYY') }}
          </template>
        </RadioGroupDescription>
      </RadioGroupOption>
    </RadioGroup>
    <slot name="actions" />
  </UICard>
</template>

<script setup lang="ts">
import { ref, computed, inject } from 'vue'
import { useField } from 'vee-validate'
import { format as formatDate } from 'date-fns'
import { useStore } from 'vuex'
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption
} from '@headlessui/vue'
import CreateClassModal from 'src/shared/components/modals/CreateClassModal.vue'
import RosterClassesModal from 'src/modules/classes/views/RosterClassesModal.vue'
import {
  UICard,
  UICardHeading,
  UITextInput,
  UIIcon,
  UIButton
} from '@pi/design'

const store = useStore()
const rostered = computed(() => store.state.auth.user.externalRoster)

interface Class {
  id: string
  name: string
  createdAt: Date
  endDate?: Date
}

const props = defineProps<{
  platformName: string
  className?: string
  sectionName?: string
  path: string
  classes: Class[]
}>()

const heading = computed(() => {
  let className = props.sectionName || props.className
  if (className) {
    return `${props.platformName}: ${className}`
  } else {
    return 'Connect Class'
  }
})

const { value } = useField<string>(props.path, 'required')
const modal = inject('$modal')
const emits = defineEmits(['refreshClassList'])
const classFilter = ref('')
const filteredClasses = computed(() => {
  if (!classFilter.value) return props.classes

  // Escape regex reserved characters.
  const matcher = new RegExp(
    classFilter.value
      .trim()
      .replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
      .replace(/-/g, '\\x2d'),
    'i'
  )
  return props.classes.filter(c => matcher.test(c.name))
})

const createClass = async () => {
  const { status } = await modal.show(CreateClassModal, {
    roster: rostered
  })
  if (status === 'ok') {
    emits('refreshClassList', true)
  }
  if (status === 'roster') {
    const { status } = await modal.show(RosterClassesModal, {})
    if (status === 'ok') {
      emits('refreshClassList', true)
    }
  }
}
</script>
