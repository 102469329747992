<template>
  <div class="data-grid__formula-bar">
    <select
      class="data-grid__formula-bar-dropdown"
      v-model="formulaType"
      :disabled="!hasSelectedCell"
    >
      <option v-if="!allowText" value="number">Number</option>
      <template v-else>
        <option value="latex">LaTeX</option>
        <option value="text">Text</option>
      </template>
      <option v-if="editMode && expressionsEnabled" value="expression">
        Expression
      </option>
    </select>
    <input
      ref="inputField"
      class="data-grid__formula-bar-input"
      v-model="formulaValue"
      :aria-label="`enter value for column ${columnIndex + 1} row ${
        typeof rowIndex === 'string' ? rowIndex : `row ${rowIndex + 1}`
      }`"
      :disabled="!hasSelectedCell"
    />
  </div>
</template>

<script setup>
import { computed, inject } from 'vue'
import { useStore } from 'vuex'
import { substituteVariablesExpression } from 'src/shared/utils/activity-variables'

const $gridActions = inject('$gridActions')
const store = useStore()
const props = defineProps({
  focusedCell: {
    type: Object,
    default: null
  },
  editMode: {
    type: Boolean,
    default: false
  },
  variableContext: {
    type: Object,
    default: undefined,
    required: false
  }
})

const expressionsEnabled = computed(() =>
  store.getters['features/isEnabled']('expressions')
)
const columnIndex = computed(() => props.focusedCell?.column)
const rowIndex = computed(() => props.focusedCell?.row)

const allowText = computed(() => props.focusedCell?.allowText ?? false)

const formulaValue = computed({
  get() {
    return formulaType.value === 'expression'
      ? substituteVariablesExpression(
          props.focusedCell?.value?.value ?? '',
          props.variableContext?.variables ?? [],
          'id'
        )
      : props.focusedCell?.value?.value ?? ''
  },
  set(value) {
    $gridActions.setValueAtColumnAndRow(
      columnIndex.value,
      rowIndex.value,
      formulaType.value === 'latex'
        ? { formula: value, parser: formulaType.value }
        : formulaType.value === 'expression'
        ? {
            formula: value,
            id: substituteVariablesExpression(
              value,
              props.variableContext?.variables ?? []
            ),
            parser: formulaType.value
          }
        : value
    )
  }
})

const formulaType = computed({
  get() {
    return props.focusedCell?.value?.dataType ?? 'text'
  },
  set(type) {
    $gridActions.setValueAtColumnAndRow(
      columnIndex.value,
      rowIndex.value,
      type === 'latex' || type === 'expression'
        ? { formula: formulaValue.value, parser: type }
        : formulaValue.value
    )
  }
})

const hasSelectedCell = computed(
  () => columnIndex.value !== null && rowIndex.value !== null
)
</script>

<style lang="scss" scoped>
.data-grid-header__menu-cell {
  width: 75px;
}
.data-grid__formula-bar {
  height: $grid-row-default-height;
  display: flex;
  justify-items: flex-start;
  align-items: stretch;

  max-width: 100%;
  border: 1px solid #979797;
}

.data-grid__formula-bar-input {
  display: inline-flex;
  height: 100%;
  width: 100%;
  border: 2px solid transparent;
  padding: 0 5px;
  font-size: 14px;
  color: #000;
  background-color: #fff !important;
  outline: none;

  &:focus {
    border-color: $teal;
  }

  &:disabled {
    background-color: #f5f5f59e !important;
  }
}

.data-grid__formula-bar-dropdown {
  padding-left: 2px;
  padding-right: 2px;
  border: none;
  box-shadow: none;
  height: 100%;
  border-right: 1px solid #979797;
  font-size: 14px;

  &:focus {
    background: #cdf6f6;
  }

  &:disabled {
    background-color: #f5f5f59e !important;
  }
}
</style>
