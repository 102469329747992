<template>
  <div>
    <div class="form-group row">
      <phet-io-simulation
        :config="component.config"
        :variables="variableContext.variables"
        edit-mode
      />
    </div>
    <template v-if="isAdmin || isContentDeveloper">
      <form-group class="row">
        <label class="col-sm-2 control-label" :for="`${id}-simName`">
          Sim Name
        </label>
        <div class="col-md-6 col-sm-8">
          <text-input
            :id="`${id}-simName`"
            label="sim name"
            :rules="{ required: true }"
            v-model="parsedConfig.simName"
            disabled
          />
        </div>
      </form-group>
      <form-group class="row">
        <label class="col-sm-2 control-label" :for="`${id}-simVersion`">
          Sim Version
        </label>
        <div class="col-md-6 col-sm-8">
          <text-input
            :id="`${id}-simVersion`"
            label="sim version"
            :rules="{ required: true }"
            v-model="parsedConfig.simVersion"
            disabled
          />
        </div>
      </form-group>
      <form-group class="row">
        <label class="col-sm-2 control-label" :for="`${id}-simWrapper`">
          Sim Wrapper
        </label>
        <div class="col-md-6 col-sm-8 click-download-field">
          <text-input
            :id="`${id}-simWrapper`"
            :rules="{ required: true }"
            class="click-download-field__text"
            label="sim wrapper"
            v-model="parsedConfig.wrapper"
            disabled
          />
          <form-button
            :disabled="!parsedConfig.wrapper"
            class="click-download-field__button"
            aria-label="Download wrapper file"
            @click="download"
          >
            Download
          </form-button>
        </div>
      </form-group>
      <form-group class="row">
        <label class="col-sm-2 control-label" :for="`${id}-params`">
          Custom Values
        </label>
        <div class="col-sm-10">
          <div v-if="parsedConfig.values.length > 0" class="value-row">
            <label class="control-label">PhET-iO ID</label>
            <label class="control-label">Value</label>
          </div>
          <div
            v-for="(value, index) in parsedConfig.values"
            :key="index"
            class="value-row"
          >
            <text-input
              v-model="parsedConfig.values[index].key"
              label="key"
              :rules="{ required: true }"
              @change="onUpdate"
            />
            <text-input
              v-model="parsedConfig.values[index].value"
              label="value"
              :rules="{
                required: true,
                phetioValue: {
                  variables: variableContext.variables
                }
              }"
              @change="onUpdate"
            />
            <form-button secondary @click="removeValue(index)">
              <icon icon="close" />
              <span class="sr-only">Remove</span>
            </form-button>
          </div>
          <form-button secondary @click="addValue">
            <icon icon="plus" />
            Add Value
          </form-button>
        </div>
      </form-group>
      <div class="form-group row">
        <div class="col-md-6 col-sm-8">
          <input
            ref="fileInput"
            :id="`${id}-file`"
            type="file"
            label="Studio HTML"
            accept="text/html"
            @change="onFileChange"
          />
          <form-button type="button" @click="uploadWrapper"
            >Upload Wrapper</form-button
          >
        </div>
      </div>
      <iframe class="wrapper" ref="iframe" src="about:blank"></iframe>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PhetIoSimulation from 'src/shared/components/PhetIoSimulation'
import {
  substituteVariablesExpression,
  substituteVariablesMathjs
} from 'src/shared/utils/activity-variables'

let idCounter = 0

export default {
  name: 'EditPhetIoSimulation',
  components: { PhetIoSimulation },
  inject: ['inherited'],
  emits: ['change'],
  props: {
    component: {
      required: true
    },
    variableContext: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      parsedConfig: {
        simName: '',
        simVersion: '',
        wrapper: '',
        values: []
      }
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'isContentDeveloper']),
    id() {
      return `edit-phet-simulation-${idCounter++}`
    }
  },
  methods: {
    convertVariables(config, replace) {
      return {
        ...config,
        values: config.values.map(({ key, value }) => ({
          key,
          value: value.match(/^mathjs\(.+\)$/)
            ? substituteVariablesMathjs(
                value,
                this.variableContext.variables,
                replace
              )
            : substituteVariablesExpression(
                value,
                this.variableContext.variables,
                replace
              )
        }))
      }
    },
    async onFileChange() {
      if (this.$refs.fileInput.files[0]) {
        try {
          const { simName, simVersion, wrapper } =
            await this.validateWrapperFile(this.$refs.fileInput.files[0])

          this.parsedConfig.simName = simName
          this.parsedConfig.simVersion = simVersion
          this.parsedConfig.wrapper = wrapper
        } catch (error) {
          this.$error('Error validating PhET-iO wrapper HTML file.')

          return
        } finally {
          this.$refs.fileInput.value = null
        }
      }

      this.onUpdate()
    },
    onUpdate() {
      this.$emit('change', {
        config: JSON.stringify(this.convertVariables(this.parsedConfig, 'name'))
      })
    },
    getWrapperFromFile(file) {
      // Read file as base64 and return without data prefix
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = event => {
          resolve(event.target.result.replace('data:text/html;base64,', ''))
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    },
    getSimNameAndVersionFromIFrame() {
      // Wait for iframe to load and try to pull
      // sim name and version from phetio context.
      return new Promise((resolve, reject) => {
        this.$refs.iframe.onload = () => {
          const phetio = this.$refs.iframe.contentWindow.phetio
          if (!phetio) {
            return reject(
              new Error(
                'Loaded HTML file does not appear to be PhET-iO wrapper'
              )
            )
          }

          return resolve({
            simName: phetio.Client.SIMULATION_NAME,
            simVersion: phetio.Client.SIMULATION_VERSION
          })
        }
      })
    },
    async validateWrapperFile(file) {
      const wrapper = await this.getWrapperFromFile(file)

      // Create a blob from the decoded base64 wrapper HTML
      const blob = new Blob([atob(wrapper)], {
        type: 'text/html'
      })

      // Set the source of the iframe to the wrapper HTML
      this.$refs.iframe.src = URL.createObjectURL(blob)

      const { simName, simVersion } =
        await this.getSimNameAndVersionFromIFrame()

      if (this.$refs.iframe) {
        this.$refs.iframe.src = 'about:blank'
      }

      return { simName, simVersion, wrapper }
    },
    addValue() {
      this.parsedConfig.values.push({ key: '', value: '' })
      this.onUpdate()
    },
    removeValue(index) {
      this.parsedConfig.values.splice(index, 1)
      this.onUpdate()
    },
    uploadWrapper() {
      this.$refs.fileInput.click()
    },
    download() {
      const filename = `${this.parsedConfig.simName}-custom-wrapper.html`
      const blob = new Blob([atob(this.parsedConfig.wrapper)], {
        type: 'text/html;charset=utf-8;'
      })
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, filename)
      } else {
        const link = document.createElement('a')
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', filename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  },
  watch: {
    component: {
      handler(value) {
        try {
          this.parsedConfig = this.convertVariables(
            JSON.parse(value.config),
            'id'
          )
        } catch {
          this.parsedConfig = {
            simName: '',
            simVersion: '',
            wrapper: '',
            values: []
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
input[type='file'] {
  width: 0px;
  height: 0px;
  margin: 0px;
  padding: 0px;
  visibility: hidden;
}

.click-download-field {
  display: flex;
  align-items: flex-start;
}

.click-download-field__button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  height: 36px;
}

.click-download-field__text {
  flex-grow: 1;

  &:deep(input) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.value-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 5px;
}

.value-row:first-child {
  padding-bottom: 0px;
  padding-right: 41px;
}

.value-row > span,
.value-row > label,
.value-row > div {
  flex: 1;
  text-align: left;
  padding-right: 5px;
}

.wrapper {
  display: none;
}
</style>
