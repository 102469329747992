<template>
  <node-view-wrapper
    :as="isReadonly ? 'span' : 'button'"
    type="button"
    :tabindex="isReadonly ? null : -1"
    :class="{
      '-my-[2px] -mx-1 px-1 rounded border-none bg-transparent hover:bg-gray-300 focus:bg-gray-300':
        !isReadonly,
      'bg-gray-300': !isReadonly && selected,
      'bg-transparent': !isReadonly && !selected,
      'bg-pivot-purple-50':
        isReadonly &&
        hasRandomVariables &&
        !hasResponseVariables &&
        !viewAsStudent,
      'bg-pivot-yellow-50': isReadonly && hasResponseVariables,
      'text-ui-gray-800': toolTip
    }"
    @click="() => !isReadonly && onClick()"
    :data-testid="node.attrs.id"
    ref="popoverAnchorRef"
    @mouseover="openPopover"
    @mouseout="closePopover"
  >
    <template v-if="isReadonly">
      <base-popover
        v-if="hasResponseVariables || (hasRandomVariables && !viewAsStudent)"
        placement="top"
        :anchor-ref="popoverAnchorRef"
        :visible="popoverVisible"
      >
        {{ variablePopText }}
      </base-popover>
      <icon
        v-if="hasResponseVariables"
        class="text-pivot-yellow-700 mr-2"
        icon="circle-info"
      />
      <icon
        v-else-if="hasRandomVariables && !viewAsStudent"
        class="text-pivot-purple-600 mr-2"
        icon="shuffle"
      />{{ canEvaluate ? value : 'Student Response' }}
    </template>
    <span v-else class="text-pivot-teal-700 font-bold">
      [expr: {{ expression }}]
    </span>
  </node-view-wrapper>
</template>

<script setup>
import { computed, ref } from 'vue'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3'
import { substituteVariablesExpression } from 'src/shared/utils/activity-variables'
import { evaluateExpression } from './Expression.js'

const props = defineProps(nodeViewProps)

const popoverAnchorRef = ref(null)
const popoverVisible = ref(false)
function closePopover() {
  popoverVisible.value = false
}
function openPopover() {
  popoverVisible.value = true
}

const isReadonly = computed(() => !props.editor.isEditable)
const viewAsStudent = computed(() => props.extension.storage.viewAsStudent)

const variables = computed(
  () => props.extension.storage.variableContext.variables
)
const usedVariables = computed(() =>
  props.node.attrs.variables.map(vid => variables.value.find(v => v.id === vid))
)
const responseVariables = computed(() =>
  usedVariables.value.filter(v => v.variableType === 'studentResponse')
)

const expression = computed(() =>
  substituteVariablesExpression(props.node.attrs.expr, variables.value, 'id')
)
const canEvaluate = computed(() =>
  responseVariables.value.every(v => v.content !== v.value)
)
const value = computed(() =>
  evaluateExpression(props.node.attrs.expr, variables.value)
)

const toolTip = computed(() => props.extension.storage.toolTip)
const hasResponseVariables = computed(() => responseVariables.value.length)
const hasRandomVariables = computed(() =>
  usedVariables.value.some(v => v.variableType !== 'studentResponse')
)
const variablePopText = computed(() => {
  if (hasResponseVariables.value) {
    const questions = responseVariables.value
      .map(v => `S${v.section}.Q${v.question}`)
      .join(', ')
    return `This value is dervied from responses to the following questions: ${questions}`
  } else if (hasRandomVariables.value) {
    return 'This value is random for each student.'
  } else {
    return ''
  }
})

const onClick = () => {
  props.editor
    .chain()
    .setNodeSelection(props.getPos())
    .openExpressionModal()
    .run()
}
</script>
