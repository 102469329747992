<template>
  <resizable
    :as="NodeViewWrapper"
    class="inline-image__wrapper"
    v-model:size="size"
    :aspect-ratio="rawSize.height ? rawSize.width / rawSize.height : 0"
    :focused="!isReadonly && selected"
    draggable="false"
    @click="!isReadonly && onClick"
    @resizestart="() => video?.pause()"
  >
    <img
      v-if="isImage"
      class="inline-image__img"
      v-bind="attrs"
      ref="image"
      draggable="false"
    />
    <video
      v-else
      class="inline-image__img"
      v-bind="attrs"
      ref="video"
      :controls="showControls"
    />
  </resizable>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3'
import Resizable from '../components/Resizable.vue'

const props = defineProps(nodeViewProps)

const attrs = computed(() => {
  const { width, height, ...rest } = props.node.attrs
  return {
    ...rest,
    src:
      props.node.attrs.src &&
      !props.node.attrs.src.startsWith('data:') &&
      !props.node.attrs.src.startsWith('http')
        ? `//${window['PI_API_HOST']}${props.node.attrs.src}`
        : props.node.attrs.src
  }
})
const isReadonly = computed(() => !props.editor.view.editable)

const isImage = computed(
  () => !!attrs.value.src?.match(/\.(jpg|jpeg|png|gif|bmp|svg|ico)$/i)
)

const showControls = ref(false)
watch(
  () => props.selected,
  selected => {
    if (selected && !showControls.value) {
      // We have to delay turning on the controls so that clicking to focus the video doesn't start it playing.
      setTimeout(() => {
        showControls.value = true
      }, 100)
    } else {
      showControls.value = selected
    }
  }
)

const image = ref<HTMLImageElement>()
const video = ref<HTMLVideoElement>()

const rawSize = ref({ width: 0, height: 0 })
watch(
  () => image.value || video.value,
  (el, _, cleanup) => {
    if (el) {
      const handler = () => {
        rawSize.value = {
          width:
            el instanceof HTMLImageElement ? el.naturalWidth : el.videoWidth,
          height:
            el instanceof HTMLImageElement ? el.naturalHeight : el.videoHeight
        }
      }
      el.addEventListener('load', handler)
      cleanup(() => {
        el.removeEventListener('load', handler)
      })
    }
  }
)

const size = computed({
  get() {
    return {
      width: (props.node.attrs.width as number) ?? rawSize.value.width,
      height: (props.node.attrs.height as number) ?? rawSize.value.height
    }
  },
  set(size) {
    props.updateAttributes(size)
  }
})

function onClick() {
  props.editor.commands.setNodeSelection(props.getPos())
}
</script>

<style lang="scss" scoped>
.inline-image__wrapper {
  display: inline-block;
  background: none;
  border: 0px;
  padding: 0;

  .inline-image__img {
    width: 100%;
    pointer-events: none;
    vertical-align: unset;
  }
}
</style>
