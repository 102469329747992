<template>
  <div
    ref="popoverAnchorRef"
    @mouseover="openPopover"
    @mouseout="closePopover"
    @focusin="openPopover"
    @focusout="closePopover"
  >
    <base-popover
      :anchor-ref="popoverAnchorRef"
      :visible="popoverVisible"
      placement="top"
      >This cell is pre-populated and cannot be edited
    </base-popover>
    <span>{{ valueForDisplay }}</span>
  </div>
</template>
<script setup lang="ts">
import {
  substituteVariablesExpression,
  evaluateExpression
} from 'src/shared/utils/activity-variables'
import math from 'src/setup/math'
import { computed, ref } from 'vue'
import {
  VariableContextConfig,
  VariableConfig,
  VariableValue
} from 'src/shared/types/activity-variables'

interface Props {
  expression: string
  readOnly: boolean
  variableContext?: VariableContextConfig
}
const popoverAnchorRef = ref()
const popoverVisible = ref<boolean>(false)
const props = defineProps<Props>()

const closePopover = () => {
  popoverVisible.value = false
}
const openPopover = () => {
  popoverVisible.value = true
}

const valueForDisplay = computed<string>(() => {
  return props.readOnly
    ? evaluateExpression(
        props.expression,
        props.variableContext?.variables ?? []
      )
    : substituteVariablesExpression(
        props.expression,
        props.variableContext?.variables ?? [],
        'id'
      )
})
</script>
