import CreateAssignmentView from './views/CreateAssignmentView'
import SelectQuestionsToGradeView from './views/SelectQuestionsToGradeView'
import AssignmentView from './views/AssignmentView'
import GradeByQuestionView from './views/GradeByQuestionView'

export default store => {
  return [
    {
      name: 'new_assignment',
      path: '/activities/assign',
      component: CreateAssignmentView,
      props: route => ({
        activityIds:
          typeof route.query.activityIds === 'string'
            ? [route.query.activityIds]
            : route.query.activityIds,
        classIds:
          typeof route.query.classIds === 'string'
            ? [route.query.classIds]
            : route.query.classIds
      }),
      meta: { title: 'Create Assignment', requiresAuth: true }
    },
    {
      name: 'assignment_scores',
      path: '/assignments/:assignmentId/:tab?',
      component: AssignmentView,
      props: route => ({
        assignmentId: route.params.assignmentId,
        tab: route.params.tab || 'scores'
      }),
      meta: { title: 'Scores', requiredAuth: true }
    },
    {
      name: 'select_questions_to_grade',
      path: '/assignments/:assignmentId/select-questions',
      component: SelectQuestionsToGradeView,
      props: true,
      meta: { title: 'Select Questions to Grade', requiredAuth: true }
    },
    {
      name: 'grade_assignment',
      path: '/assignments/:assignmentId/grade',
      component: GradeByQuestionView,
      props: ({ params, query }) => {
        return {
          assignmentId: params.assignmentId,
          questions: Object.keys(query.question).map(id => {
            const defaultScore = parseInt(query.question[id])
            return {
              id,
              defaultScore: isNaN(defaultScore) ? undefined : defaultScore
            }
          }),
          gradeAnonymously: query.gradeAnonymously?.toString() === 'true'
        }
      },
      meta: { title: 'Grade', requiredAuth: true }
    }
  ]
}
