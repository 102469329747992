<template>
  <div class="drag-drop-area" ref="root">
    <template v-if="type === 'latex'">
      <template
        v-if="
          hasStudentResponseVariables || (!viewAsStudent && hasRandomVariables)
        "
      >
        <base-popover
          placement="top"
          :anchor-ref="popoverAnchor"
          :visible="popoverVisible"
        >
          {{ variablePopText }}
        </base-popover>
        <icon
          class="mr-2"
          :class="
            hasRandomVariables
              ? 'text-pivot-purple-600'
              : 'text-pivot-yellow-700'
          "
          :icon="hasRandomVariables ? 'shuffle' : 'circle-info'"
          ref="popoverAnchor"
          @mouseover="openPopover"
          @mouseout="closePopover"
        />
      </template>
      <LatexBlock
        :latex="latexForDisplay"
        @render="onLatexRender"
        @pointerdown="onPointerDown"
      />
    </template>
    <EditorContent
      v-else
      :text="text"
      :choices="choices"
      :variable-context="variableContext"
      :view-as-student="viewAsStudent"
      :drop-targets="response"
      :target-states="targetStates"
      @droptargetchange="richTextTargetChange"
    />
    <div v-for="(element, index) in targets" :key="index">
      <div
        v-if="element.targetType === 'dropdown'"
        :ref="el => (dropdowns[element.id] = el as HTMLElement)"
        class="target-dropdown"
      >
        <DropTargetFillInTheBlankMenu
          :choices="choices"
          :variable-context="variableContext"
          :target-values="response"
          :target="element.id"
          :type="type"
          :button-ref="targetsElements[element.id]"
          :click-container="root"
          :view-as-student="viewAsStudent"
          @select="choice => selectChoice(element.id, choice)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watchEffect } from 'vue'
import katex from 'katex'
import { indexToLetter } from '../../../shared/utils/index-to-letter'
import {
  getLatexForDisplay,
  getVariablesFromLatex
} from '../../../shared/utils/latex-methods'
import DropTargetFillInTheBlankMenu from './DropTargetFillInTheBlankMenu.vue'
import useDragging from '../useDragging'
import {
  computePosition,
  autoUpdate,
  flip,
  shift,
  offset
} from '@floating-ui/dom'
interface Target {
  id: string
  letter: string
  targetType: string
}

interface Choice {
  id: string
  text: string
  validTargets?: string[]
}

interface Response {
  [targetId: string]: string | undefined
}

interface TargetStates {
  [targetId: string]: 'invalid' | 'incorrect' | 'correct' | undefined
}

interface Props {
  type: 'latex' | 'rich-text'
  variableContext: any
  text: string
  choices: Choice[]
  response: Response
  targets: Target[]
  targetStates: TargetStates
  viewAsStudent?: boolean
}
const dropdowns = ref<Record<string, HTMLElement | null>>({})
const targetsElements = ref<Record<string, HTMLElement | null>>({})
const root = ref<HTMLElement | null>(null)

const emit = defineEmits<{
  move: [{ sourceTarget?: string; destTarget?: string; choice: string }]
}>()
const props = defineProps<Props>()

onMounted(() => {
  if (props.type === 'rich-text') {
    setTimeout(() => {
      props.targets.forEach(element => {
        const selector = `div[data-target="${element.id}"]`
        const selectedElement = root.value?.querySelector<HTMLElement>(selector)
        targetsElements.value[element.id] = selectedElement ?? null
      })
    }, 300)
  }
})
const openTarget = ref<string | null>()

const watchTarget = computed(() => props.targetStates)

function richTextTargetChange(targetId: string, choiceId?: string) {
  if (choiceId) {
    emit('move', { choice: choiceId, destTarget: targetId })
  } else {
    const choice = props.response[targetId] ?? ''
    emit('move', { choice, sourceTarget: targetId })
  }
}

const selectChoice = (targetId: string, choice: string) => {
  emit('move', { choice, destTarget: targetId })
}

const latexForDisplay = computed(() => {
  let counter = 0
  let choiceInDropTarget
  const latexWithDropTargets = (props.text ?? '').replaceAll(
    /\\droptarget\{(.+?)\}(?:\{(.+?)\})?/g,
    (match, id, label) => {
      const indexLetter = indexToLetter(counter++)
      const labelText = label ? label : indexLetter
      const targetState = props.targetStates?.[id]
      const target = props.targets.find(t => t.id === id)

      const emptyDropTargetLabel = `${labelText}`

      choiceInDropTarget = props.choices.find(choice => {
        return choice.id === props.response[id]
      })

      if (target?.targetType === 'dropdown') {
        return choiceInDropTarget
          ? `\\ref{${id}}{$${choiceInDropTarget.text}$~~}`
          : `\\ref{${id}}{${emptyDropTargetLabel}~~}`
      }

      return choiceInDropTarget
        ? `\\ref{${id}}{$${choiceInDropTarget.text}$}`
        : `\\ref{${id}}{${emptyDropTargetLabel}}`
    }
  )
  return latexWithDropTargets
    ? getLatexForDisplay({
        variables: props.variableContext.variables,
        formula: latexWithDropTargets
      })
    : ''
})

function onLatexRender(root: HTMLElement) {
  const katexElements = root.querySelectorAll<HTMLElement>('.katex-ref')
  if (katexElements) {
    katexElements.forEach(element => {
      element.classList.add('drop-target')
      if (element.dataset.ref) {
        const target = props.targets.find(t => t.id === element.dataset.ref)
        if (target && target.targetType === 'dropdown') {
          element.classList.add('drop-target--dropdown')
          element.tabIndex = 0
        }
        const targetState = props.targetStates?.[element.dataset.ref]
        if (targetState) {
          element.classList.add(targetState)
        }
        if (props.response[element.dataset.ref]) {
          element.classList.add('filled')
          element.tabIndex = 0
        }
        targetsElements.value[element.dataset.ref] = element
      }
    })
  }
}
watchEffect(onCleanup => {
  const cleanupTargets = props.targets.map(element => {
    if (element.id) {
      const targetEl = targetsElements.value[element.id]
      const dropDownEl = dropdowns.value[element.id]
      if (
        targetEl instanceof HTMLElement &&
        dropDownEl instanceof HTMLElement
      ) {
        return autoUpdate(targetEl, dropDownEl, async () => {
          if (targetEl instanceof HTMLElement && dropDownEl) {
            const { x, y } = await computePosition(targetEl, dropDownEl, {
              placement: 'bottom-end',
              middleware: [offset(0), flip(), shift()]
            })
            if (dropDownEl) {
              Object.assign(dropDownEl.style, {
                ...(targetEl.offsetWidth > dropDownEl.offsetWidth && {
                  width: `${targetEl.offsetWidth}px`
                }),
                left: `${x}px`,
                top: `${y}px`
              })
            }
          }
        })
      }
    }
    return
  })
  onCleanup(() => {
    for (const cleanup of cleanupTargets) {
      cleanup?.()
    }
  })
})
let draggedChoice: Choice | undefined

const { onPointerDown } = useDragging({
  renderChoiceGhost(root) {
    if (draggedChoice) {
      const latexForDisplay = getLatexForDisplay({
        variables: props.variableContext.variables,
        formula: draggedChoice.text
      })
      katex.render(latexForDisplay, root, {
        throwOnError: false
      })
    }
  },
  onDragStart(e) {
    const target = document
      .elementsFromPoint(e.clientX, e.clientY)
      .find(el => el.getAttribute('data-ref'))
    if (!target || !(target instanceof HTMLElement)) return false

    const targetId = target.dataset.ref
    if (!targetId) return false

    const choice = props.choices.find(
      choice => choice.id === props.response[targetId]
    )
    if (choice) {
      draggedChoice = choice
      emit('move', { sourceTarget: targetId, choice: choice.id })
      return true
    }
    return false
  },
  onDragEnd(e, targetId) {
    if (targetId && draggedChoice) {
      emit('move', { destTarget: targetId, choice: draggedChoice.id })
    }
  }
})

const popoverVisible = ref(false)
const popoverAnchor = ref<HTMLElement>()
const openPopover = () => {
  popoverVisible.value = true
}
const closePopover = () => {
  popoverVisible.value = false
}
const usedVariablesInLatex = computed(() => {
  if (props.type === 'rich-text') return []
  return getVariablesFromLatex({
    formula: props.text,
    variables: props.variableContext?.variables
  })
})
const studentResponseVariablesInLatex = computed(() =>
  usedVariablesInLatex.value.filter(
    (v: any) => v.variableType === 'studentResponse'
  )
)
const hasStudentResponseVariables = computed(
  () => studentResponseVariablesInLatex.value.length > 0
)
const hasRandomVariables = computed(
  () =>
    usedVariablesInLatex.value.length > 0 && !hasStudentResponseVariables.value
)
const variablePopText = computed(() => {
  if (hasStudentResponseVariables.value) {
    const dependentQuestions = studentResponseVariablesInLatex.value
      .map((v: any) => `S${v.section}.Q${v.question}`)
      .join(', ')
    return `This value is derived from responses to the following questions: ${dependentQuestions}`
  } else if (hasRandomVariables.value) {
    return 'This value is random for each student.'
  }
  return ''
})
</script>

<style lang="scss" scoped>
.drag-drop-area {
  touch-action: none;
}

:deep(.katex-ref) {
  pointer-events: auto;
  &.drop-target {
    border: 1px dashed #3d2c60;
    border-radius: 4px;
  }

  &.drop-target--dropdown {
    border: 1px solid;
    padding-right: 15px;
    &:after {
      content: url('data:image/svg+xml; utf8, <svg class="svg-inline--fa fa-caret-down library-filter-dropdown__icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path class="" fill="currentColor" d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"></path></svg>');
      position: absolute;
      right: 1px;
      top: 0;
      width: 10px;
      height: 100%;
      line-height: 1.2;
    }
  }
  &.dropping {
    border: 1px solid #3d2c60;
    box-shadow: $focus-shadow;
  }
  &.filled {
    cursor: grab;
    border: 1px solid gray;
  }
  &.incorrect,
  &.invalid {
    border: 1px solid transparent;
    outline: 3px dashed $color-error;
    border-radius: 4px;
    background-color: rgb(255, 219, 181);
  }
  &.correct {
    border: 1px solid transparent;
    outline: 3px solid $teal;
    border-radius: 4px;
  }
}
.target-dropdown {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
