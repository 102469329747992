<template>
  <ul class="nav navbar-nav navbar-right pi-menu">
    <nav-dropdown title="Classes">
      <nav-dropdown-action :to="{ name: 'classes' }" @click="closeMenu">
        My Classes
      </nav-dropdown-action>
      <template v-if="classes?.length > 0">
        <li class="divider" role="separator" />
        <nav-dropdown-action
          v-for="klass in classes"
          :key="klass.id"
          :to="{ name: 'existing_class', params: { id: klass.id } }"
          @click="closeMenu"
        >
          {{ klass.name }}
        </nav-dropdown-action>
      </template>
    </nav-dropdown>

    <nav-dropdown title="Activities" v-if="!isGrader">
      <nav-dropdown-action
        :to="{ name: 'activities', query: { library: 'pivot' } }"
        @click="closeMenu"
      >
        Pivot Library
      </nav-dropdown-action>
      <template v-if="libraries?.length > 0">
        <li class="divider" role="separator" />
        <nav-dropdown-action
          v-for="library in libraries"
          :key="library.id"
          :to="{ name: 'activities', query: { library: library.id } }"
          @click="closeMenu"
          >{{ library.name }}
        </nav-dropdown-action>
      </template>
    </nav-dropdown>

    <nav-dropdown title="Account">
      <nav-dropdown-action :to="{ name: 'lti_platforms' }" @click="closeMenu">
        LTI
      </nav-dropdown-action>
      <template v-if="isSiteLicenseAdmin">
        <nav-dropdown-action
          :to="{ name: 'license', params: { tab: 'overview' } }"
          @click="closeMenu"
        >
          License Overview
        </nav-dropdown-action>
        <nav-dropdown-action
          :to="{ name: 'license', params: { tab: 'onboarding' } }"
          @click="closeMenu"
        >
          Onboarding
        </nav-dropdown-action>
        <nav-dropdown-action
          :to="{ name: 'license', params: { tab: 'usage' } }"
          @click="closeMenu"
        >
          Usage
        </nav-dropdown-action>
        <nav-dropdown-action
          :to="{ name: 'license', params: { tab: 'manage' } }"
          @click="closeMenu"
        >
          Manage License
        </nav-dropdown-action>
      </template>
    </nav-dropdown>
    <nav-dropdown title="Learn More">
      <nav-dropdown-action :to="{ name: 'blog' }" @click="closeMenu">
        Blog
      </nav-dropdown-action>
      <nav-dropdown-action :to="{ name: 'YouTube' }" @click="closeMenu">
        YouTube
      </nav-dropdown-action>
      <nav-dropdown-action
        :to="{ name: 'instructor_quick_start' }"
        @click="closeMenu"
      >
        Help Center
      </nav-dropdown-action>
    </nav-dropdown>

    <nav-dropdown :title="fullName || 'Welcome back!'">
      <template v-if="isTrialTeacher">
        <li class="bg-warning navbar-text pi-trial-warning">
          You have {{ days }} left in your trial.
        </li>
        <li>
          <dropdown-action>
            <button-link
              class="center-block"
              style="text-align: center"
              :to="{
                name: hasSiteLicense ? 'new_upgrade_session' : 'pricing'
              }"
              @click="closeMenu"
            >
              Upgrade Now
            </button-link>
          </dropdown-action>
        </li>
        <li class="divider" role="separator" />
      </template>
      <template v-if="hasSiteLicense">
        <nav-dropdown-action
          class="license-type-link"
          :to="{ name: 'edit_profile', hash: '#license' }"
          @click="closeMenu"
        >
          {{ licenseTypeText }}
          <template v-if="vetStatus">
            <icon
              v-if="vetStatus.icon"
              class="vet-status-icon"
              :icon="vetStatus.icon"
            />
            <span class="sr-only">{{ vetStatus.value }}</span>
          </template>
        </nav-dropdown-action>
        <nav-dropdown-action
          v-if="isSiteLicenseAdmin"
          :to="{ name: 'license', params: { tab: 'manage' } }"
          @click="closeMenu"
        >
          Manage License
        </nav-dropdown-action>
        <li role="separator" class="divider" />
      </template>
      <nav-dropdown-action :to="{ name: 'edit_profile' }" @click="closeMenu">
        Profile
      </nav-dropdown-action>
      <nav-dropdown-action
        id="logout"
        :to="{ name: 'logout' }"
        @click="closeMenu"
      >
        Log out
      </nav-dropdown-action>
    </nav-dropdown>
  </ul>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import NavDropdown from 'src/shared/components/navigation/NavDropdown'
import NavDropdownAction from 'src/shared/components/navigation/NavDropdownAction'
import client from 'src/shared/api-client.ts'

export default {
  name: 'TeacherMenu',
  components: {
    NavDropdown,
    NavDropdownAction
  },
  inject: ['closeMenu'],
  data() {
    return {
      libraries: [],
      classes: []
    }
  },
  computed: {
    ...mapState({
      license: state => state.auth.user.license,
      siteLicense: state => state.auth.user.siteLicense
    }),
    ...mapGetters([
      'fullName',
      'statusExpirationDays',
      'isTrialTeacher',
      'vetStatus',
      'isGrader',
      'hasSiteLicense'
    ]),
    days() {
      return this.statusExpirationDays === 1
        ? `${this.statusExpirationDays} day`
        : `${this.statusExpirationDays} days`
    },
    isSiteLicenseAdmin() {
      return this.siteLicense && this.siteLicense.role === 'admin'
    },
    licenseTypeText() {
      return (
        (this.siteLicense &&
          (this.siteLicense.type !== 'trial'
            ? `${this.siteLicense.type} Paid`
            : `${this.siteLicense.type} License`)) ||
        `Individual License`
      )
    }
  },
  methods: {
    async loadLibraries() {
      if (!this.isGrader) {
        this.libraries = await client.libraries.list()

        this.libraries = this.libraries.filter(
          library => library.status !== 'archived'
        )
      }
    },
    async loadClasses() {
      const body = await client.classes.search({ limit: 1000 })
      this.classes = body.classes
      this.classes = this.classes.filter(klass => klass.status !== 'archived')
    }
  },
  async mounted() {
    await Promise.all([this.loadLibraries(), this.loadClasses()])
  }
}
</script>

<style lang="scss" scoped>
.license-type-link {
  text-transform: capitalize;
}

.vet-status-icon {
  margin-left: 1.2rem;
}

.pi-trial-warning {
  width: 100%;
  margin: 0 0 15px;
  padding: 15px;
  background-color: rgba(240, 223, 61, 0.4);
}
</style>
