<template>
  <ViewContainer>
    <LoadingContainer v-if="!ltiSession || !classes" loading />
    <template v-else>
      <StickyHeader>
        <template #primary-navigation>
          <Breadcrumb>
            <BreadcrumbItem>
              {{ ltiSession?.context.platform.name }}
            </BreadcrumbItem>
            <BreadcrumbItem v-if="ltiSession.context.context.name">
              {{ ltiSession.context.context.name }}
            </BreadcrumbItem>
          </Breadcrumb>
        </template>
        <template #title>
          <StickyHeaderTitle>Connect Class</StickyHeaderTitle>
        </template>
      </StickyHeader>
      <ConnectContextForm
        v-if="connectMode === 'context'"
        :classes="classes"
        :className="ltiSession.context.context.name"
        :platformName="ltiSession.context.platform.name"
        @refreshClassList="refreshList"
      />
      <ConnectSectionsForm
        v-else-if="connectMode === 'sections'"
        :classes="classes"
        :sections="ltiSession.context.sections"
        :platformName="ltiSession.context.platform.name"
        @refreshClassList="refreshList"
      />
      <ClassConnectionTypeSelector
        v-else
        :context-name="ltiSession.context.context.name"
        :section-count="ltiSession.context.sections.length"
        @select="mode => (connectMode = mode)"
      />
    </template>
  </ViewContainer>
</template>

<script setup lang="ts">
import { onBeforeMount, ref, computed } from 'vue'

import { Class, GetLtiSessionResponse } from '@pi/api-types'

import client from 'src/shared/api-client'
import { useRouter } from 'vue-router'
import ClassConnectionTypeSelector from '../components/ClassConnectionTypeSelector.vue'
import ConnectContextForm from '../components/ConnectContextForm.vue'
import ConnectSectionsForm from '../components/ConnectSectionsForm.vue'

const router = useRouter()

const connectMode = ref<'context' | 'sections'>()

const classes = ref<Class[]>()
const ltiSession = ref<GetLtiSessionResponse>()
async function load() {
  const [ltiSessionRequest, classesRequest] = await Promise.all([
    client.lti.getSession(),
    client.classes.search({ limit: 1000 })
  ])

  classes.value = classesRequest.classes
  ltiSession.value = ltiSessionRequest
}

async function refreshList() {
  await load()
}
onBeforeMount(async () => {
  await load()

  if (ltiSession.value) {
    if (ltiSession.value.state !== 'CONNECT_CLASS') {
      await router.push({ name: 'lti' })
    }

    if (ltiSession.value.context.sections.length === 0) {
      connectMode.value = 'context'
    }
  }
})
</script>
