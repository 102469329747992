import { create, all } from 'mathjs'

const math = create(all)

math.config({
  number: 'BigNumber',
  precision: 64
})

const replacements = {}

// configure the default angles to degrees
const config = {
  angles: 'deg'
}

// convert the input angle from radians to degrees for trig functions
const fns1 = ['sin', 'cos', 'tan']
fns1.forEach(function (name) {
  const fn = math[name]

  const fnNumber = function (x) {
    if (config.angles === 'deg') {
      return fn(math.multiply(math.divide(x, 180), math.pi))
    }
  }

  // create a typed-function which checks the input types
  replacements[name] = math.typed(name, {
    BigNumber: fnNumber,
    number: fnNumber,
    'Array | Matrix': function (x) {
      return math.map(x, fnNumber)
    }
  })
})

// 'log' is used to evaluate the natural log (ln) in javascript/math.js - this evaluates 'ln' properly
replacements.ln = math.log

// 'log10' is used to evaluate log in javascript/math.js - this evaluates 'log' properly
replacements.log = math.log10

// import all replacements into math.js, override existing trig functions
math.import(replacements, { override: true })

// usound macro for PASCO
function usound(time, speed) {
  const pingEchoTime = time / 1000000 // us to s
  return (pingEchoTime * speed) / 2
}
math.import({ usound })

export default math
