<template>
  <embedded-iframe
    v-if="isIFrameComponent"
    class="activity-frame hidden-print"
    :src="parsedIFrameUrl"
    :config="component.config"
  />
</template>

<script>
import { substituteVariablesResponses } from 'src/shared/utils/activity-variables'
export default {
  name: 'ActivityFrame',
  inject: ['inherited'],
  props: {
    variableContext: {
      type: Object,
      required: true
    }
  },
  computed: {
    component() {
      return this.inherited.component
    },
    isIFrameComponent() {
      return this.component.componentType === 'IFrame'
    },
    parsedIFrameUrl() {
      const expression = this.component.url || ''
      const str = substituteVariablesResponses(
        expression,
        this.variableContext.variables,
        true
      )
      return str
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-frame {
  margin: 16px 0;
}
</style>
