<template>
  <loading-container :loading="isLoading">
    <async-form style="margin-top: 0" @submit="submit" persist>
      <activity
        v-slot="{ variableContext }"
        :activity="activity"
        :response="response"
      >
        <sticky-header>
          <template #primary-navigation>
            <breadcrumb v-if="klass">
              <breadcrumb-item
                :to="{
                  name: 'classes'
                }"
                >My Classes
              </breadcrumb-item>
              <breadcrumb-item
                :to="{
                  name: 'existing_class',
                  params: { id: klass.classId }
                }"
                >{{ klass.name }}
              </breadcrumb-item>
              <breadcrumb-item
                :to="{
                  name: 'assignment_scores',
                  params: { assignmentId: assignmentId },
                  query: $route.query
                }"
                >Scores View
              </breadcrumb-item>
            </breadcrumb>
          </template>
          <template #secondary-navigation>
            <router-link
              v-if="cursorLinks?.prev"
              class="previous-button"
              :to="{
                name: 'grade_response',
                params: {
                  assignmentId,
                  responseId: cursorLinks.prev
                },
                query: $route.query
              }"
            >
              <icon icon="arrow-left" />
              Previous
            </router-link>
            <button-dropdown
              unstyled
              center
              class="directory_dropdown"
              menu-class="grade-response-view__student-menu"
            >
              <template #button="{ isOpen }">
                <div>
                  <span>
                    {{
                      isGroupAssignment
                        ? response.groupName
                        : response.owner.lastName +
                          ', ' +
                          response.owner.firstName
                    }}
                  </span>
                  <icon
                    class="dropdown-icon"
                    :icon="isOpen ? 'caret-up' : 'caret-down'"
                  />
                </div>
              </template>
              <dropdown-link
                :to="{
                  name: 'grade_response',
                  params: {
                    assignmentId,
                    responseId: entry.id
                  },
                  query: $route.query
                }"
                v-for="entry in otherResponses"
                :key="entry.id"
              >
                <template v-if="isGroupAssignment">
                  {{ entry.groupName }}
                </template>
                <template v-else>
                  {{ entry.owner.lastName }}, {{ entry.owner.firstName }}
                </template>
              </dropdown-link></button-dropdown
            >

            <router-link
              v-if="cursorLinks?.next"
              class="next-button"
              :to="{
                name: 'grade_response',
                params: {
                  assignmentId,
                  responseId: cursorLinks.next
                },
                query: $route.query
              }"
            >
              Next
              <icon icon="arrow-right" />
            </router-link>
          </template>
          <template #page-action>
            <toggle
              link
              :toggle="showAnswer"
              class="toggle-answer-key"
              @toggle="toggleAnswerKey"
            >
              <template #on>Hide Answer Key</template>
              <template #off>View Answer Key</template>
            </toggle>

            <toggle link :toggle="show" @toggle="onToggleClick">
              <template #on>Hide Full Notes</template>
              <template #off>View Full Notes</template>
            </toggle>
          </template>
          <template #title>
            <sticky-header-title>
              {{ activity.name }}
            </sticky-header-title>
          </template>
          <template #sub-title="{ isStuck }">
            <sticky-header-sub-title>
              <template v-if="!isGroupAssignment"
                >Individual Assignment</template
              >
              <activity-group-name v-if="isGroupAssignment" />
              <activity-grading-progress />
              <activity-score
                :is-stuck="isStuck"
                grading
                @change="handleScoringChange"
              />
            </sticky-header-sub-title>
          </template>
          <template #actions>
            <button-dropdown right>
              <template #button>
                <icon icon="ellipsis-v" />
                <span class="sr-only">Actions</span>
              </template>
              <dropdown-action @click="unlockAllSections">
                Allow Work on Previously Locked Sections
              </dropdown-action>
              <dropdown-action @click="resetResponse">
                Reset Student Response
              </dropdown-action>
              <dropdown-action
                class="hidden-lg hidden-md"
                @click="toggleAnswerKey"
              >
                <span v-if="showAnswer">Hide Answer Key</span>
                <span v-else>View Answer Key</span>
              </dropdown-action>
              <!--
              <dropdown-action @click="regradeAutoGradedQuestion">
                Regrade Autograded Questions
              </dropdown-action>
              -->
              <dropdown-action @click="grantExtension">
                Grant Extension
              </dropdown-action>
            </button-dropdown>

            <select-field
              v-if="canChangeStatus"
              :modelValue="response.gradingProgress"
              aria-label="Assignment Status"
              @update:modelValue="updateResponseStatus"
            >
              <option value="pending">Pending Grade</option>
              <option value="graded">Final Grade</option>
              <option value="feedback">Request Revision</option>
            </select-field>

            <submit-button>
              <template #default>Save Progress</template>
              <template #submitting>Saving</template>
              <template #submitted>Saved</template>
            </submit-button>
          </template>
        </sticky-header>
        <activity-objectives :variable-context="variableContext" />
        <activity-notes :show="show" :variable-context="variableContext" />
        <activity-sections v-slot="{ section }">
          <activity-section-with-score
            :section="section"
            override
            open-by-default
            :variable-context="variableContext"
          >
            <template #dirtyResponseFlag>
              <span
                v-if="isResponseDirty"
                class="pi-action-icon bg-warning pull-right"
              >
                <icon icon="times" class="text-warning" />
                <strong>Unsaved Changes</strong>
              </span>
              <span
                v-else-if="isResponseSaved"
                class="pi-action-icon bg-success pull-right"
              >
                <icon icon="check" class="text-success" />
                <strong>Saved</strong>
              </span>
            </template>
            <activity-section-components v-slot="{ component }">
              <activity-split-view-component
                v-if="component.componentType === 'SplitView'"
                :ref="el => (componentRefs[component._id] = el)"
                :component="component"
                :componentRefs="componentRefs"
                :variable-context="variableContext"
                overrideHiding
                @change="updateResponse"
                @updateComponentRef="val => (componentRefs = val)"
                :viewAsStudent="false"
                :answersShown="showAnswer"
                grading
              />
              <activity-section-component
                v-if="component.componentType !== 'SplitView'"
                :component="component"
                :ref="el => (componentRefs[component._id] = el)"
                :variable-context="variableContext"
                overrideHiding
                @change="updateResponse"
              >
                <activity-section-component-renderer
                  :component="component"
                  :variable-context="variableContext"
                  :answersShown="showAnswer"
                  :viewAsStudent="false"
                  :canSubmit="false"
                  grading
                />
              </activity-section-component>
            </activity-section-components>
            <activity-section-lock-override
              @lock="lockSection"
              @unlock="unlockSection"
            />
          </activity-section-with-score>
        </activity-sections>
      </activity>
    </async-form>
  </loading-container>
</template>

<script>
import { ref } from 'vue'
import Activity from 'src/modules/activities/components/Activity'
import ActivityObjectives from 'src/modules/activities/components/ActivityObjectives'
import ActivityNotes from 'src/modules/activities/components/ActivityNotes'
import ActivitySections from 'src/modules/activities/components/ActivitySections'
import ActivitySectionWithScore from 'src/modules/activities/components/ActivitySectionWithScore'
import ActivitySectionComponents from 'src/modules/activities/components/ActivitySectionComponents'
import ActivitySectionComponent from 'src/modules/activities/components/ActivitySectionComponent'
import ActivitySectionLockOverride from 'src/modules/activities/components/ActivitySectionLockOverride'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import extensionModal from 'src/modules/assignments/components/ExtensionModal.vue'
import { notConcurrent } from 'src/setup/async'
import ActivityStudentName from 'src/modules/activities/components/ActivityStudentName.vue'
import ActivityScore from 'src/modules/activities/components/ActivityScore.vue'
import ActivityGroupName from 'src/modules/activities/components/ActivityGroupName'
import UnsavedChangesModal from 'src/shared/components/modals/UnsavedChangesModal'
import ActivitySectionComponentRenderer from '../components/ActivitySectionComponentRenderer.vue'
import ActivitySplitViewComponent from 'src/modules/activities/components/ActivitySplitViewComponent.vue'
import ActivityGradingProgress from 'src/modules/activities/components/ActivityGradingProgress.vue'
import UnlockSectionsConfirmationModal from 'src/shared/components/modals/UnlockSectionsConfirmationModal'
import client from 'src/shared/api-client'

export default {
  name: 'GradeByStudentView',
  components: {
    Activity,
    ActivityObjectives,
    ActivityNotes,
    ActivitySections,
    ActivitySectionComponents,
    ActivitySectionComponent,
    ActivitySectionLockOverride,
    ActivitySectionWithScore,
    ActivityGradingProgress,
    ActivityGroupName,
    ActivityScore,
    ActivitySplitViewComponent,
    ActivitySectionComponentRenderer
  },
  inject: ['$modal'],
  props: {
    assignmentId: {
      type: String,
      required: true
    },
    responseId: {
      type: String,
      required: true
    },
    componentId: {
      type: String,
      required: false
    },
    sort: {
      type: Object,
      default: null
    }
  },
  setup() {
    const popoverAnchorRef = ref()
    const componentRefs = {}
    return { componentRefs, popoverAnchorRef }
  },
  data() {
    return {
      activity: null,
      assignment: null,
      response: null,
      isLoadingAssignment: true,
      isLoadingResponse: true,
      afterSave: null,
      newProgress: null,
      fromRoute: null,
      show: true,
      isResponseDirty: false,
      isResponseSaved: false,
      showAnswer: false,
      klass: null,
      directory: null
    }
  },

  computed: {
    canChangeStatus() {
      return (
        this.response &&
        (this.response.gradingProgress === 'graded' ||
          this.response.gradingProgress === 'feedback' ||
          this.response.gradingProgress === 'pending')
      )
    },
    isGroupAssignment() {
      return (
        this.response &&
        this.response.assignment &&
        this.response.assignment.assignedTo === 'groups'
      )
    },
    isLoading() {
      return this.isLoadingAssignment || this.isLoadingResponse
    },
    otherResponses() {
      return this.directory.filter(entry => entry.id !== this.id)
    }
  },

  methods: {
    toggleAnswerKey() {
      this.showAnswer = !this.showAnswer
    },

    showComments(component) {
      return component.componentType.includes('Question')
    },
    beforeUnload(e) {
      const text = 'You have unsaved changes.'
      if (this.isResponseDirty) {
        e.returnValue = text
        return text
      }
    },

    onToggleClick(value) {
      this.show = value
    },

    async resetAutogradedQuestionSubmissions() {
      await client.assignments.resetSubmissionsBulk({
        assignmentId: this.assignment.id,
        assignmentResponses: [this.response.id]
      })
      this.loadResponse()
      this.$success(`Response submissions reset to zero.`)
    },

    async loadResponse(reloading = false) {
      if (!reloading) this.isLoadingResponse = true
      const response = await client.assignments.getResponseById({
        assignmentId: this.assignmentId,
        responseId: this.responseId,
        sort: this.sort
      })
      this.response = response
      if (!reloading) this.isLoadingResponse = false
    },
    async loadAssignment() {
      this.isLoadingAssignment = true
      const [activity, assignment, allResponses] = await Promise.all([
        client.assignments.getActivity({
          assignmentId: this.assignmentId
        }),
        client.assignments.get({ assignmentId: this.assignmentId }),
        client.assignments.getAllResponses({
          assignmentId: this.assignmentId
        })
      ])
      this.assignment = assignment
      this.activity = activity
      this.directory = allResponses.page.sort((a, b) => {
        if (
          this.isGroupAssignment ||
          (Object.prototype.hasOwnProperty.call(a, 'groupName') &&
            Object.prototype.hasOwnProperty.call(b, 'groupName'))
        ) {
          return a.groupName.localeCompare(b.groupName)
        } else {
          const aName = a.owner.lastName + ',' + a.owner.firstName
          const bName = b.owner.lastName + ',' + b.owner.firstName
          return aName.localeCompare(bName)
        }
      })

      const body = await client.classes.get({ classId: assignment.class })

      const className =
        body.name.length < 15
          ? body.name
          : body.name.substring(0, 4) +
            '\u2026' +
            body.name.substring(body.name.length - 8)
      this.klass = {
        classId: body.id,
        name: className,
        fullClassName: body.name === className ? null : body.name
      }
      this.isLoadingAssignment = false
    },
    save: notConcurrent(async function () {
      await client.assignments.gradeResponse({
        assignmentId: this.assignmentId,
        id: this.responseId,
        gradingProgress: this.response.gradingProgress,
        lockedSections: this.response.lockedSections,
        scoringType: this.response.scoringType,
        customScore: this.response.customScore,
        responses: this.response.responses.map(
          ({ score, comments, ...response }) => ({
            ...response,
            // Filter out null for score and comments.
            ...(score === null ? {} : { score: score }),
            ...(comments === null ? {} : { comments: comments })
          })
        )
      })
      this.isResponseDirty = false
      this.isResponseSaved = true

      await this.loadResponse(true)
    }),

    async reset() {
      await client.assignments.resetResponse({
        assignmentId: this.assignmentId,
        responseId: this.responseId
      })
      await this.loadResponse()
      this.$success('Response saved successfully.')
    },

    async submit(e) {
      try {
        await this.save()
        this.$success('Response saved successfully.')
        if (e.action === 'saveAndPrevious') {
          this.$router.push({
            name: 'grade_response',
            params: {
              assignmentId: this.assignmentId,
              responseId: this.previousResponse.id
            }
          })
        } else if (e.action === 'saveAndNext') {
          this.$router.push({
            name: 'grade_response',
            params: {
              assignmentId: this.assignmentId,
              responseId: this.previousResponse.id
            }
          })
        }
        e.done()
      } catch (error) {
        e.done(false)
        throw error
      }
    },

    updateResponse({ response, isDirty } = {}) {
      this.isResponseDirty = this.isResponseDirty || isDirty
      const index = this.response.responses.findIndex(
        r => r.component === response.component
      )
      if (index >= 0) {
        this.response.responses.splice(index, 1, response)
      } else {
        this.response.responses.push(response)
      }
    },

    async lockSection(index) {
      if (this.activity.sections[index].completeFirst) {
        this.response.lockedSections.push(index)
        await this.save()
      }
    },

    async unlockSection(index) {
      if (this.activity.sections[index].completeFirst) {
        this.response.lockedSections = this.response.lockedSections.filter(
          i => i !== index
        )
        await this.save()
      }
    },

    async updateResponseStatus(newProgress) {
      this.newProgress = newProgress
      this.response.gradingProgress = newProgress

      this.isResponseDirty = true
      if (newProgress === 'feedback' && this.response.lockedSections.length) {
        const { status } = await this.$modal.show(
          UnlockSectionsConfirmationModal,
          {
            message:
              'Do you want to allow student to work on previously locked sections?'
          }
        )
        if (status === 'yes') {
          this.response.lockedSections = []
        } else if (status === 'cancel') {
          return false
        }
      }
    },

    async resetResponse() {
      // show confirmation modal
      const { status } = await this.$modal.show(ConfirmModal, {
        text: `Caution: This will permanently delete all responses for this assignment for this student. There is no 'undo' for this action.`,
        prompt:
          "Are you sure you want delete this student's responses to this assignment?"
      })

      if (status === 'ok') {
        await this.reset()
      }
    },

    async unlockAllSections() {
      // show confirmation modal
      const { status } = await this.$modal.show(ConfirmModal, {
        text: `You are about to unlock all sections of this activity. The student will need to resubmit all sections.`,
        prompt: 'Are you sure you want to unlock all sections?'
      })

      if (status === 'ok') {
        this.response.lockedSections = []
        await this.save()
        this.$success('Response saved successfully.')
      }
    },
    async regradeAutoGradedQuestion() {
      // show confirmation modal
      const { status } = await this.$modal.show(ConfirmModal, {
        text: `You are about to regrade all auto-graded questions. This will overwrite any existing autograded scores.`,
        prompt: 'Are you sure you want to regrade all auto-graded questions?'
      })

      if (status === 'ok') {
        await client.assignments.regrade({
          assignmentId: this.assignmentId,
          studentList: [this.responseId]
        })
        await this.loadResponse()
        this.$success('Response regraded successfully.')
      }
    },
    async grantExtension() {
      const student = [
        {
          id: this.response.id,
          studentId: this.isGroupAssignment
            ? undefined
            : this.response.owner.id,
          name: this.isGroupAssignment
            ? this.response.groupName
            : `${this.response.owner.lastName}, ${this.response.owner.firstName}`
        }
      ]
      await this.$modal.show(extensionModal, {
        assignment: this.assignment,
        responses: student,
        selectedStudents: [this.response.id],
        isGroupAssignment: this.isGroupAssignment
      })
    },

    handleScoringChange({ scoringType, customScore }) {
      if (scoringType) {
        this.response.scoringType = scoringType
      }

      if (customScore === null) {
        customScore = 0
      }

      if (this.response.scoringType === 'custom') {
        this.response.customScore = customScore
        this.isResponseDirty = true
      }
    }
  },

  watch: {
    responseId: {
      async handler() {
        await this.loadResponse()
        this.cursorLinks = {
          next: this.response.next,
          prev: this.response.prev
        }
      },
      immediate: true
    },
    assignmentId: {
      handler: 'loadAssignment'
    },
    componentId: {
      async handler() {
        await this.loadResponse()
      }
    }
  },

  async created() {
    await this.loadAssignment()

    window.addEventListener('beforeunload', this.beforeUnload)

    let hashParams = window.location.hash
    hashParams = hashParams.replace('#component-', '')
    if (hashParams) {
      const el = this.componentRefs[hashParams]
      if (el) {
        el.$el.scrollIntoView()
      }
    }
    if (this.responseId) {
      const el = this.componentRefs[this.componentId]
      if (el) {
        el.$el.scrollIntoView()
      }
    }
  },

  async beforeRouteUpdate(to, from, next) {
    if (this.isResponseDirty) {
      const { status } = await this.$modal.show(UnsavedChangesModal)
      if (status === 'ok') {
        this.isResponseDirty = false
      } else {
        next(false)
      }
    }
    next()
  },

  async beforeRouteLeave(to, from, next) {
    if (this.isResponseDirty) {
      const { status } = await this.$modal.show(UnsavedChangesModal)
      if (status === 'ok') {
        window.removeEventListener('beforeunload', this.beforeUnload)
        next()
      } else {
        next(false)
      }
    } else {
      window.removeEventListener('beforeunload', this.beforeUnload)
      next()
    }
  }
}
</script>

<style lang="scss" scoped>
h4.display-status {
  font-style: italic;
}

div.bottom-score-name {
  display: flex;
  justify-content: space-between;
}

.button-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.previous-button {
  margin-right: auto;
}

.next-button {
  margin-left: 10px;
}

.pi-action-icon {
  padding: 4px 8px;
  margin: 16px;
  border-radius: 4px;
  svg {
    padding-right: 6px;
  }
}
.dropdown-icon {
  margin-left: 8px;
}

.toggle-answer-key {
  margin-right: 10px;
}
:deep(.directory_dropdown) {
  margin-left: 10px;
}

:deep(.grade-response-view__student-menu) {
  max-height: 400px;
  overflow-y: auto;
}
</style>
