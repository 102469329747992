<template>
  <div vref="root" class="button-dropdown__button">
    <ul
      v-if="menuOpen"
      class="button-dropdown__menu"
      :aria-labelledby="target"
      role="undefined"
      @focusout="onMenuBlur"
    >
      <dropdown-action
        v-if="hasChoice"
        @click="(e: MouseEvent) => setChoice(e)"
        :class="{
          'drop-choice__focused-target': focusedTarget === 0
        }"
        >Clear</dropdown-action
      >
      <dropdown-action
        v-for="(choice, index) in availableChoices"
        :value="choice"
        :key="choice.id"
        :disabled="choice.remaining <= 0"
        :class="{
          'drop-choice__focused-target': focusedTarget === index + 1
        }"
        @click="(e: MouseEvent) => setChoice(e, choice)"
      >
        <component
          class="drop-choice-content"
          :is="
            type === 'latex' ? DragDropLatexContent : DragDropRichTextContent
          "
          :text="choice.text"
          :variableContext="variableContext"
          :view-as-student="viewAsStudent"
        />
      </dropdown-action>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, provide, inject } from 'vue'
import DragDropLatexContent from './DragDropLatexContent.vue'
import DragDropRichTextContent from './DragDropRichTextContent.vue'
import { scramble } from 'src/shared/utils/array-randomizer.js'

interface DropChoice {
  id: string
  text: string
  count?: number
  validTargets?: string[]
}
interface Response {
  [targetId: string]: string | undefined
}
interface Props {
  choices: DropChoice[]
  targetValues?: Response
  variableContext: any
  target: string
  buttonRef?: any
  type: 'latex' | 'rich-text'
  clickContainer: HTMLElement
  viewAsStudent: boolean
}
const inherited = inject('inherited') as any
const menuOpen = ref(false)
const props = defineProps<Props>()
const root = ref<HTMLElement>()
const component = computed(() => inherited.value.component)
provide(
  'buttonDropdown',
  computed(() => ({
    isOpen: menuOpen,
    close: closeMenu
  }))
)

const hasChoice = computed(() => !!props.targetValues?.[props.target])

const availableChoices = computed(() => {
  const usedChoices = props.targetValues
    ? Object.values(props.targetValues)
    : []

  const result = (props.choices ?? [])

    .filter(
      choice =>
        !choice.validTargets ||
        choice.validTargets.length === 0 ||
        choice.validTargets.includes(props.target)
    )
    .map(choice => ({
      ...choice,
      remaining: choice.count
        ? choice.count - usedChoices.filter(id => id === choice.id).length
        : Infinity
    }))

  const seed = `${inherited.value.response.id ?? Math.random()}${
    component.value._id
  }`
  return inherited.value.component.randomizedChoices
    ? scramble(seed, result)
    : result
})
const emits = defineEmits(['select'])

const setChoice = (e: MouseEvent | KeyboardEvent, choice?: DropChoice) => {
  e.preventDefault()
  closeMenu()
  emits('select', choice ? choice.id : null)
}

const onMenuBlur = (e: any) => {
  if (
    e.relatedTarget !== props.buttonRef &&
    !root.value?.contains(e.relatedTarget)
  ) {
    closeMenu()
  }
}

const closeMenu = () => {
  menuOpen.value = false
  window.removeEventListener('keydown', onKeydown)
}

const openMenu = () => {
  menuOpen.value = true
  focusedTarget.value = 0
}

const focusedTarget = ref()

const totalTargets = computed(() => availableChoices.value.length + 1)

const onKeydown = (e: Event) => {
  switch ((e as KeyboardEvent).key) {
    case 'Enter': {
      e.preventDefault()
      if (menuOpen.value) {
        const target = availableChoices.value[focusedTarget.value - 1]
        if (target) {
          if (target.remaining === 0) return
          setChoice(e as KeyboardEvent, target)
        } else {
          setChoice(e as KeyboardEvent, undefined)
        }
      } else {
        openMenu()
      }
      break
    }
    case 'Escape': {
      if (menuOpen.value) {
        e.preventDefault()
        closeMenu()
      }
      break
    }
    case 'ArrowDown': {
      if (menuOpen.value) {
        focusedTarget.value =
          typeof focusedTarget.value === 'number'
            ? (focusedTarget.value + 1) % totalTargets.value
            : 0
      } else {
        focusedTarget.value = 0
        openMenu()
      }
      break
    }
    case 'ArrowUp': {
      if (menuOpen.value) {
        focusedTarget.value =
          typeof focusedTarget.value === 'number'
            ? (totalTargets.value + focusedTarget.value - 1) %
              totalTargets.value
            : totalTargets.value - 1
      } else {
        focusedTarget.value = totalTargets.value - 1
        openMenu()
      }
      break
    }
    default: {
      return
    }
  }
}

const onContainerClick = (e: MouseEvent) => {
  if (e.target === props.buttonRef) {
    if (menuOpen.value) closeMenu()
    else openMenu()
    return
  }
  const elements = document.elementsFromPoint(e.clientX, e.clientY)
  if (elements && elements.find(e => e === props.buttonRef)) {
    if (menuOpen.value) closeMenu()
    else openMenu()
    return
  }
}

function onWindowClick(e: MouseEvent) {
  if (e.target instanceof HTMLElement) {
    const withinMenu =
      e.target instanceof HTMLElement &&
      (root.value?.contains(e.target) || root.value === e.target)
    if (!withinMenu) {
      closeMenu()
    }
  }
}

const onTargetFocusOut = (e: Event) => {
  e.target?.removeEventListener('keydown', onKeydown)
  e.target?.removeEventListener('focusout', onTargetFocusOut)
}

const onTargetFocus = (e: FocusEvent) => {
  if (e.target instanceof HTMLElement) {
    const { choice, ref, target } = e.target.dataset
    if (choice || ref || target) {
      e.target.addEventListener('keydown', onKeydown)
      e.target.addEventListener('focusout', onTargetFocusOut)
    }
  }
}

watch(menuOpen, (isOpen, _, onCleanup) => {
  if (!isOpen) return

  setTimeout(() => window.addEventListener('click', onWindowClick), 500)
  onCleanup(() => window.removeEventListener('click', onWindowClick))
})

watch(
  () => ({
    anchor: props.buttonRef
  }),
  ({ anchor }, _, onCleanup) => {
    if (!anchor) return

    anchor.addEventListener('focus', onTargetFocus)
    props.clickContainer.addEventListener('click', onContainerClick)

    onCleanup(() => {
      anchor.removeEventListener('focus', onTargetFocus)
      props.clickContainer.removeEventListener('click', onContainerClick)
    })
  },
  {
    flush: 'post'
  }
)
</script>

<style scoped lang="scss">
:deep(.drop-target__dropdown-button) {
  background: none;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
}

.drop-choice__focused-target {
  color: #262626;
  outline: none;
  background-color: #f5f5f5;
  text-decoration: underline;
}

.button-dropdown__button {
  width: 100%;
}
.button-dropdown__menu {
  position: relative;
  z-index: 1000;
  float: left;
  min-width: 160px;
  width: 100%;
  padding: 5px 0;
  margin: 0;
  text-align: left;
  list-style: none;
  font-size: 14px;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.split {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 2px solid;
}

.divider {
  height: 1px;
  margin: 8px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
</style>
