<template>
  <form-group class="my-8">
    <form-label id="attempts">Question Attempts</form-label>
    <div>
      <UISwitch
        name="Override Attempts"
        v-model="override"
        :disabled="overrideDisabled"
      >
        Override Attempts
      </UISwitch>
    </div>
    <div class="flex pt-6" v-if="override">
      <number-input
        id="overrideAttempts"
        class="w-20"
        v-model="overrideAttempts"
        :disabled="overrideDisabled"
        label="Total number of attempts for all questions"
      />
      <label for="overrideAttempts" class="font-normal pl-4 leading-[2.4]"
        >Total number of attempts for all questions</label
      >
    </div>
    <div class="w-2/3 mt-3" v-if="overrideDisabled">
      Unless the feedback is shown after submitting each question, the activity
      does not support custom attempt limits
    </div>
  </form-group>
</template>

<script setup lang="ts">
import { AssignmentsResponse } from '@pi/api-types'
import { computed, ref, watch } from 'vue'
import { UISwitch } from '@pi/design'
import { AssignmentStudentFeedbackTimingValue } from '@pi/types'

interface AssignmentAttemptProps {
  assignment?: AssignmentsResponse
}
const props = defineProps<AssignmentAttemptProps>()
const emit = defineEmits(['update'])

const attemptValue = ref(props.assignment?.attemptsOverride)

const override = ref(
  props.assignment ? !!props.assignment.attemptsOverride : false
)
const overrideAttempts = computed({
  get: () => props.assignment?.attemptsOverride,
  set: val => {
    emit('update', override.value ? val : undefined)
    attemptValue.value = val
  }
})

const overrideDisabled = computed(() => {
  return (
    props.assignment?.studentFeedbackTiming ===
    AssignmentStudentFeedbackTimingValue.AfterAssignmentSubmit
  )
})

const updated = () => {
  emit('update', override.value ? overrideAttempts.value : undefined)
}

watch(overrideDisabled, () => {
  if (overrideDisabled.value) {
    override.value = false
    emit('update', undefined)
  } else {
    if (attemptValue.value) {
      emit('update', attemptValue.value)
      override.value = true
    }
  }
})
</script>
