<template>
  <view-container>
    <view-header>
      <view-title>Manage Library</view-title>
      <view-header-actions>
        <form-button v-if="isArchived" @click="unArchive">
          Unarchive
        </form-button>
      </view-header-actions>
      <view-subtitle v-if="isArchived" class="text-danger">
        This library is archived and editing has been disabled
      </view-subtitle>
    </view-header>
    <loading-container :loading="isLoading">
      <async-form
        class="manage-library__detail-form form-two-column"
        @submit="saveLibrary"
        persist
      >
        <form-group>
          <form-label class="manage-library__label" for="name">Name</form-label>
          <text-input
            id="name"
            v-model="library.name"
            class="manage-library__name-input"
            label="name"
            :readonly="isArchived || !canManageLibrary"
            rules="required"
          />
        </form-group>
        <form-group>
          <form-label class="manage-library__label" for="description">
            Description
          </form-label>
          <multiline-text-input
            id="description"
            v-model="library.description"
            rows="5"
            :readonly="isArchived || !canManageLibrary"
            label="description"
          />
        </form-group>
        <form-group
          v-if="!isArchived && canManageLibrary"
          variant="right-aligned"
        >
          <form-button submit> Save </form-button>
        </form-group>
      </async-form>
      <section>
        <div class="manage-library__section-header">
          <h2 class="manage-library__section-title">Members</h2>
          <form-button
            v-if="!isArchived && canManageLibrary"
            @click="addMembers"
          >
            Add Members
          </form-button>
        </div>
        <list :items="library.members || []" aria-label="Library Members">
          <list-column v-slot="{ firstName, lastName }" title="Name">
            {{ firstName }} {{ lastName }}
          </list-column>
          <list-column v-slot="{ email }" title="Email">
            <a :href="`mailto:${email}`">{{ email }}</a>
          </list-column>
          <list-column v-slot="member" title="Permissions">
            <template
              v-if="
                isArchived ||
                !canManageLibrary ||
                member.permissions === 'owner'
              "
            >
              {{ member.permissions }}
            </template>
            <select-field
              v-else
              aria-label="Permissions"
              label="member permissions"
              :modelValue="member.permissions"
              @update:modelValue="
                value => updateMemberPermissions(member, value)
              "
            >
              <option v-if="isOwner" value="owner">owner</option>
              <option value="admin">admin</option>
              <option value="editor">editor</option>
              <option value="viewer">viewer</option>
            </select-field>
          </list-column>
          <list-column
            v-if="!isArchived && canManageLibrary"
            v-slot="member"
            title="Actions"
          >
            <form-button
              v-if="member.permissions !== 'owner'"
              link
              @click="removeMember(member)"
            >
              Remove
            </form-button>
          </list-column>
        </list>
      </section>
    </loading-container>
  </view-container>
</template>

<script>
import { mapState } from 'vuex'
import client from 'src/shared/api-client.ts'
import AddLibraryMembersModal from '../components/AddLibraryMembersModal'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'

export default {
  name: 'ManageLibraryView',
  inject: ['$modal'],
  props: {
    libraryId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      library: {}
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    currentMember() {
      return (
        (this.library.members || []).find(
          member => member.id === this.user.id
        ) || {
          permissions: 'none'
        }
      )
    },
    isOwner() {
      return this.currentMember.permissions === 'owner'
    },
    canManageLibrary() {
      return ['owner', 'admin'].includes(this.currentMember.permissions)
    },
    isArchived() {
      return this.library.status === 'archived'
    }
  },
  methods: {
    async loadLibrary() {
      return client.libraries.get(this.libraryId)
    },
    async reloadMembers() {
      const { members } = await this.loadLibrary()
      this.library.members = members
    },
    async saveLibrary(e) {
      try {
        await client.libraries.update({
          id: this.libraryId,
          name: this.library.name,
          description: this.library.description
        })
        this.$success('Library updated!')
        e.done()
      } catch (error) {
        e.done(false)
        throw error
      }
    },
    async addMembers() {
      const { status, data } = await this.$modal.show(AddLibraryMembersModal, {
        libraryId: this.libraryId
      })
      if (status === 'ok') {
        let failures = 0
        const numEmails = data.emails.length
        if (numEmails === 0) return
        for (const email of data.emails) {
          try {
            await client.libraries.addMember({
              id: this.libraryId,
              email,
              permissions: data.permissions
            })
          } catch (error) {
            failures++
            this.$error(`Failed to add member with email ${email}.`)
          }
        }
        this.$success(`${numEmails - failures} of ${numEmails} members added.`)
        await this.reloadMembers()
      }
    },
    async updateMemberPermissions(member, permissions) {
      try {
        await client.libraries.updateMember({
          libraryId: this.libraryId,
          memberId: member.id,
          permissions
        })
        this.$success(
          `Member with email ${member.email} was given ${permissions} permissions.`
        )
      } finally {
        await this.reloadMembers()
      }
    },
    async removeMember(member) {
      const memberName = member.firstName
        ? `${member.firstName} ${member.lastName}`
        : 'this member'
      const { status } = await this.$modal.show(ConfirmModal, {
        text: 'This member will lose access to all of the activities in this library.',
        prompt: `Are you sure you want to remove ${memberName}?`
      })

      if (status === 'ok') {
        await client.libraries.removeMember(this.libraryId, member.id)
        this.$success(`Member with email ${member.email} was removed.`)
        await this.reloadMembers()
      }
    },
    async unArchive() {
      await client.libraries.setIsArchived(this.libraryId, false)
      this.$success('Library was unarchived')
      this.library = await this.loadLibrary()
    }
  },
  watch: {
    libraryId: {
      async handler() {
        this.isLoading = true
        this.library = await this.loadLibrary()
        if (!this.canManageLibrary) {
          return this.$router.push({
            name: 'activities',
            query: {
              library: this.libraryId
            }
          })
        }
        this.isLoading = false
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.manage-library {
  &__detail-form {
    max-width: 600px;
  }

  &__name-input {
    max-width: 300px;
  }

  &__label {
    width: 82px;
  }

  &__section-header {
    display: flex;
    align-items: center;
  }

  &__section-title {
    flex-grow: 1;
  }
}
</style>
