import math from 'src/setup/math'

// Collection variables have nested variables,
// so we flatten this into a single array of all the options.
export function flattenVariables(variables) {
  return variables.flatMap(v => {
    if (v.variableType === 'collection') {
      return v.variables.map(v2 => ({
        id: `${v.id}.${v2.id}`,
        name: `${v.name}.${v2.name}`,
        variableType: v2.variableType,
        value: v2.value
      }))
    } else {
      return {
        id: v.id,
        name: v.name,
        variableType: v.variableType,
        value: v.value
      }
    }
  })
}

function replaceVariables({ expression, variables, match, replace }) {
  return (
    flattenVariables(variables)
      // We sort the variables in descending order according to length
      // so that longer variables get replaced first.
      // Otherwise when you have two variables $var and $var1,
      // $var1 contains all of $var in its name, and it will get replaced incorrectly.
      .sort((var1, var2) => var2.name.length - var1.name.length)
      .reduce((expr, variable) => {
        if (variable.id === variable.name) {
          return expr
        }
        const matchStr = match(variable)
        const replaceStr = replace(variable)
        while (expr.includes(matchStr)) {
          expr = expr.replace(matchStr, replaceStr)
        }
        return expr
      }, expression)
  )
}

const validateVariableExpression = (str, variables) => {
  if (!/\$([A-Za-z0-9_ℹ]+(?:\.[A-Za-z0-9_ℹ]+)?)/.test(str)) return true
  const hasVariables = flattenVariables(variables)
    .sort((var1, var2) => var2.name.length - var1.name.length)
    .some(variable => {
      const nameMatch = `$${variable.name}`
      const idMatch = `$${variable.id}`
      return str.includes(nameMatch) || str.includes(idMatch)
    })
  return hasVariables
}

const substituteVariables = (str, variables, replaceField) =>
  replaceVariables({
    expression: str,
    variables,
    match: variable =>
      replaceField === 'id' ? variable.id : `[$${variable.name}]`,
    replace: variable =>
      replaceField === 'id' ? `[$${variable.name}]` : variable.id
  })
const substituteVariablesExpression = (str, variables, replaceField) =>
  replaceVariables({
    expression: str,
    variables,
    match: variable =>
      replaceField === 'id' ? `$${variable.id}` : `$${variable.name}`,
    replace: variable =>
      replaceField === 'id' ? `$${variable.name}` : `$${variable.id}`
  })

const substituteVariablesMathjs = (str, variables, replaceField) => {
  if (!str?.trim()) return

  let expressionTree
  try {
    expressionTree = math.parse(str)
  } catch (error) {
    return str
  }

  const newField = replaceField === 'id' ? 'name' : 'id'
  return expressionTree
    .transform(node => {
      if (node.isSymbolNode) {
        const variable = variables.find(
          v => `$${v[replaceField]}` === node.name
        )
        if (variable) {
          return new math.SymbolNode(`$${variable[newField]}`)
        } else {
          return node
        }
      } else if (node.isAccessorNode) {
        const variable = variables.find(
          v => `$${v[replaceField]}` === node.object.name
        )
        if (variable) {
          const subVariable = variable.variables.find(
            v =>
              (replaceField === 'name' ? v.name : v.id).replace('$', '') ===
              node.index.dimensions[0].value.replace('$', '')
          )
          if (subVariable) {
            return math.parse(
              `$${variable[newField]}.${
                replaceField === 'id' ? subVariable.name : `$${subVariable.id}`
              }`
            )
          } else {
            return math.parse(
              `$${variable[newField]}.${node.index.dimensions[0].value}`
            )
          }
        } else {
          return node
        }
      } else {
        return node
      }
    })
    .toString()
}

const substituteVariablesResponses = (str, variables, clearBrackets = false) =>
  replaceVariables({
    expression: str,
    variables,
    match: variable => (clearBrackets ? `[$${variable.id}]` : variable.id),
    replace: variable =>
      typeof variable.value === 'string' &&
      variable.variableType === 'studentResponse'
        ? 'Student Response '
        : variable.value
  })

const variableMatches = (str, variables) => {
  const check = str.replace('[$', '').replace(']', '')
  const found = flattenVariables(variables).find(
    v => v.name.toLowerCase() === check.toLowerCase()
  )
  return found
}

const evaluateExpression = (text, variables = []) => {
  const VARIABLE_MATCH = /\$([A-Za-z0-9_ℹ]+)(?:\.[A-Za-z0-9_ℹ]+)?/g

  let match = VARIABLE_MATCH.test(text)
    ? substituteVariablesExpression(text, variables)
    : text
  if (!match) return text
  const expressionVariables = {
    response: 'response'
  }
  //only evaluate if the variables have values.
  const usedVariables = variables.filter(v => match.includes(v.id))

  const variablesWithoutValues = usedVariables.some(variable => {
    if (variable.variableType === 'studentResponse')
      return (
        variable.value === null ||
        variable.value === undefined ||
        variable.value === variable.content
      )
    return variable.value === null || variable.value === undefined
  })
  if (variablesWithoutValues) {
    return 'Student Response'
  }
  variables.forEach(variable => {
    switch (typeof variable.value) {
      case 'number': {
        expressionVariables[`$${variable.id}`] = math.bignumber(variable.value)
        break
      }
      case 'string': {
        expressionVariables[`$${variable.id}`] = variable.value
        break
      }
      case 'object': {
        expressionVariables[`$${variable.id}`] = Object.fromEntries(
          Object.entries(variable.value).map(([key, value]) => {
            // Older activities don't have the dollar sign in nested variables, so we have to add it.
            match = match.replaceAll(
              `$${variable.id}.${key}`,
              `$${variable.id}.$${key}`
            )

            return [
              `$${key}`,
              typeof value === 'number' ? math.bignumber(value) : value
            ]
          })
        )
        break
      }
    }
  })
  try {
    const result = math.evaluate(match ?? '', expressionVariables)
    if (typeof result === 'string' && !isNaN(result)) return parseFloat(result)
    return result
  } catch (_e) {
    return ''
  }
}

export {
  substituteVariables,
  substituteVariablesExpression,
  substituteVariablesMathjs,
  substituteVariablesResponses,
  variableMatches,
  evaluateExpression,
  validateVariableExpression
}
