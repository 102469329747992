import * as Y from 'yjs'
import { markRaw } from 'vue'
import math from 'src/setup/math'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import DataGridColumnSettingsModal from 'src/shared/components/modals/DataGridColumnSettingsModal'
import DataGridColumnFormulaModal from 'src/shared/components/modals/DataGridColumnFormulaModal'
import ConfigureBluetoothDevicesModal from 'src/shared/components/modals/ConfigureBluetoothDevicesModal'
import {
  newColumn,
  columnDefinitionToYMap,
  columnHasData,
  columnDisplayName,
  columnDisplayValue,
  rowHasData,
  clearColumnData,
  clearColumnWidths,
  cropRowsForGridData,
  lastDataRowForColumn,
  columnsAreUntouched
} from 'src/shared/components/grid-graph/utilities'
import * as bluetooth from 'src/shared/components/grid-graph/bluetooth'
import { DEFAULT_NUM_WIDTH, DEFAULT_TEXT_WIDTH } from './utilities'
import { evaluateExpression } from 'src/shared/utils/activity-variables'

const MAX_SAMPLES_PER_TRIAL = 1000

export default {
  name: 'DataGridActionsProvider',
  inject: ['$modal'],
  emits: ['update:focusedCell'],
  provide() {
    return {
      $bluetooth: this.bluetooth,
      $gridActions: {
        insertColumnBeforeColumn: this.insertColumnBeforeColumn,
        insertColumnAfterColumn: this.insertColumnAfterColumn,
        removeColumn: this.removeColumn,
        insertRowAboveRow: this.insertRowAboveRow,
        insertRowBelowRow: this.insertRowBelowRow,
        removeRow: this.removeRow,
        setValueAtColumnAndRow: this.setValueAtColumnAndRow,
        insertColumnCalculation: this.insertColumnCalculation,
        removeColumnCalculation: this.removeColumnCalculation,
        updateColumnCalculation: this.updateColumnCalculation,
        updateColumnSigFigs: this.updateColumnSigFigs,
        showColumnSettingsModalForColumn: this.showColumnSettingsModalForColumn,
        showColumnFormulaModalForColumn: this.showColumnFormulaModalForColumn,
        downloadDataAsCSV: this.downloadDataAsCSV,
        reset: this.reset,
        connectBluetoothDevice: this.connectBluetoothDevice,
        configureBluetoothDevices: this.configureBluetoothDevices,
        startCollectingBluetoothSamples: this.startCollectingBluetoothSamples,
        stopCollectingBluetoothSamples: this.stopCollectingBluetoothSamples,
        setColumnWidth: this.setColumnWidth,
        paste: this.paste
      }
    }
  },
  props: {
    grid: {
      type: Y.Map,
      required: true
    },
    focusedCell: {
      type: Object,
      default: null
    },
    variableContext: {
      type: Object,
      default: undefined,
      required: false
    }
  },
  data() {
    return {
      bluetooth: {
        connectedDevices: {},
        collectingSamples: false,
        samplePeriod:
          1000 / (this.grid.get('bluetooth').get('sampleRate') || 1),
        totalSamples: 0
      },
      bluetoothSampleCollectionInterval: null
    }
  },
  watch: {
    grid: {
      handler() {
        this.unsubscribe?.()
        const bluetooth = this.grid.get('bluetooth')
        const onChange = () => {
          this.bluetooth.samplePeriod =
            1000 / (bluetooth.get('sampleRate') || 1)
        }
        onChange()
        bluetooth.observe(onChange)
        this.unsubscribe = () => bluetooth.unobserve(onChange)
      },
      immediate: true
    },
    variableContext: {
      handler() {
        this.grid.doc.transact(() => {
          this.grid.get('columns').forEach((_, i) => {
            this.calculateColumnValuesFromFormula(i)
          })
        })
      },
      immediate: true,
      deep: true
    }
  },
  beforeUnmount() {
    this.unsubscribe?.()
  },
  methods: {
    insertColumnBeforeColumn(columnIndex, overrides = {}) {
      this.grid.doc.transact(() => {
        const columns = this.grid.get('columns')

        // Clamp columnIndex
        columnIndex = Math.min(Math.max(columnIndex, 0), columns.length - 1)
        columnIndex = Math.max(columnIndex, 0)

        for (let i = columnIndex; i < columns.length; i++) {
          const formula = columns.get(i).get('formula') ?? ''
          columns.get(i).set(
            'formula',
            formula.replace(/\(col(\d+)\)/g, (match, column) => {
              if (+column > columnIndex) {
                return `(col${+column + 1})`
              }

              return match
            })
          )
        }

        const column = new Y.Map()
        columnDefinitionToYMap(column, {
          ...newColumn(this.grid.get('rows')),
          ...overrides
        })
        columns.insert(columnIndex, [column])

        const graphs = this.grid.get('graphs')

        graphs.forEach(graph => {
          const hColumns = graph.get('hColumns')
          const vColumns = graph.get('vColumns')

          if (hColumns) {
            if (hColumns.get('data') >= columnIndex) {
              hColumns.set('data', hColumns.get('data') + 1)
            }

            if (hColumns.get('uncertainty') >= columnIndex) {
              hColumns.set('uncertainty', hColumns.get('uncertainty') + 1)
            }
          }

          if (vColumns) {
            vColumns.forEach(column => {
              if (column.get('data') >= columnIndex) {
                column.set('data', column.get('data') + 1)
              }

              if (column.get('uncertainty') >= columnIndex) {
                column.set('uncertainty', column.get('uncertainty') + 1)
              }
            })
          }
        })
      })
    },
    insertColumnAfterColumn(columnIndex, overrides = {}) {
      this.grid.doc.transact(() => {
        const columns = this.grid.get('columns')

        // Clamp columnIndex
        columnIndex = Math.min(Math.max(columnIndex, 0), columns.length - 1)
        columnIndex = Math.max(columnIndex, 0)

        for (let i = columnIndex; i < columns.length; i++) {
          const formula = columns.get(i).get('formula') ?? ''
          columns.get(i).set(
            'formula',
            formula.replace(/\(col(\d+)\)/g, (match, column) => {
              if (+column >= columnIndex) {
                return `(col${+column + 1})`
              }

              return match
            })
          )
        }

        const column = new Y.Map()
        columnDefinitionToYMap(column, {
          ...newColumn(this.grid.get('rows')),
          ...overrides
        })
        columns.insert(columnIndex + 1, [column])

        const graphs = this.grid.get('graphs')

        graphs.forEach(graph => {
          const hColumns = graph.get('hColumns')
          const vColumns = graph.get('vColumns')

          if (hColumns) {
            if (hColumns.get('data') > columnIndex) {
              hColumns.set('data', hColumns.get('data') + 1)
            }

            if (hColumns.get('uncertainty') > columnIndex) {
              hColumns.set('uncertainty', hColumns.get('uncertainty') + 1)
            }
          }

          if (vColumns) {
            vColumns.forEach(column => {
              if (column.get('data') > columnIndex) {
                column.set('data', column.get('data') + 1)
              }

              if (column.get('uncertainty') > columnIndex) {
                column.set('uncertainty', column.get('uncertainty') + 1)
              }
            })
          }
        })
      })
    },
    async removeColumn(columnIndex) {
      const referencedBy = this.columnsWithFormulaUsingColumn(columnIndex)
      let removeFormulas = false

      if (referencedBy.length > 0) {
        const { status } = await this.$modal.show(ConfirmModal, {
          text: `This column is referenced in the formula of ${
            referencedBy.length
          } of other ${
            referencedBy.length === 1 ? 'column' : 'columns'
          }. Removing this column will remove the formula for any other column that is referencing it. This cannot be undone.`,
          prompt: 'Are you sure you want to remove this column?'
        })

        removeFormulas = status === 'ok'
      } else if (columnHasData(this.grid.get('columns').get(columnIndex))) {
        const { status } = await this.$modal.show(ConfirmModal, {
          text: `This column has one or more cells which contain data. Removing this column will remove all the data in those cells. This cannot be undone.`,
          prompt: 'Are you sure you want to remove this column?'
        })

        if (status !== 'ok') {
          return
        }
      }

      this.grid.doc.transact(() => {
        if (removeFormulas) {
          for (const i of referencedBy) {
            this.grid.get('columns').get(i).set('formula', '')
          }
        }

        const columns = this.grid.get('columns')

        for (let i = columnIndex; i < columns.length; i++) {
          const column = columns.get(i)
          const formula = column.get('formula') ?? ''

          column.set(
            'formula',
            formula.replace(/\(col(\d+)\)/g, (match, column) => {
              if (+column > columnIndex) {
                return `(col${+column - 1})`
              }

              return match
            })
          )
        }

        columns.delete(columnIndex, 1)

        // Update graph data if any plots are referencing this removed column
        const graphs = this.grid.get('graphs')

        graphs.forEach(graph => {
          const hColumns = graph.get('hColumns')
          const vColumns = graph.get('vColumns')

          if (hColumns) {
            if (hColumns.get('data') === columnIndex) {
              hColumns.set('data', -1)
            }
            if (hColumns.get('uncertainty') === columnIndex) {
              hColumns.set('uncertainty', -1)
            }
            if (hColumns.get('data') > columnIndex) {
              hColumns.set('data', hColumns.get('data') - 1)
            }
            if (hColumns.get('uncertainty') > columnIndex) {
              hColumns.set('uncertainty', hColumns.get('uncertainty') - 1)
            }
          }

          if (vColumns) {
            vColumns.forEach(column => {
              if (column.get('data') === columnIndex) {
                column.set('data', -1)
              }
              if (column.get('uncertainty') === columnIndex) {
                column.set('uncertainty', -1)
              }

              if (column.get('data') > columnIndex) {
                column.set('data', column.get('data') - 1)
              }
              if (column.get('uncertainty') > columnIndex) {
                column.set('uncertainty', column.get('uncertainty') - 1)
              }
            })
          }
        })
      })
    },
    insertRowAboveRow(rowIndex) {
      this.grid.doc.transact(() => {
        this.grid.set('rows', this.grid.get('rows') + 1)

        this.grid.get('columns').forEach((column, i) => {
          column.get('data').insert(rowIndex, [''])

          this.calculateColumnValuesFromFormula(i)

          const graphs = this.grid.get('graphs')

          graphs.forEach(graph => {
            let ignoredRows = graph.get('curveFitIgnoredRows')
            graph.set(
              'curveFitIgnoredRows',
              ignoredRows.map(row => {
                return row >= rowIndex ? row + 1 : row
              })
            )
          })
        })
      })
    },
    insertRowBelowRow(rowIndex) {
      this.grid.doc.transact(() => {
        this.grid.set('rows', this.grid.get('rows') + 1)

        this.grid.get('columns').forEach((column, i) => {
          column.get('data').insert(rowIndex + 1, [''])

          this.calculateColumnValuesFromFormula(i)
        })

        const graphs = this.grid.get('graphs')
        graphs.forEach(graph => {
          let ignoredRows = graph.get('curveFitIgnoredRows')
          graph.set(
            'curveFitIgnoredRows',
            ignoredRows.map(row => {
              return row >= rowIndex + 1 ? row + 1 : row
            })
          )
        })
      })
    },
    async removeRow(rowIndex) {
      const columns = this.grid.get('columns')

      if (rowHasData(columns, rowIndex)) {
        const { status } = await this.$modal.show(ConfirmModal, {
          text: `This row has one or more cells which contain data. Removing this row will remove all the data in those cells. This cannot be undone.`,
          prompt: 'Are you sure you want to remove this row?'
        })

        if (status !== 'ok') {
          return
        }
      }

      this.grid.doc.transact(() => {
        this.grid.set('rows', this.grid.get('rows') - 1)

        columns.forEach(column => {
          column.get('data').delete(rowIndex, 1)
        })
      })
      const graphs = this.grid.get('graphs')

      graphs.forEach(graph => {
        let ignoredRows = graph.get('curveFitIgnoredRows')
        ignoredRows = ignoredRows.filter(row => row !== rowIndex)
        graph.set(
          'curveFitIgnoredRows',
          ignoredRows.map(row => {
            return row >= rowIndex ? row - 1 : row
          })
        )
      })
    },
    insertColumnCalculation(columnIndex, formula) {
      this.grid.doc.transact(() => {
        const column = this.grid.get('columns').get(columnIndex)
        const calculations = column.get('calculations')
        const calculation = new Y.Map()
        calculations.push([calculation])
        calculation.set('type', formula)
        calculation.set('precision', 3)
        calculation.set('format', 'sigfigs')
      })
    },
    removeColumnCalculation(columnIndex, calculationIndex) {
      this.grid.doc.transact(() => {
        const column = this.grid.get('columns').get(columnIndex)
        const calculations = column.get('calculations')
        calculations.delete(calculationIndex, 1)
      })
    },
    updateColumnCalculation(
      columnIndex,
      calculationIndex,
      calculationSettings
    ) {
      this.grid.doc.transact(() => {
        const column = this.grid.get('columns').get(columnIndex)
        const calculations = column.get('calculations')
        calculations
          .get(calculationIndex)
          .set('precision', calculationSettings.precision)
        calculations.get(calculationIndex).set('type', calculationSettings.type)
        calculations
          .get(calculationIndex)
          .set('format', calculationSettings.format)
      })
    },
    setValueAtColumnAndRow(columnIndex, rowIndex, value) {
      this.grid.doc.transact(() => {
        const column = this.grid.get('columns').get(columnIndex)
        const data = column.get('data')

        switch (rowIndex) {
          case 'name':
          case 'units':
          case 'variable':
            column.set(rowIndex, value)
            break
          default:
            try {
              data.delete(rowIndex, 1)
            } catch (e) {
              if (e.message !== 'Length exceeded!') {
                throw e
              }
            }
            if (column.get('allowText')) {
              data.insert(rowIndex, [value])
            } else if (typeof value !== 'string') {
              data.insert(rowIndex, [value])
            } else {
              const trimmed = value.trim()

              if (!trimmed) {
                data.insert(rowIndex, [''])
              } else {
                data.insert(rowIndex, [trimmed])
              }
            }

            for (
              let i = columnIndex + 1;
              i < this.grid.get('columns').length;
              i++
            ) {
              this.calculateColumnValuesFromFormula(i)
            }
        }

        if (
          this.focusedCell?.column === columnIndex &&
          this.focusedCell?.row === rowIndex
        ) {
          this.$emit('update:focusedCell', {
            row: rowIndex,
            column: columnIndex,
            value: {
              value: value.formula ?? value,
              dataType:
                value.parser ??
                (typeof rowIndex !== 'number' || column.get('allowText')
                  ? 'text'
                  : 'number')
            }
          })
        }
      })
    },
    async showColumnSettingsModalForColumn(columnIndex, focusEl = null) {
      const columns = this.grid.get('columns')
      const column = columns.get(columnIndex)

      const { status, data } = await this.$modal.show(
        DataGridColumnSettingsModal,
        {
          column: column.toJSON()
        }
      )

      if (status === 'ok') {
        const { format, decimalPlaces, significantFigures, allowText } = data
        const referencedBy = this.columnsWithFormulaUsingColumn(columnIndex)

        // require confirmation if this column is being used in any formulas
        if (!column.get('allowText') && allowText && referencedBy.length > 0) {
          const { status } = await this.$modal.show(ConfirmModal, {
            text: `This column is referenced in the formula of ${
              referencedBy.length
            } of other ${
              referencedBy.length === 1 ? 'column' : 'columns'
            }. Changing this column to allow text will remove the formula for any other column that is referencing it. This cannot be undone.`,
            prompt: 'Are you sure you want to change this column to allow text?'
          })

          if (status !== 'ok') {
            return
          }
        }

        this.grid.doc.transact(() => {
          // converting from text column back to number column,
          // try to parse a float from the text
          if (column.get('allowText') && !allowText) {
            column.set(
              'data',
              Y.Array.from(
                column.get('data').map(value => {
                  const float = parseFloat(value)

                  return isNaN(float) ? null : float
                })
              )
            )

            column.set('width', DEFAULT_NUM_WIDTH)
          }

          // converting from number column to text column,
          if (!column.get('allowText') && allowText) {
            for (const index of referencedBy) {
              columns.get(index).set('formula', '')
            }

            // Update graph data if any plots are referencing this column that changed to text
            const hColumns = this.grid.get('hColumns')
            const vColumns = this.grid.get('vColumns')

            if (hColumns) {
              if (hColumns.get('data') === columnIndex) {
                hColumns.set('data', -1)
              }
              if (hColumns.get('uncertainty') === columnIndex) {
                hColumns.set('uncertainty', -1)
              }
            }

            if (vColumns) {
              vColumns.forEach(column => {
                if (column.get('data') === columnIndex) {
                  column.set('data', -1)
                }
                if (column.get('uncertainty') === columnIndex) {
                  column.set('uncertainty', -1)
                }
              })
            }

            column.set('width', DEFAULT_TEXT_WIDTH)
          }

          column.set('format', format)
          column.set('decimalPlaces', decimalPlaces)
          column.set('significantFigures', significantFigures)
          column.set('allowText', allowText)
          if (column.get('allowText')) {
            column.set('formula', '')
          }
        })
      }

      // Give focus back to the button that opened the modal
      if (focusEl) focusEl.focus()
    },
    async showColumnFormulaModalForColumn(columnIndex, focusEl = null) {
      const { status, data } = await this.$modal.show(
        DataGridColumnFormulaModal,
        {
          gridData: this.grid.toJSON(),
          columnIndex
        }
      )

      if (status !== 'ok') {
        focusEl?.focus()
        return
      }

      const columns = this.grid.get('columns')
      const column = columns.get(columnIndex)
      const formula = data.formula

      if (!column.get('formula') && formula && columnHasData(column)) {
        const { status } = await this.$modal.show(ConfirmModal, {
          text: 'This column has one or more cells which contain data. Changing the formula of this column will overwrite any existing data with the result of the formula. This cannot be undone.',
          prompt: 'Are you sure you want to change the formula of this column?'
        })

        if (status !== 'ok') {
          focusEl?.focus()
          return
        }
      }

      this.grid.doc.transact(() => {
        column.set('formula', data.formula)
        column.set('format', 'sigfigs')
        column.set('significantFigures', 3)

        for (let i = columnIndex; i < this.grid.get('columns').length; i++) {
          this.calculateColumnValuesFromFormula(i)
        }
      })

      // Give focus back to the button that opened the modal
      focusEl?.focus()
    },
    downloadDataAsCSV() {
      const filename = 'pivot-interactives-data.csv'
      let data = ''

      const header = []
      this.grid.get('columns').forEach(column => {
        header.push(columnDisplayName(column))
      })
      data += header.join(',') + '\n'

      for (let rowIndex = 0; rowIndex < this.grid.get('rows'); rowIndex++) {
        const row = []

        this.grid.get('columns').forEach(column => {
          row.push(columnDisplayValue(column, rowIndex))
        })

        data += row.join(',') + '\n'
      }

      const blob = new Blob([data], { type: 'type/csv;charset=utf-8;' })
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, filename)
      } else {
        const link = document.createElement('a')
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', filename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    async reset() {
      const { status } = await this.$modal.show(ConfirmModal, {
        text: 'This will delete all data in your table and replace it with the preset data and settings for this assignment. This cannot be undone.',
        prompt:
          'Are you sure you want to reset this data table to the preset data and settings?'
      })

      if (status === 'ok') {
        this.$emit('reset')
      }
    },
    async connectBluetoothDevice() {
      try {
        const device = await bluetooth.connectDevice()
        if (device) {
          this.bluetooth.connectedDevices[device.id] = markRaw(device)

          this.createColumnsForBluetoothDataStreams()
        }
      } catch (error) {
        this.$error(`Error connecting device: ${error.message}`)
      }
    },
    async configureBluetoothDevices() {
      const bluetoothYMap = this.grid.get('bluetooth')

      const { status, data } = await this.$modal.show(
        ConfigureBluetoothDevicesModal,
        {
          currentConnectedDevices: this.bluetooth.connectedDevices,
          currentSampleRate: bluetoothYMap.get('sampleRate'),
          currentMaxSamples: bluetoothYMap.get('maxSamples')
        }
      )

      if (status === 'ok') {
        this.bluetooth.connectedDevices = data.connectedDevices
        this.grid.doc.transact(() => {
          bluetoothYMap.set('sampleRate', data.sampleRate)
          bluetoothYMap.set('maxSamples', data.maxSamples)
          this.bluetooth.samplePeriod = 1000 / bluetoothYMap.get('sampleRate')

          this.createColumnsForBluetoothDataStreams()
        })
      }
    },
    async startCollectingBluetoothSamples() {
      this.bluetooth.collectingSamples = true
      this.bluetooth.totalSamples = 0

      const dataStreamColumnIndexes = this.calcDataStreamColumnIndexes()

      const timeColumnIndex = dataStreamColumnIndexes['SYSTEM-TIME']
      if (typeof timeColumnIndex === 'number') {
        clearColumnData(this.grid.get('columns').get(timeColumnIndex))
      }

      await Promise.all(
        Object.values(this.bluetooth.connectedDevices).map(async device => {
          for (const dataStream of Object.values(device.dataStreams)) {
            const columnIndex =
              dataStreamColumnIndexes[`${device.id}-${dataStream.id}`]
            if (typeof columnIndex === 'number') {
              clearColumnData(this.grid.get('columns').get(columnIndex))
            }
          }
          await device.reset()
          await device.startCollectingSamples(this.bluetooth.samplePeriod)
        })
      )

      this.grid.doc.transact(() => {
        cropRowsForGridData(this.grid)
      })

      this.onBluetoothSampleCollectionTick()
    },
    async stopCollectingBluetoothSamples() {
      this.bluetooth.collectingSamples = false

      await Promise.all(
        Object.values(this.bluetooth.connectedDevices).map(async device => {
          await device.stopCollectingSamples()
          await device.reset()
        })
      )
    },
    setColumnWidth(columnIndex, width) {
      this.grid.doc.transact(() => {
        const columns = this.grid.get('columns')
        const column = columns.get(columnIndex)
        column.set('width', Math.max(60, width))
      })
    },

    /** * PRIVATE FUNCTIONS ***/

    calcDataStreamColumnIndexes() {
      return this.grid
        .get('columns')
        .toArray()
        .reduce((acc, column, index) => {
          if (column.get('deviceId')) {
            acc[`${column.get('deviceId')}-${column.get('dataStreamId')}`] =
              index
          }
          return acc
        }, {})
    },
    onBluetoothDeviceDisconnected(device) {
      this.$error('Bluetooth device disconnected')
      delete this.bluetooth.connectedDevices[device.id]
      this.stopCollectingBluetoothSamples()
    },
    async onBluetoothDeviceError(error, device) {
      this.$error(`Bluetooth device error: ${error.message}`)
      delete this.bluetooth.connectedDevices[device.id]
      this.stopCollectingBluetoothSamples()
      // Try to disconnect, but it's already removed from connectedDevices
      try {
        await device.disconnect()
      } catch {}
    },
    onBluetoothSampleCollectionTick() {
      if (!this.bluetooth.collectingSamples) {
        return
      }
      const columns = this.grid.get('columns')
      const dataStreamColumnIndexes = this.calcDataStreamColumnIndexes()

      const timeColumnIndex = dataStreamColumnIndexes['SYSTEM-TIME']

      let firstDataStreamColumnIndex
      let userSampleLimitReached = false
      let maxSampleLimitReached = false

      this.grid.doc.transact(() => {
        for (const device of Object.values(this.bluetooth.connectedDevices)) {
          for (const dataStream of Object.values(device.dataStreams)) {
            const columnIndex =
              dataStreamColumnIndexes[`${device.id}-${dataStream.id}`]

            if (typeof columnIndex === 'number') {
              // Keep lowest column index to calculate formulas from
              if (
                !firstDataStreamColumnIndex ||
                columnIndex < firstDataStreamColumnIndex
              ) {
                firstDataStreamColumnIndex = columnIndex
              }

              // Check if this data stream has new samples buffered
              if (dataStream.hasNewSamples) {
                const samples = dataStream.readNewSamples()
                for (const sample of samples) {
                  const sampleTime = dataStream.getNextSampleTime()

                  // Get row index for existing row with this same sample time
                  let rowIndex = columns
                    .get(timeColumnIndex)
                    .get('data')
                    .toArray()
                    .indexOf(sampleTime)

                  // If existing sample time row doesn't exist, check if there
                  // is an empty row if we need to add a new one. Also set the
                  // time value in that row of the time column
                  if (rowIndex < 0) {
                    rowIndex = columns
                      .get(timeColumnIndex)
                      .get('data')
                      .toArray()
                      .findIndex(v => v === '' || v === null)
                    if (rowIndex > -1) {
                      const data = columns.get(timeColumnIndex).get('data')
                      data.delete(rowIndex, 1)
                      data.insert(rowIndex, [sampleTime])
                    } else {
                      // Add a new row and data to each column
                      this.grid.set('rows', this.grid.get('rows') + 1)
                      for (let i = 0; i < columns.length; i++) {
                        columns.get(i).get('data').push([''])
                      }
                      rowIndex = this.grid.get('rows') - 1

                      const data = columns.get(timeColumnIndex).get('data')
                      data.delete(rowIndex, 1)
                      data.insert(rowIndex, [sampleTime])
                    }
                  }

                  // Set the sample value in the correct column and row
                  const data = columns.get(columnIndex).get('data')
                  data.delete(rowIndex, 1)
                  data.insert(rowIndex, [sample])

                  const lastDataRow = lastDataRowForColumn(
                    columns.get(columnIndex)
                  )

                  if (rowIndex > this.bluetooth.totalSamples) {
                    this.bluetooth.totalSamples = rowIndex
                  }

                  if (
                    lastDataRow >=
                    this.grid.get('bluetooth').get('maxSamples') - 1
                  ) {
                    userSampleLimitReached = true
                    break
                  } else if (lastDataRow >= MAX_SAMPLES_PER_TRIAL - 1) {
                    maxSampleLimitReached = true
                    break
                  }
                }
              }
            }
          }
        }

        if (
          typeof firstDataStreamColumnIndex === 'number' &&
          firstDataStreamColumnIndex > -1
        ) {
          // Update calculated columns from first data stream column on
          for (let i = firstDataStreamColumnIndex; i < columns.length; i++) {
            this.calculateColumnValuesFromFormula(i)
          }
        }
      })

      this.$emit('bluetooth-data-collected', {
        totalSamples: this.bluetooth.totalSamples
      })

      if (userSampleLimitReached) {
        this.stopCollectingBluetoothSamples()
      } else if (maxSampleLimitReached) {
        this.stopCollectingBluetoothSamples()
        this.$error(
          `Bluetooth sample collection stopped after reaching the maximum of ${MAX_SAMPLES_PER_TRIAL} samples.`
        )
      } else if (this.bluetooth.collectingSamples) {
        window.requestAnimationFrame(this.onBluetoothSampleCollectionTick)
      }
    },
    createColumnsForBluetoothDataStreams() {
      if (Object.values(this.bluetooth.connectedDevices).length < 1) {
        return
      }

      const columns = this.grid.get('columns')

      if (columnsAreUntouched(columns)) {
        columns.delete(0, columns.length)
      }

      const dataStreamColumnIndexes = this.calcDataStreamColumnIndexes()

      const timeColumnIndex = dataStreamColumnIndexes['SYSTEM-TIME']
      if (typeof timeColumnIndex !== 'number') {
        this.insertColumnBeforeColumn(0, {
          name: 'Time',
          units: 's',
          format: 'decimals',
          significantFigures: 3,
          deviceId: 'SYSTEM',
          dataStreamId: 'TIME'
        })
      }

      for (const device of Object.values(this.bluetooth.connectedDevices)) {
        device.onDisconnect = this.onBluetoothDeviceDisconnected
        device.onError = this.onBluetoothDeviceError

        const dataStreamColumnIndexes = this.calcDataStreamColumnIndexes()

        for (const dataStream of Object.values(device.dataStreams)) {
          const columnIndex =
            dataStreamColumnIndexes[`${device.id}-${dataStream.id}`]
          if (typeof columnIndex !== 'number') {
            this.insertColumnAfterColumn(columns.length - 1, {
              name: dataStream.name,
              units: dataStream.units,
              format: 'decimals',
              significantFigures: 3,
              deviceId: device.id,
              dataStreamId: dataStream.id
            })
            if (dataStream.name.toLowerCase() === 'position') {
              this.insertColumnAfterColumn(columns.length - 1, {
                name: 'Velocity',
                units: dataStream.units + '/s',
                format: 'decimals',
                formula: `rateOfChange("(col${columns.length - 1})", "(col${
                  dataStreamColumnIndexes['SYSTEM-TIME']
                })")`,
                significantFigures: 3
              })
            }
          }
        }
      }
    },
    calculateColumnValuesFromFormula(columnIndex) {
      const columns = this.grid.get('columns')
      const column = columns.get(columnIndex)

      if (column.get('allowText') || !column.get('formula')) {
        return
      }
      const data = column.get('data').map((_, rowIndex) => {
        try {
          const scope = columns
            .slice(0, columnIndex)
            .reduce((acc, column, index) => {
              const value = column.get('data').get(rowIndex)
              let computedValue = column.get('allowText')
                ? value
                : math.bignumber(parseFloat(value))
              if (value?.parser) {
                computedValue = evaluateExpression(
                  value.id,
                  this.variableContext.variables
                )
                computedValue =
                  typeof computedValue === 'number'
                    ? math.bignumber(computedValue)
                    : computedValue
              }
              acc[`col${index}`] = computedValue

              return acc
            }, {})

          scope.rateOfChange = (y, x) => {
            const xIndex = parseInt((/(\d+)/.exec(x) ?? [])[1])
            const yIndex = parseInt((/(\d+)/.exec(y) ?? [])[1])

            const xColumn = columns.get(xIndex)
            const yColumn = columns.get(yIndex)

            const cleanData = xColumn
              .get('data')
              .toArray()
              .map((x, rowIndex) => ({
                rowIndex,
                x,
                y: yColumn.get('data').get(rowIndex)
              }))
              .map((value, rowIndex) => {
                const evaluateIfExpression = field =>
                  field?.parser === 'expression'
                    ? evaluateExpression(
                        field.id,
                        this.variableContext.variables
                      )
                    : field

                const computedXExpr = evaluateIfExpression(value?.x)
                const computedYExpr = evaluateIfExpression(value?.y)

                return {
                  rowIndex: rowIndex,
                  x: computedXExpr?.toString() || value.x,
                  y: computedYExpr?.toString() || value.y
                }
              })
              .filter(row =>
                Object.values(row).every(
                  e => e !== null && e !== '' && !isNaN(e)
                )
              )
              .sort(({ x: a }, { x: b }) => a - b)
            if (!cleanData.some(row => row.rowIndex === rowIndex)) {
              return NaN
            }
            const currentDataIndex = cleanData.findIndex(
              row => row.rowIndex === rowIndex
            )

            if (
              currentDataIndex === 0 ||
              currentDataIndex === cleanData.length - 1
            ) {
              // first or last point-don't calculate
              return NaN
            }
            const before1 = cleanData[currentDataIndex - 1]
            const after1 = cleanData[currentDataIndex + 1]
            const delta1 = (after1.y - before1.y) / (after1.x - before1.x)

            if (
              currentDataIndex === 1 ||
              currentDataIndex === cleanData.length - 2
            ) {
              // next to edge-average over 3 points
              return math.bignumber(delta1)
            }

            const before2 = cleanData[currentDataIndex - 2]
            const after2 = cleanData[currentDataIndex + 2]
            const delta2 = (after2.y - before2.y) / (after2.x - before2.x)

            if (
              currentDataIndex === 2 ||
              currentDataIndex === cleanData.length - 3
            ) {
              // 2 from edge-average over 5 points
              return math.bignumber((2 * delta1 + delta2) / 3)
            } else {
              // 3 or more from edge-average over 7 points
              const before3 = cleanData[currentDataIndex - 3]
              const after3 = cleanData[currentDataIndex + 3]
              const delta3 = (after3.y - before3.y) / (after3.x - before3.x)

              return math.bignumber((3 * delta1 + 2 * delta2 + 1 * delta3) / 6)
            }
          }
          return math.number(math.evaluate(column.get('formula'), scope))
        } catch (e) {
          return ''
        }
      })

      const newData = data.map(v =>
        v === null || isNaN(v) || !Number.isFinite(v) ? '' : v
      )
      const currentData = column.get('data')
      if (
        newData.length !== currentData.length ||
        currentData.toArray().some((value, i) => value !== newData[i])
      ) {
        column.set('data', Y.Array.from(newData))
      }
    },
    columnsWithFormulaUsingColumn(columnIndex) {
      return this.grid
        .get('columns')
        .toArray()
        .reduce((acc, column, index) => {
          if ((column.get('formula') ?? '').includes(`(col${columnIndex})`)) {
            acc.push(index)
          }

          return acc
        }, [])
    },
    paste(columnIndex, rowIndex, event) {
      const clipboardData = event.clipboardData
      const pastedText =
        clipboardData.getData('Text') || clipboardData.getData('text/plain')
      if (!pastedText && pastedText.length) {
        return
      }
      event.preventDefault()

      const rows = pastedText
        .replace(
          /"((?:[^"]*(?:\r\n|\n\r|\n|\r))+[^"]+)"/gm,
          function (match, p1) {
            // This function runs for each cell with multi lined text.
            return (
              p1
                // Replace any double double-quotes with a single
                // double-quote
                .replace(/""/g, '"')
                // Replace all new lines with spaces.
                .replace(/\r\n|\n\r|\n|\r/g, ' ')
            )
          }
        )
        // Split each line into rows
        .split(/\r\n|\n\r|\n|\r/g)

      const columns = this.grid.get('columns')

      this.grid.doc.transact(() => {
        rows.forEach((row, i) => {
          const cells = row.split('\t')
          cells.forEach((cell, c) => {
            const column = columns.get(columnIndex + c)
            if (column && !column.get('formula')) {
              const data = column.get('data')
              data.delete(rowIndex + i, 1)
              data.insert(rowIndex + i, [cell])
            }
          })
        })
      })
    }
  },
  render() {
    return this.$slots.default({
      insertColumnBeforeColumn: this.insertColumnBeforeColumn,
      insertColumnAfterColumn: this.insertColumnAfterColumn,
      removeColumn: this.removeColumn,
      insertRowAboveRow: this.insertRowAboveRow,
      insertRowBelowRow: this.insertRowBelowRow,
      removeRow: this.removeRow,
      setValueAtColumnAndRow: this.setValueAtColumnAndRow,
      showColumnSettingsModalForColumn: this.showColumnSettingsModalForColumn,
      showColumnFormulaModalForColumn: this.showColumnFormulaModalForColumn,
      downloadDataAsCSV: this.downloadDataAsCSV,
      reset: this.reset,
      connectBluetoothDevice: this.connectBluetoothDevice,
      configureBluetoothDevices: this.configureBluetoothDevices,
      startCollectingBluetoothSamples: this.startCollectingBluetoothSamples,
      stopCollectingBluetoothSamples: this.stopCollectingBluetoothSamples
    })
  }
}
