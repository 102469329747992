<template>
  <view-container>
    <loading-container :loading="loading">
      <tab-provider :tab="tab" @change="changeTab">
        <variable-provider
          ref="variableProvider"
          v-slot="{ variableContext }"
          v-model:variables="activity.variables"
          :activity="activity"
        >
          <progress-provider>
            <async-form
              class="form-horizontal"
              @submit="saveActivity"
              persist
              @fail="onFail"
            >
              <sticky-header>
                <template #primary-navigation>
                  <breadcrumb v-if="!loading && backLinkText">
                    <breadcrumb-item
                      :to="{
                        name: 'activities',
                        query: { library: backLink }
                      }"
                      >{{ backLinkText }}
                    </breadcrumb-item>
                  </breadcrumb>
                </template>
                <template #page-action>
                  <tab-panel>
                    <template #content>
                      <form-button link @click="toggleSectionsAndComponents">
                        {{ allOpen ? 'Hide' : 'Show' }} All Sections &
                        Components
                      </form-button>
                    </template>
                    <template #changelog>
                      <form-button link @click="toggleChangelogCollapsed">
                        Toggle All Sections
                      </form-button>
                    </template>
                  </tab-panel>
                </template>
                <template #title>
                  <sticky-header-title>
                    {{ title }}
                  </sticky-header-title>
                </template>
                <template #sub-title="{ isStuck }">
                  <sticky-header-sub-title>
                    <div v-if="isStuck" class="progress-summary-heading">
                      <progress-summary />
                      <tab-header stuck>
                        <tab-header-button tab="info">
                          Activity Info
                        </tab-header-button>
                        <tab-header-button tab="content">
                          Activity Content
                        </tab-header-button>
                      </tab-header>
                    </div>
                    <tab-header v-else>
                      <tab-header-button tab="info">
                        Activity Info
                      </tab-header-button>
                      <tab-header-button tab="content">
                        <span
                          :class="{
                            'error-tab bg-warning-orange-600 text-white block -mt-[5px] -mb-[8px] -ml-[10px] -mr-[10px] px-[10px] pt-[5px] pb-[8px]':
                              hasContentValidationErrors
                          }"
                        >
                          <icon
                            v-if="hasContentValidationErrors"
                            icon="triangle-exclamation"
                            class="error-icon"
                          />
                          Activity Content
                        </span>
                      </tab-header-button>
                      <tab-header-button tab="changelog" v-if="!isNew">
                        Changelog
                      </tab-header-button>
                    </tab-header>
                  </sticky-header-sub-title>
                </template>
                <template #actions>
                  <form-button
                    v-if="showSplitMigrationPrompt"
                    secondary
                    @click="promptToMigrateSplitView"
                  >
                    Migrate Split View
                  </form-button>
                  <button-link
                    v-if="canPreview"
                    secondary
                    target="_blank"
                    :to="{
                      name: 'preview_activity',
                      params: { id: activity.id }
                    }"
                  >
                    Preview Activity
                  </button-link>
                  <form-button secondary @click="addVariables">
                    Edit Variables
                  </form-button>
                  <submit-button action="save">
                    <template #default>Save</template>
                    <template #submitting>Saving</template>
                    <template #submitted>Saved</template>
                  </submit-button>
                </template>
                <template #alerts>
                  <version-alert
                    :showChanges="showChanges"
                    :showVersionHistory="showVersionHistory"
                    :activity="activity"
                    inline
                  />
                  <ValidationAlert
                    :show="showErrors"
                    :errors="validationErrors"
                    :activity="activity"
                  />
                </template>
              </sticky-header>

              <tab-panel>
                <template #info>
                  <div class="base-heading">
                    <h3>Activity Info</h3>
                    <form-button
                      v-if="canReplaceActivity"
                      warning
                      class="pull-right"
                      style="margin-top: 10px"
                      @click="showReplaceModal"
                      >Replace Prior Activities</form-button
                    >
                  </div>
                  <div class="activity-info-panels">
                    <activity-info-tab :variable-context="variableContext" />
                  </div>
                </template>

                <template #content>
                  <div class="base-heading activity-content-heading">
                    <h3>Objectives</h3>
                  </div>
                  <activity-content-tab :variable-context="variableContext" />
                </template>

                <template #changelog>
                  <div class="base-heading">
                    <h3>Changelog</h3>
                  </div>
                  <activity-changelog-tab
                    v-model:collapsed="changelogCollapsed"
                    @saved="onChangelogSave"
                  />
                </template>
              </tab-panel>
            </async-form>
          </progress-provider>
        </variable-provider>
      </tab-provider>
    </loading-container>
  </view-container>
</template>

<script>
import * as Vue from 'vue'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import * as types from 'src/setup/store/mutation-types'

import { trackEvent } from '../../../setup/analytics'
import ActivityVersionErrorModal from 'src/shared/components/modals/ActivityVersionErrorModal'
import ActivityVariablesModal from 'src/shared/components/modals/ActivityVariablesModal'
import ActivityInfoTab from '../components/ActivityInfoTab.vue'
import ActivityChangelogTab from '../components/ActivityChangelogTab.vue'
import FormButton from 'src/shared/components/global/FormButton.vue'
import ActivityContentTab from '../components/ActivityContentTab.vue'
import VariableProvider from 'src/shared/components/VariableProvider'
import ReplaceActivityModal from '../components/ReplaceActivityModal'
import VersionAlert from 'src/modules/activities/components/VersionAlert.vue'
import useTitle from 'src/shared/hooks/title'
import client from 'src/shared/api-client'
import ValidationAlert from 'src/modules/activity-editor/components/ValidationAlert.vue'
import {
  activityCanMigrateToSplitView,
  migrateActivityToSplitView
} from '../utils'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import LoadingModal from 'src/shared/components/modals/LoadingModal.vue'

export default {
  name: 'ActivityView',
  inject: ['$modal'],
  components: {
    VariableProvider,
    ActivityInfoTab,
    FormButton,
    ActivityContentTab,
    ActivityChangelogTab,
    VersionAlert,
    ValidationAlert
  },
  props: {
    id: {
      type: String
    },
    libraryId: {
      type: String
    },
    tab: {
      type: String,
      required: false,
      default: 'info'
    }
  },
  provide() {
    return {
      inherited: Vue.computed(() => ({
        saveVariables: this.saveVariables,
        activity: this.activity,
        tags: this.tags,
        publicTags: this.publicTags,
        prevSubmittableComponentMap: this.prevSubmittableComponentMap
      }))
    }
  },
  data: function () {
    return {
      activity: {
        name: '',
        description: '',
        thumbnail: '',
        objectives: {
          summary: ''
        },
        notes: '',
        sections: [],
        library: undefined,
        tags: [],
        publicTags: [],
        categories: [],
        variables: [],
        isFree: false,
        isSplitViewDefault: true
      },
      tags: [],
      publicTags: [],
      loading: true,
      changelogCollapsed: true,
      validationErrors: {},
      showErrors: false
    }
  },
  async created() {
    await Promise.all([
      this.loadInstances(),
      this.fetchActivity(),
      this.loadTags()
    ])
    useTitle(() =>
      this.activity.name && this.activity.id
        ? `${this.activity.name} | ${this.tabTitle}`
        : `New Activity | ${this.tabTitle}`
    )
    this.loading = false
  },
  computed: {
    ...mapGetters([
      'features',
      'canManageActivities',
      'userId',
      'isAdmin',
      'isContentDeveloper'
    ]),
    isNew() {
      return !this.activity.id
    },
    hasContentValidationErrors() {
      return Object.keys(this.validationErrors).some(key =>
        key.includes('section')
      )
    },
    showVersionHistory() {
      return this.activity.revisedVersions
    },
    showChanges() {
      return this.activity.parentChanges?.length > 0
    },
    showSplitMigrationPrompt() {
      return (
        !this.activity.splitViewComponentEnabled &&
        (this.isAdmin || this.isContentDeveloper)
        //activityCanMigrateToSplitView(this.activity)
      )
    },
    canReplaceActivity() {
      return this.isContentDeveloper || this.isAdmin
    },
    title() {
      return this.activity.name && this.activity.id
        ? 'Edit Activity: ' + this.activity.name
        : 'Activity'
    },
    backLinkText() {
      return `${this.activity.library.name}`
    },
    backLink() {
      return this.activity.library.id
    },
    canPreview() {
      return this.activity.id
    },
    allOpen() {
      return this.activity.sections.every(section => !section.collapsed)
    },
    activityComponents() {
      return this.activity.sections.flatMap(section => {
        const flattenedComponents = section.components.flatMap(component => {
          if (component.componentType === 'SplitView') {
            return [...component.leftContent, ...component.rightContent]
          } else {
            return component
          }
        })
        return flattenedComponents.map(c => ({
          sectionName: section.name,
          ...c
        }))
      })
    },
    prevSubmittableComponentMap() {
      const componentMap = {}
      let lastSubmittable

      for (const component of this.activityComponents) {
        componentMap[component._id] = lastSubmittable
        const isQuestion = component.componentType.includes('Question')
        if (component.autograde) {
          lastSubmittable = component._id
        } else if (isQuestion) {
          lastSubmittable = undefined
        }
      }
      return componentMap
    },
    tabTitle() {
      return this.tab === 'info'
        ? 'Activity Info'
        : this.tab === 'content'
          ? 'Activity Content'
          : 'Changelog'
    }
  },
  methods: {
    ...mapMutations([types.CLEAR_FLASH_MESSAGES]),
    ...mapActions('instances', ['loadInstances']),
    async onChangelogSave() {
      // Saving a new changelog entry will increment the version number
      // and saving further changes to the activity will fail.
      // We can't just get the new version number after adding changelog entries
      // because another session might have updated the activity content in the background.
      // By checking to see that the version incremented by only one,
      // we can update the version number to the latest only when we have made changes to the changelog
      // and updates from other sessions will still cause version conflicts
      const activity = await client.activities.get({ activityId: this.id })
      if (activity.version - 1 === this.activity.version) {
        this.activity.version += 1
      }
    },
    fetchActivity: async function () {
      if (this.id) {
        // Newly created components don't have unique IDs until they are saved
        // and sections never had unique IDs,
        // so we generate IDs to use for template keys.
        const activity = await client.activities.get({ activityId: this.id })
        for (const i in activity.sections) {
          const prevSection = this.activity?.sections[i]
          const section = activity.sections[i]
          section.collapsed = prevSection?.collapsed ?? i != 0
          section.keyId =
            prevSection?.keyId ?? Math.floor(Math.random() * 100000)
          for (const j in section.components) {
            const prevComponent = prevSection?.components[j]
            const component = section.components[j]
            component.collapsed = prevComponent?.collapsed ?? true
            component.keyId =
              prevComponent?.keyId ?? Math.floor(Math.random() * 100000)
            if (component.instance && component.instance.id) {
              component.instance = component.instance.id
            }
            if (Array.isArray(component.choices)) {
              for (const choice of component.choices) {
                choice.keyId = Math.floor(Math.random() * 100000)
              }
            }
            if (Array.isArray(component.conditions)) {
              for (const condition of component.conditions) {
                condition.keyId = Math.floor(Math.random() * 100000)
              }
            }
            if (component.componentType === 'SplitView') {
              for (const l in component.leftContent) {
                const leftComponent = component.leftContent[l]
                const prevLeftComponent = prevComponent?.leftContent[l]
                leftComponent.keyId =
                  prevLeftComponent?.keyId ?? Math.floor(Math.random() * 100000)
                if (leftComponent.instance && leftComponent.instance.id) {
                  leftComponent.instance = leftComponent.instance.id
                }
                if (Array.isArray(leftComponent.choices)) {
                  for (const choice of leftComponent.choices) {
                    choice.keyId = Math.floor(Math.random() * 100000)
                  }
                }
                if (Array.isArray(leftComponent.conditions)) {
                  for (const condition of leftComponent.conditions) {
                    condition.keyId = Math.floor(Math.random() * 100000)
                  }
                }
              }
              for (const r in component.rightContent) {
                const rightComponent = component.rightContent[r]
                const prevRightComponent = prevComponent?.rightContent[r]
                rightComponent.keyId =
                  prevRightComponent?.keyId ??
                  Math.floor(Math.random() * 100000)
                if (rightComponent.instance && rightComponent.instance.id) {
                  rightComponent.instance = rightComponent.instance.id
                }
                if (Array.isArray(rightComponent.choices)) {
                  for (const choice of rightComponent.choices) {
                    choice.keyId = Math.floor(Math.random() * 100000)
                  }
                }
                if (Array.isArray(rightComponent.conditions)) {
                  for (const condition of rightComponent.conditions) {
                    condition.keyId = Math.floor(Math.random() * 100000)
                  }
                }
              }
            }
          }
        }
        this.activity = activity
      } else if (this.libraryId) {
        this.activity.library = { id: this.libraryId }
        this.activity.splitViewComponentEnabled = true
      }
    },
    toggleSectionsAndComponents() {
      const newCollapsed = this.allOpen
      this.activity.sections.forEach(section => {
        section.collapsed = newCollapsed

        section.components.forEach(component => {
          component.collapsed = newCollapsed
          if (component.componentType === 'SplitView') {
            component.rightContent.forEach(rightComponent => {
              rightComponent.collapsed = newCollapsed
            })
            component.leftContent.forEach(leftComponent => {
              leftComponent.collapsed = newCollapsed
            })
          }
        })
      })
    },
    toggleChangelogCollapsed() {
      this.changelogCollapsed = !this.changelogCollapsed
    },
    async addVariables() {
      await this.$modal.show(ActivityVariablesModal, {
        variableContext: {
          ...this.$refs.variableProvider?.context,
          activity: this.activity
        },
        allowInsert: true
      })
    },
    async saveVariables(variables) {
      this.activity.variables = variables
    },
    async saveActivity(evt) {
      try {
        this.showErrors = false
        this.$clearFlash()
        if (this.id) {
          await client.activities.update({
            activityId: this.id,
            ...this.activity
          })

          trackEvent('save_activity')
          this.$success(`Activity ${this.activity.name} saved successfully.`)
          await this.fetchActivity() // ensure we have the correct version number
        } else {
          const id = await client.libraries.createActivity({
            libraryId: this.activity.library.id,
            ...this.activity
          })

          trackEvent('save_activity')
          this.$success(`Activity ${this.activity.name} saved successfully.`)
          this.redirectFromNew(id)
        }

        if (evt && evt.action) evt.done()
      } catch (resp) {
        if (
          resp.body?.errors?.[0]?.message.startsWith(
            'Expected version did not match'
          )
        ) {
          await this.$modal.show(ActivityVersionErrorModal, {
            activity: this.activity
          })
          trackEvent('save_activity')
          if (evt) evt.done()
        } else {
          if (evt) evt.done(false)
          throw resp
        }
      }
    },
    computeQuestionNumbers(section) {
      let questionNumber = 1
      return section.components.map(component => {
        if (
          [
            'GeneralNote',
            'IFrame',
            'EmbeddedInstance',
            'PhetIOSim',
            'PhetIOSimulation',
            'InstructorInstance',
            'StudentInstance'
          ].includes(component.componentType)
        ) {
          return 0
        }
        return questionNumber++
      })
    },
    async redirectFromNew(newActivityId) {
      await this.$router.push({
        name: 'activity',
        params: { id: newActivityId }
      })
      await this.fetchActivity()
    },
    changeTab(tab) {
      this.$router.push({
        name: this.$router.currentRoute.value.name,
        params: { id: this.id, tab }
      })
    },
    async loadTags() {
      const [tags, publicTags] = await Promise.all([
        client.activities.getTags(),
        client.activities.getPublicTags()
      ])

      this.tags = tags.tags
      this.publicTags = publicTags.tags
    },
    async showReplaceModal() {
      await this.$modal.show(ReplaceActivityModal, {
        activityId: this.id,
        activityName: this.activity.name
      })
    },
    async onFail({ errors }) {
      this.validationErrors = errors
      this.showErrors = true
    },
    async promptToMigrateSplitView() {
      const { status } = await this.$modal.show(ConfirmModal, {
        prompt: `This action will migrate this activity to the new split view. You will have a chance to edit before saving. Do you wish to continue?`
      })

      if (status === 'ok') {
        this.$modal.show(LoadingModal, { text: 'Migrating Activity' })
        setTimeout(async () => {
          const newActivity = await migrateActivityToSplitView(this.activity)
          this.$modal.close()
          this.activity = newActivity
          this.$success(
            'This activity has been updated. Please review and save if you are happy with the changes.'
          )
        }, 200)
      }
    }
  },

  watch: {
    async id() {
      this.loading = true
      await this.fetchActivity()
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.base-heading {
  border-bottom: 2px solid $teal;

  & h3 {
    display: inline-block;
    margin-right: 10px;
  }

  & span {
    font-size: 14px;
    color: $teal;
    font-weight: 600;
    cursor: pointer;
  }
}

.activity-content-heading {
  border-bottom: none;
}

.activity-info-panels {
  :deep(.panel) {
    margin: 0;
    border: 0px;
    border-radius: 0px;
    box-shadow: unset;
    border-bottom: 2px solid $teal;
  }

  :deep(.collapse-panel__header) {
    padding-left: 0;
  }

  & .activity-inner-panel {
    display: flex;
    justify-content: space-between;

    & .left-panel-side {
      width: 50%;
      margin-right: 20px;

      & .activity-input-group {
        margin-bottom: 15px;
      }
    }

    & .right-panel-side {
      width: 50%;
      margin-left: 20px;

      & .thumbnail-image-container {
        max-width: 100%;
      }
    }
  }
}

.progress-summary-heading {
  display: flex;
  width: 100%;

  :deep(.title) {
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: $screen-sm) {
      max-width: 200px;
    }
    @media (min-width: $screen-sm) and (max-width: $screen-md) {
      max-width: 250px;
    }
    @media (min-width: $screen-md) and (max-width: $screen-lg) {
      max-width: 300px;
    }
    @media (min-width: $screen-lg) {
      max-width: 400px;
    }
  }
}
</style>
