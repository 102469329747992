<template>
  <li
    ref="rootElement"
    class="filter-option"
    role="treeitem"
    :aria-label="node.shortName"
    :aria-expanded="isLeaf ? undefined : !isCollapsed"
    :aria-selected="mutableValue || isIndeterminate"
    :tabindex="isFocused ? 0 : -1"
    @keydown="onKeyDown"
    @focus="onFocus"
    @blur="onBlur"
  >
    <checkbox
      v-if="isLeaf"
      class="filter-checkbox"
      v-model="mutableValue"
      tabindex="-1"
      :name="name"
    >
      {{ node.shortName }}
    </checkbox>
    <collapse-provider v-else renderless v-model:collapsed="isCollapsed">
      <div class="filter-option-header">
        <checkbox
          class="filter-checkbox-narrow"
          v-model="mutableValue"
          :indeterminate="isIndeterminate"
          tabindex="-1"
          :name="name"
          ><span class="sr-only">{{ node.shortName }}</span></checkbox
        >

        <collapse-toggle class="filter-collapse-toggle" tabindex="-1">
          <template #default>
            <div class="filter-collapse-toggle-div">
              <span>{{ node.shortName }}</span>
              <collapse-icon class="filter-collapse-button" icon="caret" />
            </div>
          </template>
        </collapse-toggle>
      </div>
      <collapse-content class="filter-option-collapse" :lazyLoad="true">
        <ul class="filter-list" role="group" ref="childrenElement">
          <tree-input-item
            v-for="(child, i) in node.children"
            v-model="nestedValue"
            :ref="el => (childElements[i] = el)"
            :key="child.id"
            :node="child"
            :name="name"
            @movefocus="arg => onFocusMove(i, arg)"
            :select-children="selectChildren"
          />
        </ul>
      </collapse-content>
    </collapse-provider>
  </li>
</template>

<script>
import { computed, nextTick, ref } from 'vue'

function getDescendants(node) {
  return (node.children ?? []).flatMap(child => [
    child.slug,
    ...getDescendants(child)
  ])
}

function removeNode(node, value, includeAncestors = true) {
  // When any ancestor node is selected,
  // we need to add all of the sibling nodes in order to remove this one.
  if (includeAncestors && node.ancestors.some(slug => value.includes(slug))) {
    return [...value, ...node.siblings]
  } else {
    return value.filter(slug => slug !== node.slug)
  }
}

export default {
  name: 'TreeInputItem',
  emits: ['update:modelValue', 'movefocus'],
  props: {
    node: {
      type: Object,
      required: true
    },
    modelValue: {
      type: Array,
      required: true
    },
    startFocus: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: undefined
    },
    selectChildren: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const childrenSlugs = computed(() =>
      (props.node.children ?? []).map(child => child.slug)
    )
    const descendantSlugs = computed(() => getDescendants(props.node))
    const checkedSlugs = computed(() => [
      props.node.slug,
      ...(props.selectChildren ? props.node.ancestors : [])
    ])
    const mutableValue = computed({
      get: () =>
        checkedSlugs.value.some(slug => props.modelValue.includes(slug)),
      set: isChecked => {
        if (isChecked) {
          // When checking all descendants need to be removed.
          const newModelValue = [
            ...props.modelValue.filter(
              slug => !descendantSlugs.value.includes(slug)
            ),
            props.node.slug
          ]

          emit('update:modelValue', newModelValue)
        } else {
          emit(
            'update:modelValue',
            removeNode(props.node, props.modelValue, props.selectChildren)
          )
        }
      }
    })

    const nestedValue = computed({
      get: () => props.modelValue,
      set: value => {
        const selectedChildren = childrenSlugs.value.filter(slug =>
          value.includes(slug)
        ).length
        if (props.selectChildren) {
          // If all children are selected, this category should be selected instead.
          if (selectedChildren === childrenSlugs.value.length) {
            value = value.filter(slug => !childrenSlugs.value.includes(slug))
            value.push(props.node.slug)
          }

          // If any children are selected, then this category cannot be selected.
          else if (selectedChildren > 0) {
            value = removeNode(props.node, value, true)
          }
        } else {
          // If any children are selected, then this category cannot be selected.
          if (selectedChildren > 0) {
            value = removeNode(props.node, value, false)
          }
        }
        emit('update:modelValue', value)
      }
    })

    const isLeaf = computed(() => (props.node.children?.length ?? 0) === 0)
    const isIndeterminate = computed(
      () =>
        !mutableValue.value &&
        descendantSlugs.value.some(slug => props.modelValue.includes(slug))
    )

    const rootElement = ref(null)
    const childElements = ref([])

    const isCollapsed = ref(true)
    const onKeyDown = e => {
      switch (e.key) {
        case ' ':
          mutableValue.value = !mutableValue.value
          break
        case 'ArrowRight':
          if (!isLeaf.value) {
            isCollapsed.value = false
            nextTick(() => childElements.value[0]?.focus())
          }
          break
        case 'ArrowLeft':
          if (!isLeaf.value && !isCollapsed.value) {
            isCollapsed.value = true
          } else if (isLeaf.value || isCollapsed.value) {
            emit('movefocus', { direction: 'up' })
          }
          break
        case 'ArrowDown':
          if (!isLeaf.value && !isCollapsed.value) {
            childElements.value[0]?.focus()
          } else {
            emit('movefocus', { direction: 'next' })
          }
          break
        case 'ArrowUp':
          emit('movefocus', { direction: 'prev' })
          break
        case 'Home':
          emit('movefocus', { direction: 'first' })
          break
        case 'End':
          emit('movefocus', { direction: 'last' })
          break
        default:
          return
      }
      e.stopPropagation()
      e.preventDefault()
    }

    const onFocusMove = (index, { direction }) => {
      switch (direction) {
        case 'up':
          rootElement.value?.focus()
          break
        case 'next': {
          const next = childElements.value[index + 1]
          if (next && !isCollapsed.value) {
            next.focus()
          } else {
            emit('movefocus', { direction: 'next' })
          }
          break
        }
        case 'prev': {
          const prev = childElements.value[index - 1]
          if (prev && !isCollapsed.value) {
            prev.focus('prev')
          } else {
            rootElement.value?.focus()
          }
          break
        }
      }
    }

    const focus = mode => {
      if (mode === 'prev') {
        if (isLeaf.value || isCollapsed.value) {
          rootElement.value?.focus()
        } else {
          childElements.value.slice(-1)[0]?.focus('prev')
        }
      } else {
        rootElement.value?.focus()
      }
    }

    const isFocused = ref(props.startFocus)
    const onFocus = e => {
      isFocused.value = true
    }
    const onBlur = e => {
      const treeRoot = rootElement.value?.closest('[role="tree"]')
      if (e.relatedTarget && treeRoot?.contains(e.relatedTarget)) {
        isFocused.value = false
      }
    }

    return {
      rootElement,
      childElements,
      isCollapsed,
      isFocused,
      isLeaf,
      isIndeterminate,
      mutableValue,
      nestedValue,
      focus,
      onKeyDown,
      onFocus,
      onBlur,
      onFocusMove
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-option {
  margin: 8px;
  font-size: 16px;

  .filter-option {
    margin: 8px 0;
  }

  &:focus {
    outline: none;

    & :deep(.checkbox-indicator) {
      box-shadow: 0 0 5px $silver;
    }

    & :deep(.filter-option:not(:focus) .checkbox-indicator) {
      box-shadow: none;
    }
  }
}

.filter-option-header {
  display: flex;
}

.filter-collapse-button {
  width: 16px;
  margin: 0 0 0 4px;
  text-align: center;
  margin-left: 8px;
}

.filter-checkbox {
  margin: 0;
  font-size: 16px;
  flex-grow: 1;

  :deep(label) {
    margin: 0;
  }
}

.filter-checkbox-narrow {
  margin: 0;
  font-size: 16px;

  :deep(label) {
    margin: 0;
  }
  width: 30px;
}

.filter-collapse-toggle {
  width: 100%;
}

.filter-collapse-toggle-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-list {
  padding: 0;
  list-style: none;
  padding-left: 32px;
}
</style>
