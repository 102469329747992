import { format } from 'mathjs'

const EXP_MATCH = /^(\d+(?:\.\d+)?)e([+-]\d+)$/

export function formatNumber(value, variable) {
  const { precision, precisionType, useScientificNotation } = variable
  if (useScientificNotation) {
    const expStr = format(value, {
      notation: 'exponential',
      precision:
        typeof precision === 'number'
          ? precision + (precisionType === 'decimal' ? 1 : 0)
          : undefined
    })
    const match = EXP_MATCH.exec(expStr)
    if (match) {
      return {
        coefficient: match[1],
        exponent: parseInt(match[2]).toString()
      }
    } else {
      return { coefficient: value }
    }
  }
  if (typeof precision === 'number') {
    switch (precisionType) {
      default:
      case 'decimal':
        return {
          coefficient: new Intl.NumberFormat('en-US', {
            minimumFractionDigits: precision,
            maximumFractionDigits: precision
          }).format(value)
        }
      case 'sigfigs':
        return {
          coefficient: new Intl.NumberFormat('en-US', {
            minimumSignificantDigits: precision,
            maximumSignificantDigits: precision
          }).format(value)
        }
    }
  }
  return new Intl.NumberFormat('en-US', { maximumFractionDigits: 8 }).format(
    value
  )
}
