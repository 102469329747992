<template>
  <div v-if="isGeneralNoteComponent" class="activity-general-note">
    <div class="activity-general-note__marker"></div>
    <editor-content
      class="activity-general-note__content"
      :text="component.text"
      :variable-context="variableContext"
      :view-as-student="viewAsStudent"
    />
  </div>
</template>

<script>
export default {
  name: 'ActivityGeneralNote',
  inject: ['inherited'],
  props: {
    variableContext: {
      type: Object,
      required: true
    },
    viewAsStudent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    component() {
      return this.inherited.component
    },
    isGeneralNoteComponent() {
      return this.component.componentType === 'GeneralNote'
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-general-note {
  margin: 32px 0;
  display: flex;
  align-items: stretch;

  &__marker {
    width: 21px;
    border-radius: 4px;
    background-color: $yellow;
    margin: 0 24px 0 0;
  }

  &__content {
    line-height: 2;
    flex-grow: 1;
    margin: 0;
    min-width: 0;
  }
}
</style>
