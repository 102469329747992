<template>
  <div class="oauth__layout">
    <form-button
      secondary
      class="oauth__sso"
      v-if="allowed.includes('google')"
      @click="onClick(googleUrl)"
      >{{ prefix }} Google</form-button
    >
    <form-button
      secondary
      class="oauth__sso"
      v-if="allowed.includes('azure')"
      @click="onClick(azureUrl)"
      >{{ prefix }} Microsoft</form-button
    >
    <form-button
      secondary
      class="oauth__sso"
      v-if="allowed.includes('clever')"
      @click="onClick(cleverUrl)"
      >{{ prefix }} Clever</form-button
    >
    <form-button
      secondary
      class="oauth__sso"
      v-if="allowed.includes('classLink')"
      @click="onClick(classLinkUrl)"
      >{{ prefix }} ClassLink</form-button
    >
  </div>
</template>

<script setup>
import { useFlash } from 'src/shared/hooks/flash'
import { onUnmounted } from 'vue'

const flash = useFlash()

const { protocol, host } = window.location
const googleUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&redirect_uri=${protocol}//${host}/login/oauth/google&client_id=${window.PI_GOOGLE_CLIENT_ID}&scope=email profile`
const azureUrl = `${PI_API_URL}/v3/signin-oauth/azure`
const classLinkUrl = `https://launchpad.classlink.com/oauth2/v2/auth?response_type=code&scope=full&redirect_uri=${protocol}//${host}/login/oauth/classLink&client_id=${window.PI_CLASSLINK_CLIENT_ID}`
const cleverUrl = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${protocol}//${host}/login/oauth/clever&client_id=${window.PI_CLEVER_CLIENT_ID}`

const props = defineProps({
  allowed: {
    type: Array,
    default: () => ['google', 'azure', 'clever', 'classLink']
  },
  prefix: {
    type: String
  }
})
const emit = defineEmits(['success'])
let previousUrl = null
let windowRef = null
function onClick(url) {
  window.addEventListener('message', onMessage)

  // Center the popup window
  const windowWidth = 600
  const windowHeight = 700
  const left = screen.width / 2 - windowWidth / 2
  const top = screen.height / 2 - windowHeight / 2
  const windowFeatures = `toolbar=no, menubar=no, width=${windowWidth}, height=${windowHeight}, top=${top}, left=${left}`

  if (windowRef === null || windowRef.closed) {
    windowRef = window.open(url, 'Pivot OAuth', windowFeatures)
  } else if (previousUrl !== url) {
    windowRef = window.open(url, 'Pivot OAuth', windowFeatures)
    windowRef.focus()
  } else {
    windowRef.focus()
  }

  previousUrl = url
}

function onMessage(event) {
  const { type, status, error } = event.data

  if (type !== 'pivot-oauth') return

  window.removeEventListener('message', onMessage)

  if (status === 'ok') {
    emit('success', { provider: event.data.provider })
  } else if (status === 'error') {
    if (error.body && error.body.errors) {
      flash.error(error.body.errors[0]?.message)
    } else if (error.message) {
      flash.error(error.message)
    } else {
      flash.error('Error signing in with OAuth provider.')
    }
  }
}

onUnmounted(() => {
  window.removeEventListener('message', onMessage)
  windowRef?.close()
})
</script>
<style lang="scss" scoped>
.oauth__layout {
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 7px;
}
.oauth__sso {
  width: 300px;
  text-align: center;
  margin: 0px !important;
}
</style>
