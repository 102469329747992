<template>
  <view-container>
    <view-header>
      <view-title>LTI Platforms</view-title>
      <view-header-actions>
        <button-link :to="{ name: 'lti_platform_registration' }" right>
          Add Platform
        </button-link>
      </view-header-actions>
    </view-header>
    <loading-container :loading="isLoading">
      <list :items="platforms" data-testid="platform-table">
        <list-column title="Name" property="name" />
        <list-column title="Client ID" property="clientId" />
        <list-column v-slot="platform" title="Actions">
          <router-link
            :to="{ name: 'lti_edit_platform', params: { id: platform._id } }"
          >
            Edit
          </router-link>
          |
          <form-button
            class="form-button--link"
            @click="deletePlatform(platform)"
          >
            Delete
          </form-button>
        </list-column>
      </list>
    </loading-container>
  </view-container>
</template>

<script setup>
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import { useFlash } from 'src/shared/hooks/flash'
import client from 'src/shared/api-client'
import { ref, inject } from 'vue'

const isLoading = ref(true)
const platforms = ref(undefined)
const { success } = useFlash()
const modal = inject('$modal')
async function loadData() {
  const platforms = await client.lti.getPlatforms()
  platforms.value = platforms
  isLoading.value = false
}

async function deletePlatform(platform) {
  const { status } = await modal.show(ConfirmModal, {
    text: 'Students and instructors will immediately lose access to Pivot from the platform.',
    prompt: `Are you sure you want to delete ${platform.name}?`
  })
  if (status === 'ok') {
    await client.lti.deletePlatform(platform._id)
    platforms.value = this.platforms.filter(({ _id }) => _id !== platform._id)
    success(`${platform.name} has been deleted.`)
    loadData()
  }
}

loadData()
</script>
<style lang="scss" scoped>
.lti-title {
  margin-bottom: 10px;
}

.add-platform {
  margin: auto;
  margin-top: 100px;
  width: 50%;
  height: 50%;
  padding: 20px;
  text-align: center;
}
</style>
