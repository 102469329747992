<template>
  <li v-bind="wrapperAttrs">
    <hyper-link
      v-bind="buttonAttrs"
      class="nav-link"
      :class="{ disabled: disabled }"
      :to="disabled ? '' : to"
      :disabled="disabled || null"
      @click="onClick"
    >
      <!-- @slot The content of the dropdown item. -->
      <slot />
    </hyper-link>
  </li>
</template>

<script setup>
import { computed, inject, useAttrs } from 'vue'

defineOptions({
  inheritAttrs: false,
  name: 'NavDropdownAction'
})
const attrs = useAttrs()
const emit = defineEmits(['click'])
const buttonDropdown = inject('buttonDropdown')

const props = defineProps({
  /** Disables the dropdown action. */
  disabled: {
    type: Boolean,
    default: false
  },
  to: {
    type: null,
    required: true
  }
})

const wrapperAttrs = computed(() => {
  const { style, class: klass } = attrs
  return { style, class: klass }
})
const buttonAttrs = computed(() => {
  const { style, class: _, ...rest } = attrs
  return rest
})
const onClick = e => {
  if (buttonDropdown && buttonDropdown.value) buttonDropdown.value.close()
  /** Raised when the dropdown item is clicked. */
  emit('click', e)
}
</script>

<style lang="scss" scoped>
.nav-link {
  background-color: transparent;
  border-radius: 0;
  border: none;
  color: #333333;
  display: block;
  width: 100%;
  text-align: left;
  padding: 3px 20px;
  font-weight: 400;
  line-height: 1.6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover,
  &:focus {
    color: #262626;
    background-color: #f5f5f5;
    outline: none;
  }

  &:focus {
    background-color: #f5f5f5;
    text-decoration: underline;
  }

  &:disabled {
    pointer-events: none;
    cursor: none;
    color: $silver;
  }
}
</style>
