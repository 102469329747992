<template>
  <div ref="root"></div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import useScript from 'src/shared/hooks/script'
import { usStateOptions, canProvinceOptions } from 'src/shared/utils/purchases'

const CERT_CAPTURE_URL =
  PI_ENV === 'production'
    ? 'https://certcapture.com/gencert2/js'
    : 'https://sbx.certcapture.com/gencert2/js'

const emit = defineEmits(['success'])
const props = defineProps({
  token: {
    type: String,
    required: true
  },
  region: {
    type: String,
    required: true
  },
  customer: {
    type: Object,
    required: true
  }
})

const scriptLoaded = useScript({ url: CERT_CAPTURE_URL })
const hasMounted = ref(false)
onMounted(() => {
  hasMounted.value = true
})

const root = ref()
watch(
  () => scriptLoaded.value && hasMounted.value,
  readyToStart => {
    if (readyToStart) {
      GenCert.init(root.value, {
        ship_zone: props.region,
        token: props.token,
        edit_purchaser: false,
        upload_only: true,
        onUpload() {
          emit('success')
        },
        onNotNeeded() {
          emit('success')
        }
      })
      let country = ''
      let state = ''
      if (props.customer.country === 'US') {
        country = 'United States'
        state = usStateOptions.find(
          ([code]) => code === props.customer.state
        )?.[1]
      } else if (props.customer.coutry === 'CA') {
        country = 'Canada'
        state = canProvinceOptions.find(
          ([code]) => code === props.customer.state
        )?.[1]
      }
      const customer = {
        ...props.customer,
        country,
        state
      }
      GenCert.setCustomerData(customer)
      GenCert.show()
    }
  }
)
</script>
