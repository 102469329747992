<template>
  <auth-card header="Sign Up or Login">
    <div class="card-content">
      <button-link class="button-content" :to="signupRoute">
        <icon class="button-icon" icon="fa-solid fa-user-plus" />
        Create a new Pivot Interactives account
      </button-link>
      <p>or</p>
      <button-link secondary class="button-content" :to="loginRoute">
        <icon class="button-icon" icon="fa-solid fa-right-to-bracket" />
        I have an existing Pivot Interactives account (Login)
      </button-link>
    </div>
  </auth-card>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import AuthCard from './AuthCard.vue'

const currentRoute = useRoute()
const signupRoute = computed(() => ({
  ...currentRoute,
  params: { ...currentRoute.params, step: 'create-account' }
}))
const loginRoute = computed(() => ({
  ...currentRoute,
  params: { ...currentRoute.params, step: 'login-select' }
}))
</script>
<style lang="scss" scoped>
.card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.button-content {
  height: 110px;
  width: 175px;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
}

.button-icon {
  width: 24px;
  height: 24px;
  margin: 0 auto;
  display: block;
}
.title {
  font-size: 20px;
  text-align: center;
  padding-bottom: 20px;
}
</style>
