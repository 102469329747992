import {
  GetLicenseRequest,
  GetLicenseResponse,
  GetLicenseInstructorsResponse,
  GetLicenseCommentsRequest,
  GetLicenseCommentsResponse,
  ArchiveLicenseRequest,
  GetOnboardingStatsRequest,
  GetOnboardingStatsResponse,
  GetLicenseClassesRequest,
  GetLicenseClassesResponse,
  GetExternalRosterConnectionRequest,
  GetExternalRosterConnectionResponse,
  GetStudentOverviewStatsRequest,
  GetStudentOverviewStatsResponse,
  SendHelpEmailRequest,
  GetLicenseUsageRequest,
  GetLicenseUsageResponse,
  AddCommentToLicenseRequest,
  UpdateLicenceCommentRequest,
  CancelLicenseRenewalRequest,
  LeaveLicenseRequest,
  RemoveMemberFromLicenseRequest,
  RemoveLicenseInvitationRequest,
  ResolveLicenseInvitationRequest,
  ChangeLicenseMemberRoleRequest,
  CreateExternalRosterRequest,
  CreateExternalRosterResponse,
  UpdateExternalRosterRequest,
  MergeLicensesRequest,
  SearchLicensesRequest,
  SearchLicenseResponse,
  CreateLicenseRequest,
  UpdateLicenseRequest,
  InviteMembersToLicenseRequest,
  InviteMembersToLicenseResponse,
  ScheduleLicenseRenewalRequest,
  CreateTrialLicenseRequest,
  CreateStudentLicenseRequest
} from '@pi/api-types'
import Client from './client'

export default class Licenses {
  private client: Client

  constructor(client: Client) {
    this.client = client
  }

  async get({ id }: GetLicenseRequest): Promise<GetLicenseResponse> {
    const { body } = await this.client.get<GetLicenseResponse>({
      path: `/api/v3/licenses/${id}`
    })
    return body
  }

  async create(request: CreateLicenseRequest): Promise<string> {
    const params = JSON.stringify(request)
    const { headers } = await this.client.post({
      path: `/api/v3/licenses`,
      body: params
    })
    const location = headers.get('location')
    if (!location) {
      throw new Error('Location header not found')
    }
    return location?.split('/').slice(-1)[0]
  }

  async find(search: SearchLicensesRequest): Promise<SearchLicenseResponse> {
    const { ...query } = search
    const params = new URLSearchParams()

    Object.entries(query).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(value => params.append(`${key}[]`, value.toString()))
      } else {
        params.append(key, value as string)
      }
    })
    const { body } = await this.client.get<SearchLicenseResponse>({
      path: `/api/v3/licenses?${params}`
    })
    return body
  }

  async update({ id, ...request }: UpdateLicenseRequest): Promise<void> {
    const params = JSON.stringify(request)
    await this.client.patch({
      path: `/api/v3/licenses/${id}`,
      body: params
    })
  }

  async inviteMembers({
    id,
    ...request
  }: InviteMembersToLicenseRequest): Promise<InviteMembersToLicenseResponse> {
    const params = JSON.stringify(request)
    const { body } = await this.client.post<InviteMembersToLicenseResponse>({
      path: `/api/v3/licenses/${id}/invite`,
      body: params
    })
    return body
  }

  async scheduleRenewal({
    licenseId,
    ...request
  }: ScheduleLicenseRenewalRequest): Promise<void> {
    const params = JSON.stringify(request)
    await this.client.post({
      path: `/api/v3/licenses/${licenseId}/renewals`,
      body: params
    })
  }

  async upgradeFromTrial(
    request: ScheduleLicenseRenewalRequest
  ): Promise<void> {
    const params = JSON.stringify(request)
    await this.client.post({
      path: `/api/v3/licenses/upgrade`,
      body: params
    })
  }

  async changeMemberRole({
    id,
    memberId,
    ...request
  }: ChangeLicenseMemberRoleRequest): Promise<void> {
    const params = JSON.stringify(request)
    await this.client.patch({
      path: `api/v3/licenses/${id}/members/${memberId}`,
      body: params
    })
  }

  async removeMember({
    id,
    memberId
  }: RemoveMemberFromLicenseRequest): Promise<void> {
    await this.client.delete({
      path: `/api/v3/licenses/${id}/members/${memberId}`
    })
  }

  async leave({ id }: LeaveLicenseRequest): Promise<void> {
    await this.client.post({
      path: `/api/v3/licenses/${id}/leave`
    })
  }

  async resolveInvite({
    licenseId,
    ...body
  }: ResolveLicenseInvitationRequest): Promise<void> {
    await this.client.post({
      path: `/api/v3/licenses/${licenseId}/invitation/resolve`,
      body: JSON.stringify(body)
    })
  }

  async removeInvitation({
    licenseId,
    userId
  }: RemoveLicenseInvitationRequest): Promise<void> {
    await this.client.delete({
      path: `/api/v3/licenses/${licenseId}/invitations/${userId}`
    })
  }

  async cancelRenewal({
    licenseId,
    renewalId
  }: CancelLicenseRenewalRequest): Promise<void> {
    await this.client.delete({
      path: `/api/v3/licenses/${licenseId}/renewals/${renewalId}`
    })
  }

  async getComments({
    id
  }: GetLicenseCommentsRequest): Promise<GetLicenseCommentsResponse> {
    const { body } = await this.client.get<GetLicenseCommentsResponse>({
      path: `/api/v3/licenses/${id}/comments`
    })
    return body
  }

  async addComment({
    id,
    ...request
  }: AddCommentToLicenseRequest): Promise<void> {
    const params = JSON.stringify(request)
    await this.client.post({
      path: `/api/v3/licenses/${id}/comments`,
      body: params
    })
  }

  async updateComment({
    id,
    commentId,
    ...request
  }: UpdateLicenceCommentRequest): Promise<void> {
    const params = JSON.stringify(request)
    await this.client.patch({
      path: `/api/v3/licenses/${id}/comments/${commentId}`,
      body: params
    })
  }

  async getOnboardingStats({
    id
  }: GetOnboardingStatsRequest): Promise<GetOnboardingStatsResponse> {
    const { body } = await this.client.get<GetOnboardingStatsResponse>({
      path: `/api/v3/licenses/${id}/onboarding/summary`
    })
    return body
  }

  async getStudentOverviewStats({
    id
  }: GetStudentOverviewStatsRequest): Promise<GetStudentOverviewStatsResponse> {
    const { body } = await this.client.get<GetStudentOverviewStatsResponse>({
      path: `/api/v3/licenses/${id}/usage/summary`
    })
    return body
  }

  async getUsage({
    id
  }: GetLicenseUsageRequest): Promise<GetLicenseUsageResponse> {
    const { body } = await this.client.get<GetLicenseUsageResponse>({
      path: `api/v3/licenses/${id}/usage`
    })
    return body
  }

  async archive({ id }: ArchiveLicenseRequest): Promise<void> {
    const { body } = await this.client.post({
      path: `/api/v3/licenses/${id}/archive`
    })
  }

  async getInstructors(licenseId: string) {
    const { body } = await this.client.get<GetLicenseInstructorsResponse>({
      path: `/api/v3/licenses/${licenseId}/instructors`
    })
    return body
  }

  async getClasses({
    id
  }: GetLicenseClassesRequest): Promise<GetLicenseClassesResponse> {
    const { body } = await this.client.get<GetLicenseClassesResponse>({
      path: `/api/v3/licenses/${id}/classes`
    })
    return body
  }

  async createExternalRoster({
    licenseId,
    connectionType,
    applicationId,
    districtId
  }: CreateExternalRosterRequest): Promise<void> {
    const params = JSON.stringify({
      licenseId,
      connectionType,
      applicationId,
      districtId
    })
    const { body } = await this.client.post<CreateExternalRosterResponse>({
      path: `/api/v3/licenses/${licenseId}/external-roster`,
      body: params
    })
  }

  async updateExternalRoster({
    licenseId,
    ...request
  }: UpdateExternalRosterRequest): Promise<void> {
    const params = JSON.stringify(request)
    await this.client.put({
      path: `/api/v3/licenses/${licenseId}/external-roster`,
      body: params
    })
  }

  async getExternalRosterConnection({
    licenseId
  }: GetExternalRosterConnectionRequest): Promise<GetExternalRosterConnectionResponse> {
    const { body } = await this.client.get<GetExternalRosterConnectionResponse>(
      { path: `/api/v3/licenses/${licenseId}/external-roster` }
    )
    return body
  }

  async sendHelpEmail({
    licenseId,
    memberId
  }: SendHelpEmailRequest): Promise<void> {
    await this.client.post({
      path: `/api/v3/licenses/${licenseId}/members/${memberId}/send-help`
    })
  }

  async merge({
    targetLicense,
    ...request
  }: MergeLicensesRequest): Promise<void> {
    const { body } = await this.client.post({
      path: `/api/v3/licenses/${targetLicense}/merge`,
      body: JSON.stringify(request)
    })
  }

  async createTrialLicense(request: CreateTrialLicenseRequest) {
    const response = await this.client.post({
      path: `/api/v3/licenses/trial`,
      body: JSON.stringify(request)
    })
    return response
  }
  async createStudentLicense(request: CreateStudentLicenseRequest) {
    const response = await this.client.post({
      path: `/api/v3/licenses/student-pay`,
      body: JSON.stringify(request)
    })

    return response
  }
}
