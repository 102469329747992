<template>
  <ul class="nav navbar-nav navbar-right pi-menu">
    <nav-link
      :to="{ name: 'activities', query: { library: 'pivot' } }"
      @click="closeMenu"
      >Activities</nav-link
    >

    <nav-dropdown :title="fullName || 'Welcome back!'">
      <dropdown-action
        ><nav-link :to="{ name: 'edit_profile' }" @click="closeMenu">
          Profile
        </nav-link></dropdown-action
      >
      <dropdown-action
        ><nav-link id="logout" :to="{ name: 'logout' }" @click="closeMenu">
          Log out
        </nav-link></dropdown-action
      >
    </nav-dropdown>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import NavLink from 'src/shared/components/navigation/NavLink'
import NavDropdown from 'src/shared/components/navigation/NavDropdown'

export default {
  name: 'ContentDeveloperMenu',
  components: { NavLink, NavDropdown },
  computed: {
    ...mapGetters(['fullName'])
  },
  inject: ['closeMenu']
}
</script>
