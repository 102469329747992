<template>
  <ul class="nav navbar-nav navbar-right pi-menu">
    <nav-dropdown title="How it Works">
      <nav-dropdown-action :to="{ name: 'try_an_activity' }" @click="closeMenu">
        Try An Activity
      </nav-dropdown-action>
      <nav-dropdown-action
        :to="{ name: 'educators_and_students' }"
        @click="closeMenu"
      >
        Educators & Students
      </nav-dropdown-action>
      <nav-dropdown-action :to="{ name: 'institutions' }" @click="closeMenu">
        Institutions
      </nav-dropdown-action>
    </nav-dropdown>

    <nav-link :to="{ name: 'pricing' }" @click="closeMenu"> Pricing </nav-link>
    <nav-link :to="{ name: 'about' }" @click="closeMenu"> About </nav-link>
    <nav-link :to="{ name: 'blog' }" @click="closeMenu"> Blog </nav-link>

    <nav-link
      :to="{
        name: 'login',
        params: { step: 'login-select' },
        query: { redirect: this.$route.path }
      }"
      @click="closeMenu"
    >
      Log In
    </nav-link>
    <nav-button
      :to="{
        name: 'new_license_session',
        params: { licenseType: 'trial' }
      }"
      @click="closeMenu"
    >
      Start an Instructor Trial
    </nav-button>
    <nav-button :to="{ name: 'join_class' }" @click="closeMenu">
      Join a Class
    </nav-button>
  </ul>
</template>

<script>
import NavLink from 'src/shared/components/navigation/NavLink'
import NavDropdown from 'src/shared/components/navigation/NavDropdown'
import NavButton from 'src/shared/components/navigation/NavButton'
import NavDropdownAction from 'src/shared/components/navigation/NavDropdownAction'

export default {
  name: 'GuestMenu',
  components: {
    NavLink,
    NavDropdown,
    NavButton,
    NavDropdownAction
  },
  inject: ['closeMenu']
}
</script>
