<template>
  <view-container wide>
    <view-header>
      <view-title>{{ klass?.name || 'Class' }}</view-title>
    </view-header>
    <loading-container :loading="isLoading">
      <section aria-labelledby="details-header">
        <h2 id="details-header">Details</h2>
        <async-form
          ref="classForm"
          class="form-horizontal"
          persist
          @submit="saveClass"
        >
          <form-group>
            <form-label class="col-sm-2" for="name">Name</form-label>
            <div class="col-lg-5 col-md-6 col-sm-8">
              <text-input
                v-if="!klass.fromRoster"
                id="name"
                v-model="klass.name"
                rules="required"
                label="name"
                :disabled="!isInstructor"
                @change="submitForm"
              />
              <static-value v-else aria-labelledby="name">
                {{ klass.name }}
              </static-value>
              <p
                v-if="klass.fromRoster"
                class="roster-form__rostered-service-notice"
              >
                This class was created by a rostering service. This service sets
                the class name and automatically adds and drops students to
                match the roster data from your district. If you have any
                questions, please speak with your school's IT support.
              </p>
            </div>
          </form-group>

          <form-group v-if="!klass.fromRoster">
            <form-label class="col-sm-2">Class Key</form-label>
            <div class="col-lg-5 col-md-6 col-sm-8">
              <click-to-copy-field
                aria-label="Class Key"
                :value="klass.classKey || ''"
                message="The class key has been copied."
                name="class-key"
              />
            </div>
          </form-group>
          <form-group v-if="!klass.fromRoster">
            <form-label class="col-sm-2">Join Class Link</form-label>
            <div class="col-lg-5 col-md-6 col-sm-8">
              <click-to-copy-field
                aria-label="Class Link"
                :value="classLink"
                message="A link to join this class has been copied."
                name="class-link"
              />
            </div>
          </form-group>
          <form-group v-if="!klass.fromRoster">
            <form-label class="col-sm-2" for="can-join">
              Accept New Students
            </form-label>
            <checkbox
              id="can-join"
              v-model="klass.canJoin"
              class="col-lg-5 col-md-6 col-sm-8"
              :true-value="true"
              :false-value="false"
              :disabled="!isInstructor"
              @update:modelValue="submitForm"
            />
          </form-group>

          <form-group>
            <form-label id="payment-label" class="col-sm-2">Payment</form-label>
            <static-value aria-labelledby="payment-label" class="col-sm-10">
              {{ klass.paymentSource }}
            </static-value>
          </form-group>
          <form-group>
            <form-label id="level-label" class="col-sm-2">Level</form-label>
            <static-value aria-labelledby="level-label" class="col-sm-10">
              {{ klass.level }}
            </static-value>
          </form-group>
          <form-group v-if="!klass.fromRoster">
            <div class="roster-form__end-date-label col-sm-2">
              <form-label for="endDate">End Date </form-label
              ><help-popover
                >All students will automatically be dropped from this class 30
                days after the end date of this course.</help-popover
              >
            </div>
            <date-input
              id="endDate"
              :min-date="minEndDate"
              :max-date="maxEndDate"
              v-model="newEndDate"
              label="end date"
              class="col-sm-10 roster-form__end-date-container"
              :clearable="false"
            ></date-input>
          </form-group>
          <form-group v-if="!klass.fromRoster">
            <div class="roster-form__drop-date-label col-sm-2">
              <form-label for="dropDate">Autodrop Date</form-label>

              <help-popover
                >On this date, all students will automatically be dropped from
                the class. To restore students to the class after the end date,
                you will need to change the end date of the class to a date in
                the future.</help-popover
              >
            </div>
            <div class="roster-form__past-drop-date-display col-sm-10">
              <static-value
                id="dropDate"
                class="roster-form__drop-date-display"
                v-if="dropDate"
                >{{ $format.date(dropDate, 'MM/dd/yyyy') }}</static-value
              >
            </div>
          </form-group>
        </async-form>
      </section>
      <div
        v-if="!endDateInFuture && !klass.fromRoster"
        class="end-date-warning"
      >
        <p class="drop-paragraph">
          <icon icon="triangle-exclamation" class="warning-icon" /> This class
          has passed its end date of {{ endDateDisplay }}. All students
          {{ dropDateInFuture ? 'will be' : 'were' }} automatically dropped on
          {{ $format.date(dropDate, 'MM/dd/yyyy') }}.
        </p>
        <p class="delete-paragraph">
          All data for this class
          {{ dropDataDateInFuture ? 'will be' : 'was' }} deleted from dropped
          students 180 days after a student
          {{ dropDataDateInFuture ? 'is' : 'was' }} dropped from the class.
          {{
            dropDataDateInFuture
              ? 'To restore students that have been automatically dropped, change the end date of the class.'
              : 'Student data cannot be recovered once it is deleted.'
          }}
        </p>
      </div>
      <section aria-labelledby="graders-header">
        <div class="graders-header">
          <h2 id="graders-header">Instructors</h2>
          <form-button v-if="isInstructor" @click="addInstructor"
            >Add Instructor</form-button
          >
        </div>
        <p v-if="(klass.instructors || []).length === 0">
          No instructors have been added to this class yet.
        </p>
        <list v-else :items="klass.instructors" aria-label="Graders">
          <list-column v-slot="{ firstName, lastName }" title="Name">
            {{ firstName }} {{ lastName }}
          </list-column>
          <list-column v-slot="{ email }" title="Email">
            <a :href="`mailto:${email}`">{{ email }}</a>
          </list-column>
          <list-column v-slot="{ role }" title="Role">
            {{ role }}
          </list-column>
          <list-column v-if="isInstructor" v-slot="grader" title="Actions">
            <form-button link @click.stop="removeInstructor(grader)">
              Remove
            </form-button>
          </list-column>
        </list>
      </section>
      <section aria-labelledby="students-header">
        <h2 id="students-header">Students</h2>
        <div v-if="isInstructor" class="student-roster__header">
          <div>
            <p
              v-if="(klass.roster || []).length === 0"
              class="student-roster__dropped-notice-paragraph"
            >
              No students have joined this class yet.
            </p>
            <template v-else>
              <p class="student-roster__dropped-notice-paragraph">
                Students will be automatically dropped 30 days after the class
                ends.
              </p>
              <p class="student-roster__dropped-notice-paragraph">
                To change the auto-drop date for students, change the end date
                of the class.
              </p>
            </template>
          </div>
          <div class="student-roster__header-actions">
            <label for="bulkActionSelect">Bulk Actions</label>
            <select-field
              id="bulkActionSelect"
              v-model="bulkStudentAction"
              :disabled="!allowStudentBulkActions"
              class="student-roster__header-actions--select"
            >
              <option value="">Select Action</option>
              <option value="drop">Drop Now</option>
              <option value="transfer">Transfer</option>
            </select-field>
            <form-button
              secondary
              @click="bulkStudentActionSubmit"
              :disabled="!allowStudentBulkActions"
              >Apply</form-button
            >
          </div>
        </div>

        <list
          v-if="(klass.roster || []).length !== 0"
          :items="klass.roster"
          aria-label="Students"
        >
          <list-column v-if="isInstructor" title="bulkActions">
            <template v-slot:title>
              <checkbox
                v-model="allStudentsSelected"
                :indeterminate="someStudentsSelected"
              />
            </template>
            <template v-slot="{ student }">
              <checkbox v-model="selectedStudents" :value="student._id" />
            </template>
          </list-column>
          <list-column v-slot="{ student }" title="Name">
            <router-link
              :to="{
                name: 'student_class_assignments',
                params: { classId: klass.id, studentId: student._id }
              }"
            >
              {{ student.firstName }} {{ student.lastName }}
            </router-link>
          </list-column>
          <list-column v-slot="{ student }" title="Email">
            <a :href="`mailto:${student.email}`">{{ student.email }}</a>
          </list-column>
          <list-column v-slot="{ source }" title="Source">
            {{ source }}
          </list-column>
          <list-column
            v-if="klass.paymentSource === 'student'"
            v-slot="{ expirationDate }"
            title="Expiration"
          >
            <template v-if="expirationDate">
              {{ $format.date(expirationDate, 'MM/dd/yyyy') }}
            </template>
          </list-column>
          <list-column
            v-if="isInstructor && !klass.fromRoster"
            v-slot="{ student }"
            title="Actions"
          >
            <form-button link @click.stop="dropStudent(student)">
              Drop Now
            </form-button>
            |
            <form-button link @click.stop="transferStudent(student)">
              Transfer
            </form-button>
            |
            <form-button link @click.stop="updateStudent(student)">
              Update Profile
            </form-button>
          </list-column>
        </list>
      </section>
      <section aria-labelledby="dropped-students">
        <h2 id="dropped-students">Dropped Students</h2>
        <div v-if="isInstructor" class="student-roster__header">
          <div>
            <p
              class="student-roster__dropped-notice-paragraph"
              v-if="
                !endDateInFuture &&
                klass?.droppedStudents?.length &&
                !klass.fromRoster
              "
            >
              Because the end date for this class has passed, you cannot undrop
              these students. To see the work of these students, click on their
              name.
            </p>
            <p
              v-if="klass?.droppedStudents?.length === 0"
              class="student-roster__dropped-notice-paragraph"
            >
              There are no dropped students in this class.
            </p>
            <p
              v-else-if="!klass.fromRoster"
              class="student-roster__dropped-notice-paragraph"
            >
              All data for this class will be deleted from dropped students 180
              days after a student is dropped from the class. To restore
              students that have been automatically dropped, change the end date
              of the class.
            </p>
          </div>
          <div class="student-roster__header-actions">
            <label for="bulkDroppedStudentActionSelect">Bulk Actions</label>
            <select-field
              id="bulkDroppedStudentActionSelect"
              v-model="bulkDroppedStudentAction"
              :disabled="!allowDroppedStudentBulkActions"
              class="student-roster__header-actions--select"
            >
              <option value="">Select Action</option>
              <option value="restore">Restore</option>
              <option value="transfer">Transfer</option>
            </select-field>
            <form-button
              secondary
              @click="bulkDroppedStudentActionSubmit"
              :disabled="!allowDroppedStudentBulkActions"
              >Apply</form-button
            >
          </div>
        </div>
        <list
          v-if="klass?.droppedStudents?.length"
          :items="klass.droppedStudents"
          aria-label="Dropped Students"
        >
          <list-column v-if="isInstructor" title="bulkActions">
            <template v-slot:title>
              <checkbox
                v-model="allDroppedStudentsSelected"
                :indeterminate="someDroppedStudentsSelected"
              />
            </template>
            <template v-slot="{ student }">
              <checkbox
                v-model="selectedDroppedStudents"
                :value="student._id"
              />
            </template>
          </list-column>
          <list-column v-slot="{ student }" title="Name">
            <router-link
              :to="{
                name: 'student_class_assignments',
                params: { classId: klass.id, studentId: student._id }
              }"
            >
              {{ student.firstName }} {{ student.lastName }}
            </router-link>
          </list-column>
          <list-column v-slot="{ student }" title="Dropped By">
            {{ student.actor }}
          </list-column>
          <list-column v-slot="{ student }" title="Source">
            {{ student.source }}
          </list-column>
          <list-column v-slot="{ student }" title="Email">
            <a :href="`mailto:${student.email}`">{{ student.email }}</a>
          </list-column>
          <list-column v-slot="{ student }" title="Dropped On:">
            {{ $format.date(student.dateDropped, 'MM/dd/yyyy') }}
          </list-column>
          <list-column v-slot="{ student }" title="Delete Data On:">
            {{ $format.date(dropDataDate(student.dateDropped), 'MM/dd/yyyy') }}
          </list-column>
          <list-column
            v-if="isInstructor && !klass.fromRoster"
            v-slot="{ student }"
            title="Actions"
          >
            <div>
              <form-button
                v-if="endDateInFuture"
                class="form-button--link"
                @click="restoreStudent(student)"
              >
                Restore
              </form-button>
              <span v-if="endDateInFuture"> | </span>
              <form-button link @click.stop="transferStudent(student)">
                Transfer
              </form-button>
            </div>
          </list-column>
        </list>
      </section>
    </loading-container>
  </view-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import client from 'src/shared/api-client'
import UpdateStudentProfileModal from 'src/shared/components/modals/UpdateStudentProfileModal'
import AddInstructorModal from 'src/shared/components/modals/AddInstructorModal'
import AddGraderModal from 'src/shared/components/modals/AddGraderModal'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import TransferStudentModal from 'src/shared/components/modals/TransferStudentModal'
import ClickToCopyField from 'src/shared/components/ClickToCopyField'
import { addProtocolAndHost } from 'src/shared/utils/environment-tools'
import { addYears, endOfDay, isAfter, addDays } from 'date-fns'

export default {
  name: 'TeacherClassView',
  components: {
    ClickToCopyField
  },
  inject: ['$modal'],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      klass: undefined,
      password: '',
      isLoading: true,
      selectedStudents: [],
      selectedDroppedStudents: [],
      bulkStudentAction: '',
      bulkDroppedStudentAction: '',
      graders: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    classLink() {
      return addProtocolAndHost(`join-class?classKey=${this.klass.classKey}`)
    },
    endDateDisplay() {
      return this.klass.endDate
        ? new Date(this.klass.endDate).toLocaleDateString()
        : null
    },
    allowStudentBulkActions() {
      return this.selectedStudents.length > 0
    },
    allowDroppedStudentBulkActions() {
      return this.selectedDroppedStudents.length > 0
    },
    minEndDate() {
      return new Date()
    },
    maxEndDate() {
      return addYears(this.minEndDate, 1)
    },
    endDateInFuture() {
      return (
        !!this.klass.endDate &&
        isAfter(endOfDay(new Date(this.klass.endDate)), new Date())
      )
    },
    dropDate() {
      return addDays(new Date(this.klass.endDate), 30)
    },
    dropDateInFuture() {
      return isAfter(endOfDay(this.dropDate), new Date())
    },
    dropDataDateInFuture() {
      return isAfter(
        endOfDay(this.dropDataDate(this.klass.endDate)),
        new Date()
      )
    },
    isInstructor() {
      return this.klass.instructors.some(
        instructor =>
          instructor.role === 'Instructor' && instructor._id === this.user.id
      )
    },
    allStudentsSelected: {
      get() {
        return this.selectedStudents.length === this.klass.roster.length
      },
      set(isSelected) {
        if (isSelected) {
          this.selectedStudents = this.klass.roster.map(s => s.student._id)
        } else {
          this.selectedStudents = []
        }
      }
    },
    someStudentsSelected() {
      return this.selectedStudents.length > 0 && !this.allStudentsSelected
    },
    allDroppedStudentsSelected: {
      get() {
        return (
          this.selectedDroppedStudents.length ===
          this.klass.droppedStudents?.length
        )
      },
      set(isSelected) {
        if (isSelected) {
          this.selectedDroppedStudents = this.klass.droppedStudents.map(
            s => s.student._id
          )
        } else {
          this.selectedDroppedStudents = []
        }
      }
    },

    someDroppedStudentsSelected() {
      return (
        this.selectedDroppedStudents.length > 0 &&
        !this.allDroppedStudentsSelected
      )
    },

    newEndDate: {
      get() {
        return this.klass?.endDate
      },
      async set(date) {
        this.changingEndDate = false
        await client.classes.update({ id: this.id, endDate: date })
        await this.loadClass()
        this.$success(`Class end date updated successfully.`)
      }
    }
  },
  methods: {
    ...mapActions(['refreshAuth']),
    async loadClass() {
      const body = await client.classes.get({ classId: this.id })
      this.klass = body
      this.isLoading = false
      if (this.$route.hash) {
        this.$nextTick().then(() =>
          document
            .getElementById(this.$route.hash.replace('#', ''))
            .scrollIntoView()
        )
      }
    },
    submitForm() {
      this.$refs.classForm.submit()
    },
    async saveClass(e) {
      try {
        await client.classes.update({
          id: this.id,
          name: this.klass.name,
          canJoin: this.klass.canJoin
        })
        this.$success(`Class updated successfully.`)
        e.done && e.done()
      } catch (error) {
        e.done && e.done(false)
        throw error
      }
    },
    dropDataDate(dropDate) {
      return addDays(new Date(dropDate), 180)
    },
    async dropStudent(student) {
      try {
        await client.classes.dropStudents({
          classId: this.id,
          studentIds: [student._id]
        })
        this.$success(
          `${student.firstName} ${student.lastName} was dropped from the class.`
        )
        await Promise.all([this.refreshAuth(), this.loadClass()])
      } catch (error) {
        if (error.body.operations) {
          this.$error(error.body.operations[0].error)
        } else {
          throw error
        }
      }
    },
    async restoreStudent(student) {
      try {
        await client.classes.restoreStudents({
          classId: this.id,
          studentIds: [student._id]
        })
        this.$success(
          `${student.firstName} ${student.lastName} was restored to the class.`
        )
        await Promise.all([this.refreshAuth(), this.loadClass()])
      } catch (error) {
        if (error.body.operations) {
          this.$error(error.body.operations[0].error)
        } else {
          throw error
        }
      }
    },
    async updateStudent(student) {
      const { status, data } = await this.$modal.show(
        UpdateStudentProfileModal,
        {
          student
        }
      )
      if (status === 'ok') {
        student.firstName = data.firstName
        student.lastName = data.lastName
        student.email = data.email
      }
    },
    async addInstructor() {
      const result = await this.$modal.show(AddInstructorModal, {
        classId: this.id,
        licenseId: this.klass.license,
        existingInstructors: this.klass.instructors
      })
      if (result.status === 'ok') {
        const { instructors } = await client.classes.getInstructors({
          classId: this.id
        })
        this.klass.instructors = instructors
      }
    },
    async removeInstructor(instructor) {
      const isSelf = instructor._id === this.user.id

      const result = await this.$modal.show(ConfirmModal, {
        text: isSelf
          ? 'You will not be able to view the class roster or grade assignments.'
          : 'This user will not be able to view the class roster or grade assignments.',
        prompt: isSelf
          ? 'Are you sure you want to remove yourself from this class?'
          : `Are you sure you want to remove ${
              instructor.firstName || 'this user'
            } as a instructor?`
      })
      if (result.status === 'ok') {
        await client.classes.removeInstructor({
          classId: this.id,
          instructorId: instructor._id
        })
        if (instructor._id === this.user._id) {
          this.$router.push({ name: 'classes' })
        } else {
          const { instructors } = await client.classes.getInstructors({
            classId: this.id
          })
          this.klass.instructors = instructors
        }
      }
    },
    async loadGraders() {
      const { instructors } = await client.classes.getInstructors({
        classId: this.id
      })
      this.graders = instructors.filter(i => i.role === 'Grader')
    },
    async addGrader() {
      const result = await this.$modal.show(AddGraderModal, {
        classId: this.id
      })
      if (result.status === 'ok') {
        this.loadGraders()
      }
    },
    async removeGrader(grader) {
      const result = await this.$modal.show(ConfirmModal, {
        text: 'This user will not be able to view the class roster or grade assignments.',
        prompt: `Are you sure you want to remove ${
          grader.firstName || 'this user'
        } as a grader?`
      })
      if (result.status === 'ok') {
        await client.classes.removeGrader({
          classId: this.id,
          graderId: grader._id
        })
        if (grader._id === this.user._id) {
          this.$router.push({ name: 'classes' })
        } else {
          this.loadGraders()
        }
      }
    },
    async transferStudent(student) {
      const { status, data } = await this.$modal.show(TransferStudentModal, {
        students: [student],
        currentClassName: this.klass.name
      })
      if (status === 'ok') {
        try {
          await client.classes.transferStudents({
            sourceClassId: this.id,
            targetClassKey: data.classKey,
            studentIds: [student._id]
          })
          this.$success(
            `${student.firstName} ${student.lastName} was successfully transferred to ${data.className}.`
          )
        } catch (error) {
          if (error.body.operations) {
            this.$error(error.body.operations[0].error)
          } else {
            throw error
          }
        }
        await this.loadClass()
      }
    },

    async bulkStudentActionSubmit() {
      const selectedStudents = this.klass.roster
        .filter(s => this.selectedStudents.includes(s.student._id))
        .map(s => s.student)

      if (this.bulkStudentAction === '') return

      if (this.bulkStudentAction === 'drop') {
        const studentListHTML = `<p>The following ${
          this.selectedStudents.length === 1 ? 'student' : 'students'
        } will be dropped from the class:</p>
        <ul>
          ${selectedStudents
            .map(student => `<li>${student.firstName} ${student.lastName}</li>`)
            .join('')}
          </ul>
          `
        const { status } = await this.$modal.show(ConfirmModal, {
          html: studentListHTML,
          prompt:
            this.selectedStudents.length === 1
              ? `Are you sure you wish to drop this student from your class?`
              : `Are you sure you want to drop ${this.selectedStudents.length} students from this class?`
        })
        if (status === 'ok') {
          try {
            await client.classes.dropStudents({
              classId: this.id,
              studentIds: this.selectedStudents
            })
            this.$success(
              `${this.selectedStudents.length} ${
                this.selectedStudents.length === 1
                  ? 'student was'
                  : 'students were'
              } dropped from the class.`
            )
          } catch (error) {
            if (error.body.operations) {
              const errorMessage = selectedStudents
                .filter(student =>
                  error.body.operations.find(
                    operation => operation.id === student._id
                  )
                )
                .map(student => {
                  return {
                    name: `${student.firstName} ${student.lastName}`,
                    error: error.body.operations.find(
                      operation => operation.id === student._id
                    ).error
                  }
                })
                .map(({ name, error }) => {
                  return `${name}: ${error}`
                })
                .join(`\n`)

              this.$error(
                `${error.operations.length} student(s) failed to drop from the class:\n${errorMessage}`
              )
            } else {
              throw error
            }
          }

          await Promise.all([this.refreshAuth(), this.loadClass()])
        }
      }

      if (this.bulkStudentAction === 'transfer') {
        const { status, data } = await this.$modal.show(TransferStudentModal, {
          students: selectedStudents,
          currentClassName: this.klass.name
        })
        if (status === 'ok') {
          try {
            await client.classes.transferStudents({
              sourceClassId: this.id,
              targetClassKey: data.classKey,
              studentIds: this.selectedStudents
            })

            this.$success(
              `${this.selectedStudents.length} ${
                this.selectedStudents.length === 1
                  ? 'student was'
                  : 'students were'
              } transferred to ${data.className}.`
            )
          } catch (error) {
            if (error.body?.operations) {
              const errorMessage = selectedStudents
                .filter(student =>
                  error.body.operations.find(
                    operation => operation.id === student._id
                  )
                )
                .map(student => {
                  return {
                    name: `${student.firstName} ${student.lastName}`,
                    error: error.body.operations.find(
                      operation => operation.id === student._id
                    ).error
                  }
                })
                .map(({ name, error }) => {
                  return `${name}: ${error}`
                })
                .join(`\n`)
              this.$error(
                `${error.operations.length} student(s) failed to transfer:\n${errorMessage}`
              )
            } else {
              throw error
            }
          }
          await Promise.all([this.refreshAuth(), this.loadClass()])
        }
      }
    },
    async bulkDroppedStudentActionSubmit() {
      const selectedDroppedStudents = this.klass.droppedStudents
        .filter(s => this.selectedDroppedStudents.includes(s.student._id))
        .map(s => s.student)

      if (this.bulkDroppedStudentAction === '') return

      if (this.bulkDroppedStudentAction === 'restore') {
        try {
          await client.classes.restoreStudents({
            classId: this.id,
            studentIds: this.selectedDroppedStudents
          })
          this.$success(
            `${this.selectedDroppedStudents.length} ${
              this.selectedDroppedStudents.length === 1
                ? 'student was'
                : 'students were'
            } restored to this class.`
          )
        } catch (error) {
          if (error.body?.operations) {
            const errorMessage = selectedDroppedStudents
              .filter(student =>
                error.body.operations.find(
                  operation => operation.id === student._id
                )
              )
              .map(student => {
                return {
                  name: `${student.firstName} ${student.lastName}`,
                  error: error.body.operations.find(
                    operation => operation.id === student._id
                  ).error
                }
              })
              .map(({ name, error }) => {
                return `${name}: ${error}`
              })
              .join(`\n`)
            this.$error(
              `${error.operations.length} student(s) failed to restore to this class:\n${errorMessage}`
            )
          } else {
            throw error
          }
        }

        await Promise.all([this.refreshAuth(), this.loadClass()])
      }

      if (this.bulkDroppedStudentAction === 'transfer') {
        const { status, data } = await this.$modal.show(TransferStudentModal, {
          students: selectedDroppedStudents,
          currentClassName: this.klass.name
        })
        if (status === 'ok') {
          try {
            await client.classes.transferStudents({
              sourceClassId: this.id,
              targetClassKey: data.classKey,
              studentIds: this.selectedDroppedStudents
            })

            this.$success(
              `${this.selectedDroppedStudents.length} ${
                this.selectedDroppedStudents.length === 1
                  ? 'student was'
                  : 'students were'
              } transferred to ${data.className}.`
            )
          } catch (error) {
            if (error.body?.operations) {
              const errorMessage = selectedDroppedStudents
                .filter(student =>
                  error.body.operations.find(
                    operation => operation.id === student._id
                  )
                )
                .map(student => {
                  return {
                    name: `${student.firstName} ${student.lastName}`,
                    error: error.body.operations.find(
                      operation => operation.id === student._id
                    ).error
                  }
                })
                .map(({ name, error }) => {
                  return `${name}: ${error}`
                })
                .join(`\n`)
              this.$error(
                `${error.operations.length} student(s) failed to transfer:\n${errorMessage}`
              )
            } else {
              throw error
            }
          }
          this.selectedDroppedStudents = []
          await Promise.all([this.refreshAuth(), this.loadClass()])
        }
      }
    }
  },
  watch: {
    id: {
      async handler() {
        await Promise.all([this.loadClass(), this.loadGraders()])
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.graders-header {
  display: flex;
  align-items: center;

  h2 {
    flex-grow: 1;
  }
}

.roster-form__end-date-container {
  display: flex;
  align-items: baseline;
}

.roster-form__end-date-label,
.roster-form__drop-date-label {
  text-align: end;
}

.roster-form__end-date-info {
  margin-left: 5px;
}

.roster-form__change-end-date-button {
  margin-left: 5px;
  align-self: center;
  padding: 8px 0;
}

.roster-form__past-end-date-display,
.roster-form__past-drop-date-display {
  display: flex;
}

.student-roster__header {
  display: flex;
  justify-content: space-between;
}

.student-roster__dropped-notice-paragraph {
  margin: 0px;
}

.student-roster__header-actions {
  width: 350px;
  flex-shrink: 0;
  margin-left: 16px;

  label {
    font-weight: bold;
  }

  &--select {
    margin: 0 10px;
    display: inline-flex;
    width: 180px;
  }
}

.roster-form__rostered-service-notice {
  font-style: italic;
}

.end-date-warning {
  background-color: $color-warning;
  color: #fff;
  padding: 20px 20px;
}

.drop-paragraph {
  font-weight: 700;
}
</style>
