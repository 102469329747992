/* eslint-disable camelcase */
import { configure, defineRule, validate } from 'vee-validate'
import * as rules from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'
import en from '@vee-validate/i18n/dist/locale/en.json'
import { substituteVariablesMathjs } from 'src/shared/utils/activity-variables'
import math from 'src/setup/math'
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping'
import format from 'date-fns/format'

export default function () {
  // setInteractionMode('aggressive')
  Object.keys(rules).forEach(rule => {
    defineRule(rule, rules[rule])
  })

  configure({
    generateMessage: localize({ en })
  })

  defineRule(
    'url',
    (
      value,
      { require_protocol, protocols = ['http', 'https'], host_whitelist },
      ctx
    ) => {
      if (!value) return true
      const protocolStr = `((${protocols.join('|')}):\\/\\/)${
        require_protocol ? '' : '?'
      }`
      const domainStr = host_whitelist
        ? host_whitelist.map(host => host.split('.').join('\\.')).join('|')
        : '(([\\w-])+\\.)+([a-zA-Z]{2,63})'
      const pathQueryStr = '([/\\w-]*)*\\/?\\??([^#\\n\\r]*)?#?([^\\n\\r]*)'
      const regex = new RegExp(`${protocolStr}${domainStr}${pathQueryStr}`, 'i')
      return (
        (typeof value === 'string' && regex.test(value)) ||
        `The ${ctx.field} field is not a valid URL`
      )
    }
  )

  defineRule('emailPerLine', async (value, _, ctx) => {
    if (!value) return true
    const results = await Promise.all(
      value
        .trim()
        .split('\n')
        .map(line => validate(line, 'required|email'))
    )
    return (
      results.every(({ valid }) => valid) ||
      `The ${ctx.field} field must contain one valid email address per line`
    )
  })

  defineRule('json', (value, _, ctx) => {
    if (!value) return true
    try {
      JSON.parse(value)
      return true
    } catch {
      return `The ${ctx.field} field must be valid JSON`
    }
  })

  defineRule('urlPerLine', async (value, args, ctx) => {
    if (!value) return true
    const results = await Promise.all(
      value
        .split('\n')
        .map(line => validate(line, { required: true, url: args }))
    )
    return (
      results.every(({ valid }) => valid) ||
      `The ${ctx.field} field must contain one valid URL per line`
    )
  })

  function validateMathjsExpression(expr, variables) {
    if (!expr) return true
    try {
      const replaced = substituteVariablesMathjs(expr, variables, 'name', true)
      const expression = math.parse(replaced)

      const symbols = expression.filter(
        node => node.isSymbolNode && !(node.name in math)
      )
      const accesses = expression.filter(
        node =>
          node.isAccessorNode && symbols.some(symbol => symbol === node.object)
      )
      const areSymbolsValid = symbols.every(node => {
        return variables.some(v => `$${v.id}` === node.name)
      })
      const inValidSymbols = symbols.filter(node => {
        return !variables.some(v => `$${v.id}` === node.name)
      })
      const areAccessesValid = accesses.every(node => {
        const collection = variables.find(v => `$${v.id}` === node.object.name)

        return collection.variables.some(
          v => `$${v.id}` === node.index.dimensions[0].value
        )
      })
      return {
        isValid: areSymbolsValid && areAccessesValid,
        symbols: inValidSymbols
      }
    } catch (err) {
      return false
    }
  }

  defineRule('mathjs', (value, { variables }, ctx) => {
    const validated = validateMathjsExpression(value, variables)
    if (validated.isValid) return true
    const variableExpr = validated.symbols?.map(s => s.name).join(', ') ?? false
    const isMultiple = validated.symbols?.length > 1
    return variableExpr
      ? isMultiple
        ? `These ${variableExpr} variable's are' unrecognized. Please check that they matche the name(s) of a variable in the variables menu`
        : `The ${variableExpr} variable is unrecognized. Please check that it matches the name of a variable in the variables menu`
      : `The ${ctx.field} field must contain a valid mathjs expression`
  })

  defineRule('undefinedVariables', value => {
    if (!value) return true
    try {
      if (value.includes('_UND')) {
        return `Undefined variables need to be reconnected before the activity can be published`
      }
      if (value.includes('[var:UNKNOWN]')) {
        return 'There are unrecognized variables. Please check that they match the name of a variable in the variables menu'
      }
      return true
    } catch (err) {
      return `Undefined variables need to be reconnected before the activity can be published`
    }
  })

  defineRule('postalCode', (value, { country }) => {
    if (!value) return true
    if (country === 'US') {
      return (
        /^[0-9]{5}(?:-[0-9]{4})?$/.test(value) ||
        'Postal code format is invalid.'
      )
    } else if (country === 'CA') {
      return (
        /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(value) ||
        'Postal code format is invalid.'
      )
    }
    return true
  })

  defineRule('after', (value, { date }, ctx) => {
    if (!value) return true
    return (
      !(value instanceof Date) ||
      !(date instanceof Date) ||
      value.getTime() >= date.getTime() ||
      `${ctx.field} must be after ${format(date, 'MM/dd/yyyy')}.`
    )
  })

  defineRule('before', (value, { date }, ctx) => {
    if (!value) return true
    return (
      !(value instanceof Date) ||
      !(date instanceof Date) ||
      value.getTime() <= date.getTime() ||
      `${ctx.field} must be before ${format(date, 'MM/dd/yyyy')}.`
    )
  })

  defineRule('overlaps', (value, { start, end, ranges }, ctx) => {
    if (!value) return true
    if (start) end = value
    else start = value
    return (
      !start ||
      !end ||
      start > end ||
      ranges.every(
        range =>
          !areIntervalsOverlapping(
            { start, end },
            { start: range[0], end: range[1] }
          )
      ) ||
      `${ctx.field} must not overlap with another range.`
    )
  })

  defineRule('schoolDomain', value => {
    if (!value) return true
    if (
      typeof value === 'string' &&
      (value.endsWith('gmail.com') || value.endsWith('yahoo.com'))
    ) {
      return `Please enter your school's email domain`
    }
    return (
      /^[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value) ||
      `Please enter your school's email domain`
    )
  })

  defineRule('password', (value, { isStudent }, ctx) => {
    if (PI_ENV !== 'production' || !value) {
      return true
    }

    if (isStudent) {
      return value.length > 7 || 'Your password must be at least 8 characters'
    }

    return (
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
        value
      ) ||
      `Your password must have at least 8 characters, a mixture of upper and lowercase letters, and at least one number and special character.`
    )
  })

  defineRule('phetioValue', (value, { variables }, ctx) => {
    if (!value) return true

    // If value starts with `{` validate as JSON with nested mathjs expressions.
    // Otherwise assume it's just a mathjs expression
    if (value.startsWith('{')) {
      try {
        // Validate JSON first, then run reviver function for each
        // value in the JSON and check if it's a mathjs expression.
        // Nested mathjs expressions are notated with `mathjs(expression)`
        JSON.parse(value, (_, val) => {
          if (typeof val === 'string' && val.startsWith('mathjs(')) {
            if (!val.endsWith(')')) {
              throw new Error('mathjs')
            }

            if (
              !validateMathjsExpression(
                val.substring(7, val.length - 1),
                variables
              ).isValid
            ) {
              throw new Error('mathjs')
            }
          }

          return val
        })

        return true
      } catch (error) {
        if (error.message === 'mathjs') {
          return `The ${ctx.field} field must contain a valid mathjs expression`
        }

        return `The ${ctx.field} field must be valid JSON`
      }
    } else {
      return (
        validateMathjsExpression(value, variables).isValid ||
        `The ${ctx.field} field must contain a valid mathjs expression`
      )
    }
  })
}
