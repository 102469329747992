<template>
  <view-container narrow class="profile-page">
    <view-header>
      <view-title>Profile</view-title>
    </view-header>
    <loading-container :loading="isLoading">
      <section>
        <section-header>
          <section-title>Personal Information</section-title>
        </section-header>
        <async-form class="form-two-column" @submit="doUpdateUser" persist>
          <form-group>
            <form-label for="firstName"> First name </form-label>
            <text-input
              id="firstName"
              v-model="firstName"
              :rules="{ required: !isAdmin }"
              label="First name"
              autocomplete="given-name"
            />
          </form-group>
          <form-group>
            <form-label for="lastName"> Last name </form-label>
            <text-input
              id="lastName"
              v-model="lastName"
              :rules="{ required: !isAdmin }"
              label="Last name"
              autocomplete="family-name"
            />
          </form-group>
          <form-group>
            <form-label for="email"> Email </form-label>
            <text-input
              id="email"
              v-model="email"
              :rules="{ required: !!password, email: true }"
              label="Email"
              autocomplete="email"
              :disabled="!canUpdateProfile"
              :help-text="
                canUpdateProfile
                  ? undefined
                  : 'Your email is set by your SSO provider.'
              "
            />
          </form-group>

          <form-group v-if="editingTeacherProfile">
            <form-label> Institution Roles </form-label>

            <institution-roles-choices
              v-model="institutionRoles"
              aria-label="Institution Roles"
            />
          </form-group>

          <form-group v-if="editingTeacherProfile">
            <form-label> Subjects </form-label>
            <primary-subjects-choices
              v-model="primarySubjects"
              aria-label="Primary Subjects"
            />
          </form-group>

          <template v-if="canUpdateProfile">
            <form-group>
              <form-label for="password"> New Password </form-label>
              <div>
                <text-input
                  id="password"
                  v-model="password"
                  label="password"
                  type="password"
                  :rules="{ password: { isStudent } }"
                  autocomplete="new-password"
                  :disabled="!canUpdateProfile"
                />
              </div>
            </form-group>
            <form-group>
              <form-label for="confirmPassword"> Confirm password </form-label>
              <div>
                <text-input
                  id="confirmPassword"
                  v-model="confirmPassword"
                  :rules="{ required: !!password, is: password }"
                  label="Confirm Password"
                  type="password"
                  autocomplete="new-password"
                  :disabled="!canUpdateProfile"
                />
              </div>
            </form-group>
          </template>
          <form-group>
            <form-label for="UserId">User Id</form-label>
            <click-to-copy-field
              id="userId"
              aria-label="User Id"
              :value="user.id"
              class="userId__field"
            />
          </form-group>
          <form-group variant="right-aligned">
            <submit-button>
              <template #default>Save</template>
              <template #submitting>Saving</template>
              <template #submitted>Saved</template>
            </submit-button>
          </form-group>
        </async-form>
      </section>
      <section v-if="canManageMFA">
        <section-header>
          <section-title>Multifactor Authentication</section-title>
        </section-header>
        <template v-if="user.hasMFA">
          <p>
            You have already configured multi-factor authentication for your
            account. You can invalidate your current multi-factor device and
            reconfigure if needed.
          </p>
          <form-button destructive @click="reconfigureMFA">
            Reconfigure MFA
          </form-button>
        </template>
        <template v-else>
          <p>
            Consider configuring multifactor authentication for added security
            on your account.
          </p>
          <form-button @click="configureMFA"> Configure MFA </form-button>
        </template>
      </section>
      <section v-if="isTeacher" id="license" class="license-info">
        <div class="current-license">
          <section-header>
            <section-title>License &amp; Status</section-title>
          </section-header>
          <div v-if="license">
            <ul class="details">
              <li>
                Current License:
                <span class="license-name">{{ license.name }}</span>
              </li>
              <li>
                License Type:
                <span class="license-type">{{ license.type }} License</span>
              </li>
              <li v-if="license.endDate">
                Expires:
                <span class="license-expiration">
                  {{ $format.date(license.endDate, 'MM-dd-yyyy') }}</span
                >
              </li>
            </ul>
            <div class="leave-license">
              <button-link
                v-if="isTrialTeacher"
                class="center-block"
                :to="{ name: 'new_upgrade_session' }"
              >
                Upgrade Now
              </button-link>
              <form-button
                v-if="!isTrialTeacher && canLeaveCurrentLicense"
                destructive
                @click="leaveLicense"
              >
                Leave License
              </form-button>
            </div>
          </div>
          <div v-else>
            <ul class="details">
              <li>
                Current License:
                <span v-if="isTrialTeacher" class="license-name"> Trial </span>
                <span v-else> Individual </span>
              </li>
            </ul>
          </div>
        </div>

        <section>
          <ul
            v-if="isTeacher && user.pendingInvites.length"
            class="license-invitations"
          >
            <li
              v-for="invite in user.pendingInvites"
              :key="invite.id"
              class="license-invite"
            >
              <div class="license-invite-details">
                <p>{{ licenseInvitationMessage(invite) }}</p>
              </div>
              <div class="license-invite-actions">
                <form-button
                  destructive
                  @click="declineLicenseInvite(invite.id)"
                >
                  Decline
                </form-button>
                <form-button @click="acceptLicenseInvite(invite.id)">
                  Accept
                </form-button>
              </div>
            </li>
          </ul>
        </section>

        <div v-if="vetStatus" class="vet-status">
          <ul class="details">
            <li>
              User Status:
              <span class="user-vet-status">
                <icon
                  v-if="vetStatus.icon"
                  class="vet-status-icon"
                  :icon="vetStatus.icon"
                />
                {{ vetStatus.value }}
              </span>
            </li>
            <li>
              <p class="vet-status-message">{{ vetStatusMessage }}</p>
            </li>
          </ul>
        </div>
      </section>
    </loading-container>
  </view-container>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import * as types from 'src/setup/store/mutation-types'
import InstitutionRolesChoices from 'src/shared/components/InstitutionRolesChoices'
import PrimarySubjectsChoices from 'src/shared/components/PrimarySubjectsChoices'
import client from 'src/shared/api-client.ts'

import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import MFASecretModal from 'src/shared/components/modals/MFASecretModal'
import ClickToCopyField from 'src/shared/components/ClickToCopyField'
import LeaveLicenseConfirmModal from 'src/shared/components/modals/LeaveLicenseConfirmModal.vue'

export default {
  name: 'UserProfileView',
  props: {
    id: {
      type: String,
      default: null
    }
  },
  inject: ['$modal'],
  components: {
    PrimarySubjectsChoices,
    InstitutionRolesChoices,
    ClickToCopyField
  },
  data: () => ({
    firstName: '',
    lastName: '',
    email: '',
    primarySubjects: [],
    institutionRoles: [],
    password: null,
    confirmPassword: null,
    editingTeacherProfile: false,
    isLoading: true,
    license: null,
    canUpdateProfile: false
  }),
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    ...mapGetters([
      'isTeacher',
      'isAdmin',
      'vetStatus',
      'isContentDeveloper',
      'isStudent'
    ]),
    vetStatusMessage() {
      switch (this.vetStatus.value.toLowerCase()) {
        case 'pending':
          return 'Your user account is pending verification. Once verified, you will be able to access answers and notes.'
        case 'verified':
          return 'Your user account has been verified.'
        case 'rejected':
          return 'This user account has been suspended.'
        default:
          return ''
      }
    },
    isTrialTeacher() {
      return this.license && this.license.type === 'trial'
    },
    canLeaveCurrentLicense() {
      return this.license && !this.license.isLastAdmin
    },
    canManageMFA() {
      return !this.id && this.canUpdateProfile
    }
  },
  methods: {
    ...mapActions(['refreshAuth']),
    ...mapMutations({
      setHasMFA: types.SET_HAS_MFA
    }),
    async load() {
      this.clear()
      if (this.isAdmin && this.id) {
        const user = await client.users.getUser(this.id)
        this.firstName = user.firstName
        this.lastName = user.lastName
        this.email = user.email
        this.institutionRoles = user.institutionRoles
        this.primarySubjects = user.primarySubjects
        this.editingTeacherProfile = user.role === 'teacher'
        this.canUpdateProfile = user.canUpdateProfile
      } else {
        this.firstName = this.user.firstName
        this.lastName = this.user.lastName
        this.email = this.user.email
        // need to include fields for institutionRoles on user
        this.primarySubjects = this.user.primarySubjects
        this.institutionRoles = this.user.institutionRoles
        this.editingTeacherProfile = this.isTeacher
        this.canUpdateProfile = this.user.canUpdateProfile
      }
      if (this.user.siteLicense) {
        this.license = this.user.siteLicense
      } else {
        this.license = null
      }
      this.isLoading = false
    },
    clear() {
      this.firstName = ''
      this.lastName = ''
      this.email = ''
      this.institutionRoles = []
      this.primarySubjects = []
      this.password = ''
      this.confirmPassword = ''
    },
    licenseInvitationMessage(invitation) {
      const isInstitution = invitation.type === 'institution'
      const isTrial = invitation.type === 'trial'
      return `You have been invited to ${isInstitution ? 'an' : 'a'} ${
        invitation.type
      } ${!isTrial ? 'paid' : ''} license by ${invitation.institutionName}`
    },
    async acceptLicenseInvite(licenseId) {
      // if the user is currently on a license show the confirmation modal
      if (this.user.siteLicense && this.user.siteLicense.id) {
        const { status } = await this.$modal.show(LeaveLicenseConfirmModal, {
          isLastAdmin: this.license.isLastAdmin,
          hasPendingRenewals: this.license.renewalScheduled
        })

        if (status === 'ok') {
          await client.licenses.resolveInvite({
            licenseId,
            accept: true
          })
        } else {
          return
        }
      } else {
        await client.licenses.resolveInvite({
          licenseId,
          accept: true
        })
      }
      await this.refreshAuth()
      this.$success(`You have joined the license`)
      this.load()
    },
    async declineLicenseInvite(licenseId) {
      await client.licenses.resolveInvite({
        licenseId,
        accept: false
      })

      await this.refreshAuth()
      this.$success(`You have declined the license invitation`)
      this.load()
    },
    async doUpdateUser(e) {
      try {
        const payload = {
          id: this.id,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          ...(this.password && { password: this.password }),
          ...(this.editingTeacherProfile && {
            primarySubjects: this.primarySubjects,
            institutionRoles: this.institutionRoles
          })
        }

        if (this.isAdmin && this.id) {
          await client.users.updateUserProfile(payload)
          this.$success('The instructor profile was updated successfully.')
        } else {
          await client.users.updateMyProfile(payload)
          await this.refreshAuth()
          this.$success('Your profile was updated successfully.')
        }

        this.password = ''
        setTimeout(() => (this.confirmPassword = ''), 0) //this fixes vee-validate stale state issue causing it to appear invalid.
        e.done()
      } catch (error) {
        e.done(false)
        throw error
      }
    },
    moveToSection(sectionId) {
      if (sectionId) {
        const scrollTarget = document.querySelector(sectionId)
        scrollTarget && scrollTarget.scrollIntoView(true)
      } else {
        const scroller = document.querySelector('.modal-provider') || window
        scroller.scrollTo(0, 0)
      }
    },
    async leaveLicense() {
      const { status } = await this.$modal.show(ConfirmModal, {
        html: '<p>Leaving a license will restrict your access to Pivot Interactive until you join a new license and this will remove you from all your classes.</p><p>This cannot be undone</p>',
        prompt: 'Are you sure you want to leave your current license?'
      })
      if (status === 'ok') {
        await client.licenses.leave({ id: this.license.id })
        await this.refreshAuth()
        this.$success(`You have successfully left the license`)
        this.load()
      }
    },
    async reconfigureMFA() {
      const { status } = await this.$modal.show(ConfirmModal, {
        text: 'Reconfiguring your multi-factor authentication device will invalidate your current device.',
        prompt: 'Are you sure you want to reconfigure your MFA device?'
      })
      if (status === 'ok') {
        this.configureMFA()
      }
    },
    async configureMFA() {
      const mfaResponse = await client.auth.generateMFA()
      const { status } = await this.$modal.show(MFASecretModal, {
        secret: mfaResponse.secret,
        uri: mfaResponse.uri,
        qr: mfaResponse.qr
      })

      if (status === 'ok') {
        this.setHasMFA(true)
        this.$success('Multi-factor authentication configured succssfully.')
      }
    }
  },
  async created() {
    await this.load()
  },
  beforeRouteUpdate(to, from, next) {
    // ensures if user is on the profile page and they click the license option in the Teacher Menu, they are brought to the license section
    this.moveToSection(to.hash)
    next()
  },
  mounted() {
    // we need to make sure we have the current user / license data
    this.refreshAuth()
    // ensures the page scrolls to the license section if the hash matches
    if (this.$route.hash) {
      this.$nextTick(() => {
        this.moveToSection(this.$route.hash)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-page {
  padding-bottom: 8rem;
}
.license-info {
  padding: 2rem 0 4rem 0;
  .details {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      font-weight: bold;
      span {
        font-weight: normal;
      }
    }
  }
}
.leave-license {
  margin-top: 2rem;
}
.license-type {
  text-transform: capitalize;
}
.vet-status {
  margin-top: 2.5rem;
}
.vet-status-icon {
  margin-right: 0.2rem;
  margin-left: 0.4rem;
}
.vet-status-message {
  font-style: italic;
  font-weight: 100;
  opacity: 0.75;
}
.form-label {
  width: 130px;
}
.license-invitations {
  list-style: none;
  margin: 4rem 0 6rem 0;
  padding: 0;
}
.license-invite {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem;
  margin-top: 2rem;
  background: #eee;
  border-radius: 5px;
  p {
    margin: 0;
  }
}
.license-invite-actions {
  display: flex;
  justify-content: space-between;
  button:last-child {
    margin-left: 1rem;
  }
}
.license-invite-details {
  padding-right: 2rem;
}
.userId__field {
  width: 334px;
}
</style>
