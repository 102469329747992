<template>
  <loading-container :loading="isLoading">
    <view-header :show-flash="false">
      <template v-if="license">
        <view-title
          v-if="license"
          editable
          aria-label="License Name"
          :value="license.name"
          @submit="updateLicenseName"
        />
      </template>

      <view-title v-else> Manage License </view-title>

      <view-header-actions v-if="isAdmin">
        <button-dropdown class="action-menu" secondary v-if="isAdmin">
          <template #button>
            <icon icon="ellipsis-v" />
            <span class="sr-only">Actions</span>
          </template>
          <dropdown-action @click="addMembers">
            Invite Instructors or Admins
          </dropdown-action>

          <dropdown-action @click="scheduleRenewal">
            Schedule Renewal
          </dropdown-action>
          <dropdown-link
            v-if="!isLicenseTrial"
            :to="{
              name: 'new_renewal_session',
              query: { licenseId: license.id }
            }"
          >
            Generate Renewal Quote
          </dropdown-link>
          <dropdown-action
            v-if="license.type === 'institution'"
            @click="configureRostering"
          >
            Configure Rostering
          </dropdown-action>
          <dropdown-action @click="archive"> Archive License </dropdown-action>
          <dropdown-action @click="merge"> Merge License </dropdown-action>
        </button-dropdown>
        <button-link
          class="center-block"
          :to="{ name: 'admin_update_license', id }"
        >
          Update License
        </button-link>
      </view-header-actions>
      <view-header-actions v-else-if="isLicenseTrial">
        <button-link class="center-block" :to="{ name: 'new_upgrade_session' }">
          Upgrade Now
        </button-link>
      </view-header-actions>
      <div class="manage-tab__salesforce" v-if="license && isAdmin">
        Salesforce ID:
        <hyper-link
          v-if="license.salesforceId"
          :to="{
            name: 'salesforce_account',
            params: { id: license.salesforceId }
          }"
          >{{ license.salesforceId }}</hyper-link
        >
        <router-link
          v-if="license.salesforceId"
          :to="{ name: 'admin_update_license', id }"
        >
          edit
        </router-link>
        <router-link v-else :to="{ name: 'admin_update_license', id }">
          set
        </router-link>
      </div>
      <view-subtitle
        v-if="license"
        editable
        aria-label="License Institution Name"
        :value="license.institutionName"
        @submit="updateInstitutionName"
      />

      <view-subtitle
        v-if="license"
        editable
        aria-label="License Email Domain"
        :value="license.emailDomain"
        placeholder="Email Domain (e.g. school.edu)"
        @submit="updateEmailDomain"
      />
    </view-header>
    <view-sub-header v-if="externalRoster">
      <checkbox
        :modelValue="externalRoster.isEnabled"
        @update:modelValue="value => toggleRostering(value)"
      >
        Toggle Rostering
      </checkbox>
    </view-sub-header>
    <view-sub-header v-if="isAdmin">
      <checkbox
        :modelValue="license.isDiscoveryManaged"
        @update:modelValue="value => toggleDiscoveryManaged(value)"
      >
        DEK12 License
      </checkbox>
    </view-sub-header>
    <view-sub-header v-if="isAdmin && license.type === 'trial'">
      <checkbox
        :modelValue="license.autoVet"
        @update:modelValue="value => toggleAutoVet(value)"
      >
        Automatically pre-vet users
      </checkbox>
    </view-sub-header>
    <section>
      <div class="row details">
        <div class="col-sm-2">
          <h4 id="seatLimitLabel">Seat Limit</h4>
          <p aria-labelledby="seatLimitLabel">
            {{ license.maxSeats }}
            <router-link
              v-if="license.type === 'institution'"
              class="add-seats-cta"
              :to="{
                name: 'new_add_seats_session',
                query: { licenseId: license.id }
              }"
            >
              <icon icon="plus" />
              Add Seats
            </router-link>
          </p>
        </div>
        <div class="col-sm-1 text-center">
          <h4>Seats Used</h4>
          <p>{{ license.seatsUsed }}</p>
        </div>
        <div class="col-sm-2 text-center" v-if="isAdmin">
          <h4>Live Seat Count</h4>
          <p>{{ license.liveSeatCount }}</p>
        </div>

        <div class="col-sm-2">
          <h4>Start Date</h4>
          <p>
            <template v-if="license.startDate">
              {{ $format.date(license.startDate, 'MM-dd-yyyy') }}
            </template>
          </p>
        </div>
        <div class="col-sm-2">
          <h4>End Date</h4>
          <p>
            <template v-if="license.endDate">
              {{ $format.date(license.endDate, 'MM-dd-yyyy') }}
            </template>
          </p>
        </div>
        <div class="col-sm-1">
          <h4>Status</h4>
          <p>{{ license.status }}</p>
        </div>
        <div class="col-sm-1">
          <h4>Type</h4>
          <p>{{ license.type }}</p>
        </div>
        <div class="col-sm-1">
          <h4>Level</h4>
          <p>{{ license.level }}</p>
        </div>
      </div>
    </section>
    <section>
      <div class="members-header">
        <h2>Members</h2>
      </div>

      <list :items="sortedMembers || []" aria-label="License Members">
        <list-column
          v-if="isAdmin"
          v-slot="{ firstName, lastName, id: instructorId }"
          title="Name"
        >
          <router-link
            :to="{
              name: 'admin_manage_instructor',
              params: { id: instructorId }
            }"
          >
            {{ firstName || lastName ? `${firstName} ${lastName}` : 'Pending' }}
          </router-link>
        </list-column>
        <list-column v-else v-slot="{ firstName, lastName }" title="Name">
          {{ firstName || lastName ? `${firstName} ${lastName}` : 'Pending' }}
        </list-column>

        <list-column title="Email" property="email" />
        <list-column
          v-if="isAdmin"
          v-slot="{ status, disabledReason }"
          title="Status"
        >
          {{ disabledReason ? disabledReason : status }}
        </list-column>

        <list-column v-slot="{ seatsUsed, role }" title="Seats Used">
          <span v-if="role !== 'grader'">{{ seatsUsed || 0 }}</span>
          <span v-else>-</span>
        </list-column>
        <list-column v-slot="member" title="Role">
          <select-field
            :modelValue="member.role"
            data-testid="member-role"
            @update:modelValue="value => changeMemberRole(member.id, value)"
          >
            <option value="member">Member</option>
            <option value="admin">Admin</option>
            <option value="grader">Grader</option>
          </select-field>
        </list-column>
        <list-column v-slot="member" title="Actions">
          <form-button
            link
            data-testid="remove-member"
            @click="() => removeMember(member.id, member.email)"
          >
            Remove
          </form-button>
        </list-column>
      </list>
    </section>

    <section class="license-invitations">
      <div class="invitations-header">
        <h2>Invitations</h2>
        <form-button @click="addMembers">
          <icon icon="plus" />
          Invite Instructors or Admins
        </form-button>
      </div>
      <list
        :items="license.invitations || []"
        aria-label="Invitations"
        class="invitations-table"
      >
        <list-column v-slot="{ firstName, lastName, userId }" title="Name">
          <router-link
            v-if="isAdmin"
            :to="{
              name: 'admin_manage_instructor',
              params: { id: userId }
            }"
          >
            {{ `${firstName} ${lastName}` }}
          </router-link>
          <div v-else>{{ `${firstName} ${lastName}` }}</div>
        </list-column>

        <list-column title="Email" property="email" />
        <list-column v-slot="{ role }" title="Role">
          {{ role }}
        </list-column>
        <list-column v-slot="{ status }" title="Status">
          {{ status }}
        </list-column>
        <list-column v-slot="{ userId, status, email }" title="Actions">
          <button-dropdown tertiary right>
            <template #button>
              <icon icon="ellipsis-h" />
              <span class="sr-only">License Invitations Actions Menu</span>
            </template>

            <dropdown-action @click="removeLicenseInvitation(userId, status)">
              Remove
            </dropdown-action>

            <dropdown-action @click="resendLicenseInvite(email)">
              Resend Invitation
            </dropdown-action>
            <dropdown-action v-if="isAdmin" @click="copyInvitationURL(email)">
              Copy Invitation URL
            </dropdown-action>
          </button-dropdown>
        </list-column>
      </list>
    </section>

    <section>
      <div class="renewals-header">
        <h2>Scheduled Renewals</h2>
        <button-link
          v-if="!isLicenseTrial"
          :to="{
            name: 'new_renewal_session',
            query: { licenseId: license.id }
          }"
        >
          <icon v-if="!isLicenseTrial" icon="plus" />
          Renew License
        </button-link>
      </div>
      <list :items="license.renewals || []" aria-label="Renewals">
        <list-column v-slot="{ startDate }" title="Start Date">
          {{ $format.date(startDate, 'MM-dd-yyyy') }}
        </list-column>
        <list-column v-slot="{ endDate }" title="End Date">
          {{ $format.date(endDate, 'MM-dd-yyyy') }}
        </list-column>
        <list-column v-slot="renewal" title="Seat Limit">
          {{ renewal.maxSeats }}
        </list-column>
        <list-column title="Type" property="type" />
        <list-column title="Status" property="status" />
        <list-column
          v-if="isAdmin"
          v-slot="{ renewalId, status }"
          title="Actions"
        >
          <form-button
            v-if="status === 'scheduled'"
            link
            @click="cancelRenewal(renewalId)"
          >
            Cancel
          </form-button>
        </list-column>
      </list>

      <div v-if="!hasRenewals" class="row">
        <p class="col-xs-12 text-center">
          This license has no scheduled renewals
        </p>
      </div>
    </section>
    <section v-if="isAdmin">
      <div class="renewals-header">
        <h2>Comments</h2>
      </div>
      <ol class="license-comment__list">
        <li
          v-for="comment in comments"
          :key="comment.id"
          class="license-comment__comment"
        >
          <async-form
            v-if="editingComment?.id === comment.id"
            class="license-comment__form"
            @submit="updateComment"
          >
            <multiline-text-input
              aria-label="Edit comment"
              v-model="editingComment.text"
              class="license-comment__text-input"
            />

            <submit-button primary class="license-comment__edit-button"
              >Save</submit-button
            >
            <form-button
              secondary
              class="license-comment__edit-button"
              @click="cancelComment"
            >
              Cancel
            </form-button>
          </async-form>
          <template v-else>
            <div>
              <span class="license-comment__name">
                {{ comment.user.firstName }}
                {{ comment.user.lastName }}
              </span>
              <span class="license-comment__timestamp">
                {{ $format.date(comment.updatedAt, 'MM-dd-yyyy hh:mm a') }}
              </span>
              <form-button
                v-if:="comment.user.id === userId"
                tertiary
                @click="editComment(comment)"
                class="license-comment__edit-button"
                >Edit</form-button
              >
            </div>
            <div
              class="license-comment__text"
              v-html="sanitizeComment(comment.text)"
            ></div>
          </template>
        </li>
        <li>
          <async-form class="license-comment__form" @submit="addComment">
            <form-label for="new-license-comment"> New comment:</form-label>
            <multiline-text-input
              id="new-license-comment"
              v-model="newComment"
              class="license-comment__text-input"
            />
            <submit-button class="license-comment__submit-button"
              >Comment</submit-button
            >
          </async-form>
        </li>
      </ol>
    </section>
    <section>
      <div class="renewals-header">
        <h2>History</h2>
      </div>
      <list :items="license.history || []" aria-label="History">
        <list-column v-slot="{ timestamp }" title="Date">
          {{ $format.date(timestamp, 'MM-dd-yyyy hh:mm a') }}
        </list-column>
        <list-column v-slot="{ action }" title="Action">
          {{ action }}
        </list-column>
        <list-column v-if="isAdmin" v-slot="{ actor }" title="Actor">
          <span v-if="actor">{{ actor.firstName }} {{ actor.lastName }}</span>
        </list-column>
        <list-column
          v-if="isAdmin"
          v-slot="{ paymentId, invoiceId, sourceLicense }"
          title="Actions"
        >
          <router-link
            v-if="paymentId"
            :to="{ name: 'purchase_quote', params: { id: paymentId } }"
            style="margin-right: 5px"
          >
            View Purchase
          </router-link>
          {{ paymentId && (sourceLicense || invoiceId) ? '|' : '' }}
          <router-link
            v-if="sourceLicense"
            :to="{
              name: 'admin_manage_license',
              params: { id: sourceLicense, tab: 'manage' }
            }"
            style="margin-right: 5px"
          >
            View License
          </router-link>
          {{ sourceLicense && invoiceId ? '|' : '' }}
          <a
            v-if="invoiceId"
            style="margin-left: 5px"
            :href="`${stripeBaseInvoiceURL}${invoiceId}`"
            target="_blank"
          >
            View in Stripe
          </a>
        </list-column>
        <template #details="{ item }">
          <span v-if="item.startDate"
            >Start Date: {{ $format.date(item.startDate, 'MM-dd-yyyy') }}</span
          >
          <span v-if="item.endDate"
            >End Date: {{ $format.date(item.endDate, 'MM-dd-yyyy') }}</span
          >
          <span v-if="item.seatLimit">Seat Limit: {{ item.seatLimit }}</span>
          <span v-if="item.status">Status: {{ item.status }}</span>
          <span v-if="item.type">Type: {{ item.type }}</span>
        </template>
      </list>
    </section>
    <section v-if="externalRoster && externalRoster.lastSyncDate">
      <div class="renewals-header">
        <h2>Last External Roster Sync</h2>
      </div>

      <p>{{ lastSyncDate }}</p>
      <p v-if="externalRoster.lastSyncLog">
        <icon icon="close" class="text-danger" />
        Failed
        <form-button class="view-log-cta" link @click="viewLastSyncLog">
          <icon icon="list" />
          View Log
        </form-button>
      </p>
      <p v-else>
        <icon icon="check" class="text-success" />
        Success
      </p>
    </section>
  </loading-container>
</template>

<script setup>
import debounce from 'lodash/debounce'

import client from 'src/shared/api-client.ts'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import MergeLicenseModal from 'src/shared/components/modals/MergeLicenseModal'
import LicenseRenewalModal from 'src/shared/components/modals/LicenseRenewalModal'
import LicenseAddMembersModal from 'src/shared/components/modals/LicenseAddMembersModal'
import ConfigureRosteringModal from 'src/shared/components/modals/ConfigureRosteringModal'
import TextLogModal from 'src/shared/components/modals/TextLogModal'
import { computed, inject, ref, onBeforeMount, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useFlash } from 'src/shared/hooks/flash'
import { format } from 'date-fns'
import DOMPurify from 'dompurify'

const $modal = inject('$modal')

const $router = useRouter()

const $route = useRoute()

const store = useStore()

const props = defineProps({
  id: {
    type: String,
    required: false
  }
})

const flash = useFlash()

const license = ref({})

const externalRoster = ref()

const editingComment = ref({})

const comments = ref([])

const newComment = ref('')

const isAdmin = computed(
  () => store.getters.isAdmin || store.getters.isSalesAdmin
)

const userId = computed(() => store.getters.userId)

const siteLicense = computed(() => store._state.data.auth.user.siteLicense)
const isLoading = computed(() => !license.value?.id)
const licenseId = computed(() => {
  return props.id || (siteLicense.value && siteLicense.value.id)
})

const sortedMembers = computed(() => {
  return license.value.members.slice(0).sort((a, b) => {
    if (a.role === b.role) {
      return `${a.lastName}, ${a.firstName}`.localeCompare(
        `${b.lastName}, ${a.firstName}`
      )
    }
    const roleOrder = ['admin', 'member', 'grader']
    return roleOrder.indexOf(a.role) - roleOrder.indexOf(b.role)
  })
})

const isLicenseTrial = computed(() => {
  return license?.value.type === 'trial'
})

const hasRenewals = computed(() => {
  return license.value.renewals && license.value.renewals.length
})

const stripeBaseInvoiceURL = computed(() => {
  if (PI_ENV !== 'production') {
    return 'https://dashboard.stripe.com/test/invoices/'
  }
  return 'https://dashboard.stripe.com/invoices/'
})

const lastSyncDate = computed(() => {
  if (externalRoster.value && externalRoster.value.lastSyncDate) {
    return format(
      new Date(externalRoster.value.lastSyncDate),
      'MM-dd-yyyy hh:mm a'
    )
  }
  return 'Not Yet Run'
})

const getLicense = async () => {
  const requests = [client.licenses.get({ id: licenseId.value })]
  if (isAdmin.value) {
    requests.push(client.licenses.getComments({ id: licenseId.value }))
  }
  const [licenseRequest, commentsRequest] = await Promise.all(requests)

  license.value = licenseRequest

  comments.value = commentsRequest ?? []
}

const addComment = async e => {
  try {
    await client.licenses.addComment({
      id: licenseId.value,
      text: newComment.value
    })
    const commentsRequest = await client.licenses.getComments({
      id: licenseId.value
    })

    comments.value = commentsRequest ?? []
    e.done()
    newComment.value = ''
  } catch (error) {
    e.done(false)
    throw error
  }
}

const editComment = comment => {
  editingComment.value = { ...comment }
}

const cancelComment = () => {
  editingComment.value = null
}

const updateComment = async e => {
  try {
    await client.licenses.updateComment({
      id: licenseId.value,
      commentId: editingComment.value.id,
      text: editingComment.value.text
    })
    comments.value = await client.licenses.getComments({ id: licenseId.value })
    editingComment.value = null
    e.done()
  } catch (error) {
    e.done(false)
    throw error
  }
}

const getExternalRosterConnection = async () => {
  try {
    const body = await client.licenses.getExternalRosterConnection({
      licenseId: licenseId.value
    })
    externalRoster.value = body
  } catch (error) {
    if (error.status !== 404) {
      throw error
    }
  }
}

const toggleDiscoveryManaged = debounce(async function (toggle) {
  try {
    await client.licenses.update({
      id: license.value.id,
      isDiscoveryManaged: toggle
    })
    flash.success('License discovery status updated successfully.')
  } finally {
    await getLicense()
  }
}, 500)

const toggleAutoVet = debounce(async function (toggle) {
  try {
    await client.licenses.update({
      id: license.value.id,
      autoVet: toggle
    })
    flash.success('License auto vet status updated successfully.')
  } finally {
    await getLicense()
  }
}, 500)

const isMemberLastAdmin = memberId => {
  const members = license.value.members.filter(m => m.id !== memberId)
  if (members.every(m => m.role.toLowerCase() !== 'admin')) {
    return true
  }
  return false
}

const removeMember = async (memberId, email) => {
  let removeMemberText
  if (
    userId.value === memberId &&
    siteLicense.value &&
    siteLicense.value.isLastAdmin
  ) {
    removeMemberText = {
      html: `<p>You are the last admin on this license. If you leave this license:<p><ul>
            <li>It will not be possible to add or remove instructors from this license</li>
            <li>You will need to contact Pivot Interactives support to be added back to the license</li>
            ${
              license.value.renewals.length
                ? '<li>Your pending renewals will be lost.</li>'
                : ''
            }
          </ul>`,
      prompt: `Are you sure you want to be removed from this license?`
    }
  } else if (isMemberLastAdmin(memberId)) {
    // only platform admins should get this condition
    removeMemberText = {
      text: 'This member is the last admin on the license.',
      prompt: `Are you sure you want to remove the member ${email}?`
    }
  } else {
    removeMemberText = {
      html: `<p>Removing this member from this license will immediately revoke their access to Pivot Interactives and remove ${email} from all of their classes</p>
        <p>This cannot be undone.</p>`,
      prompt: `Are you sure you want to remove the member ${email}?`
    }
  }
  try {
    const { status } = await $modal.show(ConfirmModal, removeMemberText)
    if (status === 'ok') {
      await client.licenses.removeMember({ id: licenseId.value, memberId })
      flash.success('Member removed from license successfully')
    }
  } finally {
    await getLicense()
  }
}

const changeMemberRole = async (memberId, role) => {
  try {
    await client.licenses.changeMemberRole({
      id: licenseId.value,
      memberId: memberId,
      role: role
    })
    flash.success('Member role updated successfully')
  } finally {
    await getLicense()
  }
}

const removeLicenseInvitation = async (userId, inviteStatus) => {
  try {
    const { status } = await $modal.show(ConfirmModal, {
      text: `${
        inviteStatus === 'pending'
          ? 'This user has not responded to the license invitation yet. Removing the invitation will prevent them from being able to accept it. '
          : ''
      }This action cannot be undone.`,
      prompt: `Are you sure you want to remove this license Invitation?`
    })
    if (status === 'ok') {
      await client.licenses.removeInvitation({
        licenseId: license.value.id,
        userId: userId
      })
      flash.success('License invitation removed')
      await getLicense()
    }
  } catch (error) {
    flash.error('Error removing license invitation')
    throw error
  }
}

const copyInvitationURL = async email => {
  const { protocol, host } = window.location
  const response = await client.invitations.search({
    q: email,
    limit: 1
  })

  const invitation = response.items.filter(item => item.email === email)[0]

  if (invitation) {
    const token = invitation.token
    const signupUrl = `${protocol}//${host}/signup/${token}`
    window.navigator.clipboard.writeText(signupUrl)
    flash.success('Copied signup URL to clipboard')
  } else {
    flash.error('No invitation found for this email')
  }
}

const addMembers = async () => {
  try {
    const { status, data } = await $modal.show(LicenseAddMembersModal, {
      license: license,
      isAdmin
    })

    if (status === 'ok') {
      flash.success(
        `${data.emailArray.length} email(s) invited to license successfully.`
      )
    }
  } finally {
    await getLicense()
  }
}

const resendLicenseInvite = async email => {
  try {
    await client.licenses.inviteMembers({
      id: licenseId.value,
      emails: [email]
    })
  } finally {
    await getLicense()
    flash.success('License Invitation Resent')
  }
}

const scheduleRenewal = async () => {
  try {
    const { status, data } = await $modal.show(LicenseRenewalModal, {
      currentLicense: license.value
    })
    if (status === 'ok') {
      const { id } = license.value
      await client.licenses.scheduleRenewal({ licenseId: id, ...data })
      flash.success('License renewal scheduled successfully')
    }
  } finally {
    await getLicense()
  }
}

const cancelRenewal = async renewalId => {
  try {
    const { status } = await $modal.show(ConfirmModal, {
      text: 'This action cannot be undone.',
      prompt: `Are you sure you want to cancel this scheduled renewal?`
    })
    if (status === 'ok') {
      const { id } = license.value
      await client.licenses.cancelRenewal({ licenseId: id, renewalId })
      flash.success('Your scheduled renewal has been cancelled.')
    }
  } finally {
    await getLicense()
  }
}

const updateLicenseName = async value => {
  try {
    await client.licenses.update({ id: license.value.id, name: value })
    flash.success('License name updated successfully.')
  } finally {
    await getLicense()
  }
}

const updateInstitutionName = async value => {
  try {
    await client.licenses.update({
      id: license.value.id,
      institutionName: value
    })
    flash.success('Institution Name updated successfully.')
  } finally {
    await getLicense()
  }
}

const updateEmailDomain = async value => {
  try {
    await client.licenses.update({ id: license.value.id, emailDomain: value })
    flash.success('Email Domain updated successfully.')
  } finally {
    await getLicense()
  }
}

const configureRostering = async () => {
  const { status, data } = await $modal.show(ConfigureRosteringModal, {
    ...(externalRoster.value && {
      update: true,
      initialConnectionType: externalRoster.value.connectionType,
      initialApplicationId: externalRoster.value.applicationId,
      initialDistrictId: externalRoster.value.districtId
    })
  })
  if (status === 'ok') {
    if (externalRoster.value) {
      await client.licenses.updateExternalRoster({
        licenseId: license.value.id,
        ...data
      })
    } else {
      await client.licenses.createExternalRoster({
        licenseId: license.value.id,
        ...data
      })
    }
    flash.success('External roster updated successfully.')
    await getExternalRosterConnection()
  }
}

const viewLastSyncLog = () => {
  $modal.show(TextLogModal, {
    title: 'Last Roster Sync Log',
    text: externalRoster.value.lastSyncLog,
    date: new Date(externalRoster.value.lastSyncDate)
  })
}

const archive = async () => {
  const { status } = await $modal.show(ConfirmModal, {
    title: `Archive License ${license.value.name}`,
    text: `Archiving a license will remove all members and place them on individual accounts.  Archived licenses cannot be updated, modified or viewed.  This action cannot be undone.`,
    prompt: `Are you sure you want to archive this license?`
  })
  if (status === 'ok') {
    await client.licenses.archive({ id: license.value.id })
    $router.push({ name: 'admin_licenses' })
    flash.success('License archived successfully.')
  }
}
const merge = async () => {
  const { status, data } = await $modal.show(MergeLicenseModal, {
    sourceLicense: license.value
  })
  if (status === 'ok') {
    const { status } = await $modal.show(ConfirmModal, {
      text: `This will permanently merge ${license.value.name} into ${data.targetLicense.name}.  ${license.value.name} will be archived. `,
      prompt: `Merge these licenses and archive ${license.value.name}? This cannot be undone.`
    })
    if (status === 'ok') {
      await client.licenses.merge({
        sourceLicense: license.value.id,
        targetLicense: data.targetLicense.id
      })
      $router.push({ name: 'admin_licenses' })
      flash.success('License merged successfully.')
    }
  }
}

const toggleRostering = debounce(async function (toggle) {
  externalRoster.value.isEnabled = toggle

  if (externalRoster.value) {
    await client.licenses(licenseId.value, {
      isEnabled: externalRoster.value.isEnabled
    })
    flash.success('External roster updated successfully.')
    await getExternalRosterConnection()
  }
}, 500)

const sanitizeComment = comment => DOMPurify.sanitize(comment)

watch(
  () => props.id,
  async () => {
    await Promise.all([getLicense(), getExternalRosterConnection()])
    if (
      externalRoster.value &&
      externalRoster.value.lastSyncLog &&
      $route.hash.toLowerCase() === '#roster-sync-log'
    ) {
      viewLastSyncLog()
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
.invitations-table {
  overflow: visible;
}
section.license-invitations {
  margin: 4.8rem 0;
}
.details {
  margin-bottom: 4.8rem;
}
.members-header {
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
}
.invitations-header,
.renewals-header {
  display: flex;
  align-items: center;
  margin: 4.8rem 0 2.4rem 0;
}
.members-header h2,
.invitations-header h2,
.renewals-header h2 {
  margin: 0 auto 0 0;
}
.add-seats-cta,
.view-log-cta {
  margin-left: 1rem;
}

.header-actions > * {
  margin-left: 8px;
}

:deep(.action-menu) {
  margin-right: 8px;
}

.flex-end {
  justify-content: flex-end;
  flex-grow: 1;
}
.tag {
  display: flex;
  padding: 5px;
  border-radius: 5px;
  background-color: $plum;
  color: #ffffff;
  font-weight: 100;
  font-size: 12px;
  line-height: 12px;
}
.license-comment__list {
  list-style-type: none;
}
.license-comment__comment {
  margin-bottom: 10px;
  border-bottom: 1px solid #8c8c8c;
  background: #e2e2e2;
  border-radius: 5px;
  padding: 10px;
}
.license-comment__name {
  font-weight: 700;
  margin-right: 10px;
}
.license-comment__timestamp {
  color: #8c8c8c;
}
.license-comment__edit-button {
  margin-top: 5px;
  margin-left: 10px;
}
.license-comment__form {
  font-weight: 700;
}
.license-comment__submit-button {
  margin-top: 5px;
  margin-bottom: 10px;
}
.break {
  flex-basis: 100%;
  height: 0;
}

.dashboard-invitations-button {
  margin-bottom: -40px;
}

.dashboard-title {
  margin-bottom: 10px;
}

.overview {
  display: flex;
  justify-content: space-between;
}

.welcome-panel {
  margin-bottom: 22px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  padding-left: 15px;
  padding-bottom: 20px;
}

.context-message {
  margin: 0;
  font-family: Lato;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 20px;
}
.invitation-message {
  margin: 0;
  font-family: Lato;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: $color-success;
  padding-bottom: 20px;
  width: 65%;
}

.send-invitations-button {
  margin-right: 10px;
}

.manage-license__salesforceId {
  margin-right: 10px;
  font-size: 25px;
  font-weight: 700;
}

.manage-tab__salesforce {
  width: 100%;
}
</style>
