<template>
  <div>
    <data-grid-pojo-adapter
      v-if="component.componentType.includes('Grid')"
      :settings="component.settings"
      :modelValue="value"
      :graph="component.componentType === 'GridGraphQuestion'"
    />
  </div>
</template>

<script>
import DataGridPojoAdapter from 'src/shared/components/grid-graph/DataGridPojoAdapter'

import {
  defaultGridValue,
  defaultGraphValue,
  convertDataFormat
} from 'src/shared/components/grid-graph/utilities'

export default {
  name: 'TableGraphResponse',
  components: { DataGridPojoAdapter },
  props: {
    component: {
      required: true
    },
    response: [String, Object]
  },
  computed: {
    defaultValue() {
      if (this.component.componentType === 'GridGraphQuestion') {
        return {
          ...defaultGridValue(),
          ...defaultGraphValue()
        }
      }

      return { ...defaultGridValue() }
    },
    value() {
      const value = this.response || this.defaultValue

      return convertDataFormat(value)
    }
  }
}
</script>
