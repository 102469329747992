import { unref } from 'vue'
import { Node, mergeAttributes, nodeInputRule } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import InlineExpression from './InlineExpression'
import math from 'src/setup/math'
import { substituteVariablesExpression } from 'src/shared/utils/activity-variables'
import { Plugin, PluginKey } from 'prosemirror-state'
import { Fragment, Slice } from 'prosemirror-model'
import { mapSlice } from 'src/shared/components/editor/utils'

const EXPRESSION_MATCH = /\[expr:(.*?)\]$/
const VARIABLE_MATCH = /\$([A-Za-z0-9_ℹ]+)(?:\.[A-Za-z0-9_ℹ]+)?/g

const replaceIdsWithNames = (expr, variables) => {
  variables.forEach(variable => {
    const idRegex = new RegExp(`\\$${variable.id}\\b`, 'g')
    expr = expr.replace(idRegex, `$${variable.name}`)
  })
  return expr
}

const replaceNamesWithIds = (expr, variables) => {
  variables.forEach(variable => {
    const nameRegex = new RegExp(`\\$${variable.name}\\b`, 'g')
    expr = expr.replace(nameRegex, `$${variable.id}`)
  })
  return expr
}

export const evaluateExpression = (match, variables = []) => {
  const expressionVariables = {
    response: 'response'
  }
  //only evaluate if the variables have values.
  const usedVariables = variables.filter(v => v.id && match.includes(v.id))

  const variablesWithoutValues = usedVariables.some(variable => {
    if (variable.variableType === 'studentResponse')
      return (
        variable.value === null ||
        variable.value === undefined ||
        variable.value === variable.content
      )
    return variable.value === null || variable.value === undefined
  })
  if (variablesWithoutValues) {
    return ''
  }
  variables.forEach(variable => {
    switch (typeof variable.value) {
      case 'number': {
        expressionVariables[`$${variable.id}`] = math.bignumber(variable.value)
        break
      }
      case 'string': {
        expressionVariables[`$${variable.id}`] = variable.value
        break
      }
      case 'object': {
        expressionVariables[`$${variable.id}`] = Object.fromEntries(
          Object.entries(variable.value).map(([key, value]) => {
            // Older activities don't have the dollar sign in nested variables, so we have to add it.
            match = match.replaceAll(
              `$${variable.id}.${key}`,
              `$${variable.id}.$${key}`
            )

            return [
              `$${key}`,
              typeof value === 'number' ? math.bignumber(value) : value
            ]
          })
        )
        break
      }
    }
  })
  try {
    const result = math.evaluate(match ?? '', expressionVariables)
    if (typeof result === 'string' || isNaN(result)) {
      return result
    } else {
      return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 8
      }).format(result.toString())
    }
  } catch (_e) {
    return ''
  }
}

const processExpression = (match, variables) => {
  const usedVariables = getVariables(match, variables)
  return {
    expr: substituteVariablesExpression(match, usedVariables, 'name'),
    variables: usedVariables.map(v => v.id)
  }
}

export const getVariables = (expr, contextVariables = []) => {
  const matches = Array.from(expr.matchAll(VARIABLE_MATCH))
  return contextVariables.filter(variable =>
    matches.some(match => variable.name === match[1])
  )
}

const bracesAreBalanced = expr => {
  let counter = 0
  for (let char of expr) {
    if (char === '[') counter++
    if (char === ']') counter--
    if (counter < 0) return false
  }
  // If counter is 0 all braces match
  return counter === 0
}

const Expression = Node.create({
  name: 'expression',
  inline: true,
  group: 'inline',
  atom: true,
  selectable: true,

  addAttributes() {
    return {
      expr: {
        default: '',
        parseHTML: element =>
          element.getAttribute('expr') ?? element.textContent.trim()
      },
      variables: {
        default: []
      }
    }
  },

  addStorage() {
    return {
      variableContext: null
    }
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: text => {
          if (!this.storage.variableContext) return null
          const match = EXPRESSION_MATCH.exec(text)
          const matches = match && bracesAreBalanced(text)

          if (!matches) return null

          return {
            index: match.index,
            text: match[0],
            replaceWith: match[0],
            data: processExpression(
              match[1].trim(),
              this.storage.variableContext.value.variables
            )
          }
        },
        type: this.type,
        getAttributes: match => ({
          expr: match.data.expr,
          variables: match.data.variables
        })
      })
    ]
  },

  addCommands() {
    return {
      setExpression:
        expr =>
        ({ commands }) => {
          const attrs = processExpression(
            expr,
            this.storage.variableContext.value.variables
          )
          return commands.insertContent({ type: this.name, attrs })
        },
      openExpressionModal: () => () => {
        this.storage.onEditExpression?.()
      }
    }
  },
  addKeyboardShortcuts() {
    return {
      Enter({ editor }) {
        if (editor.isActive('expression')) {
          editor.commands.openExpressionModal()
          return true
        }
      }
    }
  },

  addNodeView() {
    return VueNodeViewRenderer(InlineExpression)
  },

  renderHTML({ node, HTMLAttributes }) {
    return ['expression', mergeAttributes(HTMLAttributes)]
  },

  renderText({ node }) {
    const expression = substituteVariablesExpression(
      node.attrs.expr,
      unref(this.storage.variableContext).variables,
      'id'
    )

    return `{${expression}}`
  },

  parseHTML() {
    return [{ tag: 'expression' }]
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('inline-expression-paste'),
        props: {
          transformCopied: slice => {
            const copyExpressionNodesAsText = node => {
              if (node.type.name === 'expression') {
                const transformedExpr = replaceIdsWithNames(
                  node.attrs.expr,
                  this.storage.variableContext.value.variables
                )
                const contentText = `[expr: ${transformedExpr}]`
                const textNode = node.type.schema.text(contentText)
                return textNode
              }
              return node.copy(node.content)
            }

            return mapSlice(slice, copyExpressionNodesAsText)
          },

          transformPasted: slice => {
            const transformTextNode = (node, schema) => {
              if (node.isText) {
                const exprPattern = /\[expr:(.*?)\]/g
                let match
                let matchesFound = false
                let lastIndex = 0
                let nodes = []

                while ((match = exprPattern.exec(node.text)) !== null) {
                  matchesFound = true
                  if (match.index > lastIndex) {
                    nodes.push(
                      schema.text(node.text.slice(lastIndex, match.index))
                    )
                  }
                  const exprContent = match[1].trim()
                  const { variables } = processExpression(
                    exprContent,
                    this.storage.variableContext.value.variables
                  )
                  nodes.push(
                    schema.nodes.expression.create({
                      expr: replaceNamesWithIds(
                        exprContent,
                        this.storage.variableContext.value.variables
                      ),
                      variables
                    })
                  )
                  lastIndex = match.index + match[0].length
                }

                if (matchesFound && lastIndex < node.text.length) {
                  nodes.push(schema.text(node.text.slice(lastIndex)))
                }

                return matchesFound ? nodes : [node]
              }

              return [node]
            }

            const transformContent = (content, schema) => {
              let transformedContent = []
              content.forEach(child => {
                if (child.content && child.content.size > 0) {
                  const newChildContent = transformContent(
                    child.content,
                    schema
                  )
                  const newNode = child.copy(
                    Fragment.from(newChildContent.flat())
                  )
                  transformedContent.push(newNode)
                } else {
                  const transformedNodes = transformTextNode(child, schema)
                  transformedContent.push(...transformedNodes)
                }
              })
              return transformedContent
            }

            const newContent = transformContent(
              slice.content,
              slice.content.firstChild.type.schema
            )

            const newSlice = new Slice(
              Fragment.from(newContent.flat()),
              slice.openStart,
              slice.openEnd
            )
            return newSlice
          }
        }
      })
    ]
  }
})

export default Expression
