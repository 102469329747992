<template>
  <UIForm @submit="onSubmit">
    <ClassSelector
      v-for="section in sections"
      :key="section.id"
      :classes="classes"
      :sectionName="section.name"
      :platformName="platformName"
      :path="`sections.${section.id}`"
      @refreshClassList="refreshList"
    >
    </ClassSelector>
    <div class="flex justify-end mt-5 mx-auto max-w-[680px]">
      <UIButton submit :disabled="!meta.valid || !atLeastOneClassSelected">
        Connect Classes
      </UIButton>
    </div>
  </UIForm>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate'
import { useRouter } from 'vue-router'

import { UIButton, UIForm } from '@pi/design'
import { Class } from '@pi/api-types'

import client from 'src/shared/api-client'
import { useFlash } from 'src/shared/hooks/flash'
import ClassSelector from '../components/ClassSelector.vue'
import { computed } from 'vue'

const emits = defineEmits(['refreshClassList'])
const flash = useFlash()
const router = useRouter()

interface LtiSection {
  id: string
  name: string
}

defineProps<{
  platformName: string
  classes: Class[]
  sections: LtiSection[]
}>()

const { handleSubmit, meta, values } = useForm<{
  sections: Record<string, string>
}>({
  initialValues: {
    sections: {}
  },
  validateOnMount: true
})
const onSubmit = handleSubmit(async values => {
  const sections = Object.fromEntries(
    Object.entries(values.sections).filter(([_, classId]) => classId !== 'none')
  )
  await client.lti.connectClasses({
    sections
  })

  flash.success('Classes connected successfully.')
  await router.push({ name: 'lti_connect_assignment' })
})

const atLeastOneClassSelected = computed(() => {
  return Object.values(values.sections).some(id => id && id !== 'none')
})
function refreshList() {
  emits('refreshClassList', true)
}
</script>
