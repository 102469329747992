<template>
  <form-group class="mb-8">
    <form-label id="total-points">Hints And Feedback</form-label>
    <div>
      <UISwitch v-model="showHints" name="Show Hints" :disabled="false"
        >Show Hints</UISwitch
      >
      <div class="flex my-3">
        <label for="studentFeedbackTiming">Show Feedback to Students</label>
        <select-field
          id="studentFeedbackTiming"
          v-model="studentFeedbackTiming"
          class="ml-4"
          :disabled="!showRightWrong"
          @update:modelValue="updated"
        >
          <option v-for="opt in options" :key="opt.value" :value="opt.value">
            {{ opt.label }}
          </option>
        </select-field>
      </div>
    </div>
  </form-group>
</template>

<script setup lang="ts">
import { AssignmentsResponse } from '@pi/api-types'
import { ref } from 'vue'
import { UISwitch } from '@pi/design'
interface FeedbackControlProps {
  assignment?: AssignmentsResponse
}

const props = defineProps<FeedbackControlProps>()

const emit = defineEmits(['update'])

const options = [
  { value: 'after-question-submit', label: 'After Submitting Each Question' },
  { value: 'after-assignment-submit', label: 'After Submitting the Assignment' }
  // { value: 'after-final-grade', label: 'After Final Grade' }
]

const showHints = ref(props.assignment ? !props.assignment.noHints : true)
const showRightWrong = ref(
  props.assignment ? props.assignment.studentFeedbackTiming !== 'never' : true
)
const studentFeedbackTiming = ref(
  props.assignment?.studentFeedbackTiming ?? 'after-question-submit'
)

const updated = () => {
  emit('update', {
    studentFeedbackTiming: studentFeedbackTiming.value,
    noHints: !showHints.value
  })
}
</script>
