<template>
  <loading-container :loading="isLoading">
    <async-form
      style="margin-top: 0"
      @submit="submit"
      @keydown.enter.prevent
      persist
    >
      <activity
        ref="activity"
        v-slot="{
          variableContext,
          viewMode,
          toggleSplitView,
          splitViewAvailable
        }"
        :activity="activity"
        :response="response"
      >
        <sticky-header>
          <template #primary-navigation>
            <breadcrumb>
              <breadcrumb-item
                :to="{
                  name: 'assignment_scores',
                  params: { assignmentId: mutableAssignmentId }
                }"
                >Scores
              </breadcrumb-item>
            </breadcrumb>
          </template>
          <template v-if="isVetted" #page-action>
            <toggle link :toggle="answersShown" @toggle="onToggleClick">
              <template #on>Hide Answers & Notes</template>
              <template #off>Show Answers & Notes</template>
            </toggle>
            <toggle
              v-if="splitViewAvailable"
              link
              :toggle="viewMode === 'split'"
              class="split-question-view"
              @toggle="toggleSplitView"
            >
              <template #on>Show Inline Question View</template>
              <template #off>Show Split Question View</template>
            </toggle>
          </template>
          <template #title>
            <sticky-header-title>
              Assignment: {{ activity.name }}
            </sticky-header-title>
          </template>
          <template #sub-title>
            <sticky-header-sub-title>
              <activity-student-name />
              <activity-score />
            </sticky-header-sub-title>
          </template>
          <template #actions>
            <submit-button
              v-if="!allowAutoGrade"
              action="submit"
              :disabled="isSubmitted || isOverdue"
            >
              <template #default> Submit </template>
              <template #submitting> Submitting </template>
              <template #submitted> Submitted </template>
            </submit-button>
            <button-link
              secondary
              :to="{
                name: 'activities',
                query: { library: libraryId }
              }"
            >
              View Library
            </button-link>
            <button-link
              v-if="canEdit"
              :to="{
                name: 'activity',
                params: { id: activity.id, tab: 'content' }
              }"
            >
              Edit
            </button-link>
          </template>
        </sticky-header>
        <activity-objectives
          :variable-context="variableContext"
          :changelog="activity.changelogEntries"
          :view-as-student="viewAsStudent"
        />
        <div
          :class="viewMode === 'split' ? 'instructor-activity-sections' : ''"
        >
          <activity-notes
            :show="answersShown"
            :variable-context="variableContext"
          />
        </div>
        <activity-sections
          v-slot="{ section, index }"
          :isTeacherPreview="!viewAsStudent"
          :class="{
            'instructor-activity-sections': viewMode === 'split',
            'split-activity-view': splitViewComponentEnabled
          }"
        >
          <activity-section
            :section="section"
            class="instructor-activity-section"
            :variable-context="variableContext"
            openByDefault
            override
            :view-as-student="viewAsStudent"
          >
            <activity-section-components
              v-slot="{ component }"
              :view-mode="viewMode"
              :split-view-component-enabled="splitViewComponentEnabled"
            >
              <activity-split-view-component
                v-if="component.componentType === 'SplitView'"
                :ref="el => (componentRefs[component._id] = el)"
                :component="component"
                :componentRefs="componentRefs"
                :variable-context="variableContext"
                @change="updateResponse"
                @updateComponentRef="val => (componentRefs = val)"
                :viewAsStudent="viewAsStudent"
                preview="assignment"
                :answersShown="false"
                :viewMode="viewMode"
              />
              <activity-section-component
                v-if="component.componentType !== 'SplitView'"
                :component="component"
                preview="assignment"
                overrideHiding
                :viewAsStudent="viewAsStudent"
                @change="updateResponse"
                v-slot="{ onSubmit, onCanSubmitChange, canSubmit }"
              >
                <activity-section-component-renderer
                  :component="component"
                  :variable-context="variableContext"
                  :answersShown="false"
                  :viewAsStudent="viewAsStudent"
                  :canSubmit="canSubmit"
                  @submit="onSubmit"
                  @canSubmitChange="onCanSubmitChange"
                />
              </activity-section-component>
            </activity-section-components>
            <activity-section-lock :activityIndex="index" @lock="lockSection" />
          </activity-section>
        </activity-sections>
      </activity>
    </async-form>
  </loading-container>
</template>

<script>
import Activity from 'src/modules/activities/components/Activity'
import ActivityScore from 'src/modules/activities/components/ActivityScore'
import ActivityObjectives from 'src/modules/activities/components/ActivityObjectives'
import ActivityNotes from 'src/modules/activities/components/ActivityNotes'
import ActivitySections from 'src/modules/activities/components/ActivitySections'
import ActivitySection from 'src/modules/activities/components/ActivitySection'
import ActivitySectionComponents from 'src/modules/activities/components/ActivitySectionComponents'
import ActivitySectionComponent from 'src/modules/activities/components/ActivitySectionComponent'
import ActivitySectionLock from 'src/modules/activities/components/ActivitySectionLock'
import ActivityStudentName from 'src/modules/activities/components/ActivityStudentName.vue'
import ActivitySectionComponentRenderer from '../components/ActivitySectionComponentRenderer.vue'
import ActivitySplitViewComponent from 'src/modules/activities/components/ActivitySplitViewComponent.vue'
import { AssignmentStudentFeedbackTimingValue } from '@pi/types'
import { mapGetters } from 'vuex'
import { getActivityAnswers } from '../correct-answers'
import client from 'src/shared/api-client'

export default {
  name: 'ActivityPreviewView',
  components: {
    Activity,
    ActivityScore,
    ActivityObjectives,
    ActivityNotes,
    ActivitySections,
    ActivitySection,
    ActivitySectionComponents,
    ActivitySectionComponent,
    ActivitySectionLock,
    ActivitySplitViewComponent,
    ActivitySectionComponentRenderer,
    ActivityStudentName
  },
  inject: ['$modal'],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup() {
    const componentRefs = {}
    return { componentRefs }
  },
  data() {
    return {
      activity: {},
      response: {
        responses: [],
        lockedSections: [],
        variables: []
      },
      mutableAssignmentId: this.id,
      isLoading: true,
      isSubmitted: false,
      answersShown: false,
      classId: '',
      activityVariables: []
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'isContentDeveloper', 'isVetted']),
    splitViewComponentEnabled() {
      return this.activity.splitViewComponentEnabled
    },
    viewAsStudent() {
      return !this.canEdit
    },
    canEdit() {
      return (
        this.isAdmin ||
        this.isContentDeveloper ||
        ['owner', 'admin', 'editor'].includes(this.activity?.permissions)
      )
    },
    libraryId() {
      return this.activity?.libraryId
    },
    allowAutoGrade() {
      return (
        this.response.assignment.studentFeedbackTiming ===
        AssignmentStudentFeedbackTimingValue.AfterQuestionSubmit
      )
    }
  },
  methods: {
    onToggleClick(value) {
      this.answersShown = value

      if (this.answersShown) {
        this.response.responses = getActivityAnswers(this.activity)
      } else {
        this.response.responses = []
      }
    },
    async load() {
      this.isLoading = true
      const [assignmentRequest, response, activity] = await Promise.all([
        client.assignments.get({ assignmentId: this.id }),
        client.assignments.getPreviewResponse({ assignmentId: this.id }),
        client.assignments.getActivity({ assignmentId: this.id })
      ])
      this.activity = activity
      this.classId = assignmentRequest.class
      this.response = response
      this.activityVariables = response.variables ?? []
      this.isLoading = false

      try {
        this.activity.changelogEntries = await client.activities.getChangelog({
          activityId: this.activity.id
        })
      } catch (error) {
        // Graders may not be able to see the changelog, so don't fail if this request fails.
        if (error.status === 403) {
          this.activity.changelogEntries = []
        } else {
          throw error
        }
      }
    },
    updateResponse({ response, isDirty }) {
      // Update response object.
      const index = this.response.responses.findIndex(
        r => r.component === response.component
      )
      if (index >= 0) {
        this.response.responses.splice(index, 1, response)
      } else {
        this.response.responses.push(response)
      }
      if (!isDirty || !this.allowAutoGrade) {
        // if the response has been graded, update the dependency info
        this.$refs.activity.checkComponentDependencies()
      }
      this.updateResponseVariableValue(response.component, response.value)
    },
    updateResponseVariableValue(id, value) {
      const activityVariable = this.activity.variables.find(
        v => v.content === id
      )
      if (!activityVariable) return
      this.response.variables = this.response.variables.map(v => {
        if (v.id === activityVariable.id) {
          return {
            ...v,
            value
          }
        }
        return v
      })
    },
    lockSection(index) {
      this.response.lockedSections.push(index)
    },
    async submit(e) {
      this.response = await client.assignments.submitPreviewResponses({
        assignmentId: this.id,
        responses: this.response.responses.map(response => ({
          component: response.component,
          value: response.value ?? undefined
        }))
      })
      this.isSubmitted = true
      e.done()
    }
  },
  async created() {
    await this.load()
    this.$refs.activity?.checkComponentDependencies()
  }
}
</script>

<style lang="scss" scoped>
.split-question-view {
  margin-left: 10px;
}

.instructor-activity-sections {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-right: -50vw;
  margin-left: -50vw;
  padding-left: 30px;
  padding-right: 30px;
}

p {
  font-style: italic;
}
</style>
