import { FeatureFlagConfig } from '@pi/api-client'

function computeFlags(): FeatureFlagConfig {
  const featureFlags: FeatureFlagConfig = {
    enabled: [],
    disabled: []
  }

  // from environment vars
  for (const key in window) {
    if (!key.startsWith('PI_FEAT_')) {
      continue
    }

    const flag = key.split('PI_FEAT_')[1].toLowerCase()
    if (!!window[key] === true) {
      featureFlags.enabled.push(flag)
    } else {
      featureFlags.disabled.push(flag)
    }
  }

  // from query string
  const query = new URLSearchParams(window.location.search)
  const queryFlags = (query.get('ff') ?? '').split(',')
  for (const flag of queryFlags) {
    if (!flag) {
      continue
    }

    featureFlags.enabled.push(flag.toLowerCase())
  }

  const disabledQueryFlags = (query.get('xff') ?? '').split(',')
  for (const flag of disabledQueryFlags) {
    if (!flag) {
      continue
    }

    featureFlags.disabled.push(flag.toLowerCase())
  }

  return featureFlags
}

const featureFlags = computeFlags()

export { featureFlags }
