<template>
  <UIForm @submit="onSubmit">
    <ClassSelector
      :classes="classes"
      :className="className"
      :platformName="platformName"
      path="classId"
      @refreshClassList="refreshList"
    >
      <template #actions>
        <div class="flex justify-end mt-5">
          <UIButton submit :disabled="!meta.valid"> Connect Class </UIButton>
        </div>
      </template>
    </ClassSelector>
  </UIForm>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate'
import { useRouter } from 'vue-router'

import { UIButton, UIForm } from '@pi/design'
import { Class } from '@pi/api-types'

import client from 'src/shared/api-client'
import { useFlash } from 'src/shared/hooks/flash'
import ClassSelector from '../components/ClassSelector.vue'

const flash = useFlash()
const router = useRouter()
const emits = defineEmits(['refreshClassList'])

defineProps<{
  platformName: string
  className?: string
  classes: Class[]
}>()

const { handleSubmit, meta } = useForm<{ classId: string }>({
  validateOnMount: true
})
const onSubmit = handleSubmit(async values => {
  await client.lti.connectClass({
    classId: values.classId
  })

  flash.success('Class connected successfully.')
  await router.push({ name: 'lti_connect_assignment' })
})

function refreshList() {
  emits('refreshClassList', true)
}
</script>
