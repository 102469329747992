<template>
  <view-container>
    <view-header>
      <view-title>{{ (klass && klass.name) || 'Class' }}</view-title>
      <template v-if="klass">
        <view-header-actions>
          <button-link
            class="view-roster-button"
            :to="{ name: 'existing_class_roster', params: { id: klass.id } }"
            >View Class & Roster</button-link
          >
          <button-dropdown secondary right>
            <template #button>
              <icon icon="ellipsis-v" />
              <span class="sr-only">Class Actions Menu</span>
            </template>
            <dropdown-action
              v-if="assignments.length"
              @click="downloadGradesForClass(id, klass.name)"
            >
              Download Grades
            </dropdown-action>
            <dropdown-copy
              v-if="!klass.isArchived && !klass.fromRoster"
              :value="classLink"
              success-message="A link to join this class has been copied."
            >
              Copy Join Link
            </dropdown-copy>

            <dropdown-action
              v-if="!klass.isArchived && isInstructor"
              @click="archiveClass"
            >
              Archive Class
            </dropdown-action>
            <dropdown-action
              v-if="klass.isArchived && isInstructor"
              @click="unarchiveClass"
            >
              Unarchive
            </dropdown-action>
            <dropdown-action @click="toggleShowArchived">
              {{
                `${
                  computedQuery.showingArchived === 'false' ? 'Show' : 'Hide'
                } Archived Assignments`
              }}
            </dropdown-action>
          </button-dropdown>
        </view-header-actions>
        <view-subtitle v-if="klass.isArchived" class="archived-message">
          (Archived)</view-subtitle
        >
      </template>
    </view-header>
    <loading-container :loading="isLoading">
      <div v-if="sortedAssignments.length === 0" class="row">
        <div class="col-sm-12 text-center no-assignments-msg">
          <p>
            It looks like you don’t have any
            <span v-if="computedQuery.showingArchived === 'false'">
              unarchived
            </span>
            assignments in this class yet. Click below to add an assignment.
          </p>
          <button-link
            v-if="!klass.isArchived && isInstructor"
            :to="{
              name: 'class_assign_activity',
              params: { id: klass.id }
            }"
          >
            <icon icon="plus" />
            Assign an Activity
          </button-link>
          <pendo-button secondary class="class-view__help-button"
            >Show Me How</pendo-button
          >
        </div>
      </div>
      <div v-if="sortedAssignments.length > 0" class="pi-assignments row">
        <list
          class="assignments-table"
          :items="sortedAssignments"
          aria-label="Assignments"
          item-key="id"
        >
          <template v-if="isInstructor" #firstRow>
            <div class="first-row-content">
              <router-link
                v-if="!klass.isArchived && isInstructor && isLicenseActive"
                :to="{
                  name: 'class_assign_activity',
                  params: { id: klass.id }
                }"
                data-testid="firstRowAddBtn"
                data-tagid="add-assignment-btn"
              >
                <icon icon="plus" />
                Assign an Activity
              </router-link>
              <a v-if="!isLicenseActive" class="disabled-link">
                <icon icon="plus" />
                Assign an Activity
              </a>
            </div>
          </template>
          <sortable-list-column
            v-slot="assignment"
            :sort="computedQuery.sort === 'activity.name' && query.dir"
            title="Assignment Name"
            @change="sort => updateQuery({ sort: 'activity.name', dir: sort })"
          >
            <router-link
              :to="{
                name: 'assignment_scores',
                params: { assignmentId: assignment.id }
              }"
            >
              {{ assignment.activity.name }}
            </router-link>
          </sortable-list-column>
          <sortable-list-column
            v-slot="assignment"
            :sort="computedQuery.sort === 'status' && query.dir"
            title="Status"
            @change="sort => updateQuery({ sort: 'status', dir: sort })"
          >
            <span v-if="!assignment.isArchived">
              <span v-if="assignment.status == 'not-open'">Not Open</span>
              <span v-if="assignment.status == 'open'">Open</span>
              <span v-if="assignment.status == 'closed'">Closed</span>
            </span>
            <p v-else class="archived-tag">Archived</p>
          </sortable-list-column>
          <sortable-list-column
            v-slot="assignment"
            :sort="computedQuery.sort === 'startDate' && query.dir"
            title="Start Date"
            @change="sort => updateQuery({ sort: 'startDate', dir: sort })"
          >
            <date-input
              :modelValue="assignment.startDate"
              :max-date="assignment.endDate"
              label="start date"
              time
              @update:modelValue="
                newDate =>
                  updateAssignmentDate('startDate', assignment, newDate)
              "
            />
          </sortable-list-column>
          <sortable-list-column
            v-slot="assignment"
            :sort="computedQuery.sort === 'endDate' && query.dir"
            title="Due Date"
            @change="sort => updateQuery({ sort: 'endDate', dir: sort })"
          >
            <date-input
              :modelValue="assignment.endDate"
              :min-date="assignment.startDate"
              label="end date"
              time
              @update:modelValue="
                newDate => updateAssignmentDate('endDate', assignment, newDate)
              "
            />
          </sortable-list-column>

          <list-column v-slot="assignment" title="Actions">
            <button-dropdown
              tertiary
              right
              :up="nearEnd(assignment)"
              class="pull-right"
            >
              <template #button>
                <icon icon="ellipsis-h" />
                <span class="sr-only">Assignments Menu</span>
              </template>
              <dropdown-link
                :to="{
                  name: 'assignment_scores',
                  params: { assignmentId: assignment.id }
                }"
                >Grade by
                {{
                  assignment.assignedTo === 'groups' ? 'Group' : 'Student'
                }}</dropdown-link
              >
              <dropdown-link
                :to="{
                  name: 'select_questions_to_grade',
                  params: {
                    assignmentId: assignment.id
                  }
                }"
              >
                Grade by Question
              </dropdown-link>
              <dropdown-action @click="regradeAssignment(assignment)">
                Regrade AutoGraded Questions
              </dropdown-action>
              <dropdown-link
                :to="{
                  name: 'preview_assignment',
                  params: { id: assignment.id }
                }"
              >
                Preview Assignment
              </dropdown-link>
              <dropdown-link
                v-if="isInstructor"
                :to="{
                  name: 'activity',
                  params: { id: assignment.activity._id, tab: 'content' }
                }"
              >
                Edit Activity
              </dropdown-link>
              <dropdown-copy
                :value="formatAssignmentLink(assignment.id)"
                success-message="A link to this assignment has been copied."
              >
                Copy Link
              </dropdown-copy>

              <dropdown-action
                @click="downloadGrades(assignment.id, assignment.activity.name)"
              >
                Download Grades
              </dropdown-action>
              <dropdown-action
                v-if="isInstructor"
                @click="
                  !assignment.isArchived
                    ? archiveAssignment(assignment)
                    : unarchiveAssignment(assignment.id)
                "
              >
                {{
                  `${
                    !assignment.isArchived ? 'Archive' : 'UnArchive'
                  } Assignment`
                }}
              </dropdown-action>
              <dropdown-action
                v-if="isInstructor"
                @click="
                  deleteAssignment(assignment.id, assignment.activity.name)
                "
              >
                Delete Assignment
              </dropdown-action>
            </button-dropdown>
          </list-column>
        </list>
      </div>
    </loading-container>
  </view-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import client from 'src/shared/api-client'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import TransferStudentModal from 'src/shared/components/modals/TransferStudentModal'
import { addProtocolAndHost } from 'src/shared/utils/environment-tools'

import orderBy from 'lodash/orderBy'
import PendoButton from 'src/shared/components/PendoButton'
import { format } from 'date-fns'
import useTitle from 'src/shared/hooks/title'
import { trackEvent } from '../../../setup/analytics'

export default {
  name: 'ClassView',
  components: { PendoButton },
  inject: ['$modal'],
  props: {
    id: {
      type: String,
      required: true
    },
    query: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      klass: undefined,
      assignments: [],
      password: '',
      isLoading: false
    }
  },
  computed: {
    ...mapGetters(['isLicenseActive']),
    ...mapState({
      user: state => state.auth.user
    }),
    computedQuery() {
      return {
        sort: this.query.sort || '',
        dir: this.query.dir || '',
        showingArchived: this.query.showingArchived || 'false'
      }
    },
    classLink() {
      return addProtocolAndHost(`join-class?classKey=${this.klass.classKey}`)
    },
    sortedAssignments() {
      const filteredAssignments = this.assignments.filter(assignment => {
        if (this.computedQuery.showingArchived === 'true') {
          return !assignment.isDeleted
        } else {
          return !assignment.isDeleted && !assignment.isArchived
        }
      })

      if (['asc', 'desc'].includes(this.computedQuery.dir)) {
        let iteratees = []
        let orders = []
        if (this.computedQuery.sort === 'status') {
          const statusSortDirection =
            this.computedQuery.dir === 'asc' ? 'desc' : 'asc'

          iteratees = ['status', 'endDate', 'startDate']
          orders = [statusSortDirection, 'asc', 'asc']
        } else {
          iteratees = [this.computedQuery.sort]
          orders = [this.computedQuery.dir]
        }

        return orderBy(filteredAssignments, iteratees, orders)
      } else {
        return filteredAssignments
      }
    }
  },
  methods: {
    ...mapActions(['refreshAuth']),
    async regradeAssignment(assignment) {
      const { status } = await this.$modal.show(ConfirmModal, {
        text: 'This will overwrite scores and comments for only autograded questions in this assignment.'
      })
      if (status === 'ok') {
        await client.assignments.regrade({
          assignmentId: assignment._id || assignment.id
        })
        this.$success('Regrade complete!')
      }
    },
    updateQuery(query) {
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: {
          ...this.$route.query,
          ...query
        }
      })
    },
    async loadClass() {
      const body = await client.classes.get({ classId: this.id })
      this.klass = body
      useTitle(() => `${body.name} | Assignments`)
    },
    async archiveClass() {
      const { name, id } = this.klass
      const { status } = await this.$modal.show(ConfirmModal, {
        text: 'You will no longer be able to assign activities to this class and students in this class will be unable to view their assignments. This will free up seats on your account, allowing you to invite new students to your classes.',
        prompt: `Are you sure you want to archive the class ${name}?`
      })
      if (status === 'ok') {
        await client.classes.archive({ classId: id })
        trackEvent('archive_class')
        this.$success(`Archived class ${name} successfully.`)
        await this.refreshAuth()
        this.loadClass()
      }
    },
    async unarchiveClass() {
      const { name, id } = this.klass
      const { status } = await this.$modal.show(ConfirmModal, {
        text: 'Unarchiving will add the students enrolled in this class to the number of seats used on your account.',
        prompt: `Are you sure you want to unarchive the class ${name}?`
      })
      if (status === 'ok') {
        await client.classes.unarchive({ classId: id })
        this.$success(`Unarchived class ${name} successfully.`)
        this.loadClass()
      }
    },
    formatAssignmentLink(id) {
      return addProtocolAndHost(`assignments/${id}/responses`)
    },
    async downloadGradesForClass(classId, className) {
      const csv = await client.classes.downloadGradesAsCSV({
        classId: classId
      })

      const blob = new Blob([csv], {
        type: 'text/csv'
      })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${className} - ${format(new Date(), 'M-d-yyyy')}.csv`
      if (window.Cypress) {
        document.body.appendChild(link)
      } else {
        link.click()
      }
    },
    async downloadGrades(assignmentId, assignmentName) {
      const response = await client.assignments.downloadGradesAsCSV({
        assignmentId
      })
      const blob = new Blob([response], {
        type: 'text/csv;charset=utf-8;'
      })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${assignmentName} - ${format(
        new Date(),
        'M-d-yyyy'
      )}.csv`
      if (window.Cypress) {
        document.body.appendChild(link)
      } else {
        link.click()
      }
    },
    async archiveAssignment(assignment) {
      const { id: assignmentId, status: assignmentStatus } = assignment

      const assignmentName = assignment.activity.name

      if (assignmentStatus === 'open') {
        const { status } = await this.$modal.show(ConfirmModal, {
          text: 'This assignment is currently open.  Archiving the assignment will also close it, which will not allow any further responses from your students.  Archived assignments are not visible to students.',
          prompt: `Are you sure you want to close and archive the assignment "${assignmentName}"?`
        })
        if (status === 'ok') {
          await client.assignments.archive({
            assignmentId: assignmentId,
            isArchived: true
          })
          await this.loadAssignments()
          this.$success('Assignment successfully archived.')
        }
      } else {
        await client.assignments.archive({
          assignmentId: assignmentId,
          isArchived: true
        })
        await this.loadAssignments()
        this.$success('Assignment successfully archived.')
      }
    },
    async unarchiveAssignment(assignmentId) {
      await client.assignments.archive({
        assignmentId: assignmentId,
        isArchived: false
      })
      await this.loadAssignments()
      this.$success('Assignment successfully unarchived.')
    },
    async deleteAssignment(assignmentId, assignmentName) {
      const { status } = await this.$modal.show(ConfirmModal, {
        text: 'Warning!  This will permanently close and delete the assignment.  This action cannot be undone.',
        prompt: `Are you sure you want to close and delete the assignment "${assignmentName}"?`
      })
      if (status === 'ok') {
        await client.assignments.delete({
          assignmentId: assignmentId
        })
        await this.loadAssignments()
        this.$success('Assignment successfully deleted.')
      }
    },
    toggleShowArchived() {
      const newShowingArchived =
        this.computedQuery.showingArchived === 'true' ? 'false' : 'true'
      this.$router.push({
        query: { ...this.$route.query, showingArchived: newShowingArchived }
      })
    },
    async loadAssignments() {
      const assignments = await client.classes.getAssignments({
        classId: this.id
      })

      if (assignments) {
        this.assignments = assignments.map(assignment => ({
          ...assignment,
          startDate: assignment.startDate
            ? new Date(assignment.startDate)
            : assignment.startDate,
          endDate: assignment.endDate
            ? new Date(assignment.endDate)
            : assignment.endDate
        }))
      }
    },
    formatStatus(status) {
      return status === 'not-open'
        ? 'Not Open'
        : status === 'closed'
          ? 'Closed'
          : 'Open'
    },
    async updateAssignmentDate(field, assignment, newDate) {
      await client.assignments.update({
        assignmentId: assignment.id || assignment._id,
        [field]: newDate
      })
      this.loadAssignments()
      this.$success('Assignment dates updated.')
    },
    async transferStudent(student) {
      const { status, data } = await this.$modal.show(TransferStudentModal, {
        student: `${student.firstName} ${student.lastName}`,
        currentClassName: this.klass.name
      })
      if (status === 'ok') {
        await client.classes.transferStudent(
          this.id,
          student._id,
          data.classKey
        )
        this.$success(
          `${student.firstName} ${student.lastName} was successfully transferred to ${data.className}.`
        )
        await this.loadClass()
      }
    },
    nearEnd(assignment) {
      const index = this.sortedAssignments.findIndex(
        a => a.id === assignment.id
      )
      return (
        index >= this.sortedAssignments.length - 4 &&
        this.sortedAssignments.length > 6
      )
    },
    isInstructor() {
      return (this.klass?.instructors || []).some(
        instructor =>
          instructor.role === 'Instructor' && instructor._id === this.user.id
      )
    }
  },
  created() {
    useTitle(() =>
      this.klass ? `${this.klass.name} | Assignments` : 'Assignments'
    )
  },
  watch: {
    id: {
      async handler() {
        this.isLoading = true
        await this.loadClass()
        await this.loadAssignments()

        if (this.computedQuery.sort === '') {
          await this.$router.replace({
            query: {
              sort: 'startDate',
              dir: 'asc'
            }
          })
        }
        this.isLoading = false
      },
      immediate: true
    },
    isArchived: {
      handler() {
        this.loadClass()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.archived-tag {
  padding: 6px 12px;
  color: $color-error;
  margin: 0px;
}

.no-classes-msg {
  margin: 4rem 0;
}

.first-row-content {
  padding: 8px 0;
}

.archived-message {
  color: $color-error;
}
.view-roster-button {
  margin-right: 16px;
}
.class-view__help-button {
  margin-left: 8px;
}

.disabled-link {
  color: #767676;
  cursor: not-allowed;
}
</style>
